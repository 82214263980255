import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { ApiQuery } from '@app/core/http/api-query';
import { FormGroup } from '@angular/forms';
import { UsersService } from '@app/settings/users/users.service';
import { UsersFormService } from '@app/settings/users/users-form.service';
import { User } from '@app/settings/users/user.model';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Logger } from '@app/core';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new Logger('UserSelectComponent');

@UntilDestroy()
@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectComponent implements OnInit {
  data: Observable<User[]>;
  apiQuery: ApiQuery = new ApiQuery();
  loader: boolean;
  @Input() parentFormGroup: FormGroup;
  @Input() passFormControlName = 'user_id';
  @Input() hideLabel = false;
  @Input() label: string = null;
  @Input() onlyActive = true;
  input$ = new Subject<string>();
  @Input() roleType: UserRoleType | Array<UserRoleType>;
  @Output() select = new EventEmitter();
  userFormControlName: any = this.passFormControlName.replace('_id', '');
  @Input() includes: string = null;
  lastResponse: any = null;

  constructor(
    private usersService: UsersService,
    private usersFormService: UsersFormService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    log.debug('label', this.label);
    // @todo: Да се напише regex за този replace
    this.userFormControlName = this.passFormControlName.replace('_id', '');

    // userFormControlName = userFormControlName[0];

    const preSelectedUser = this.parentFormGroup.get(this.userFormControlName);
    const preSelectedUserId = this.parentFormGroup.get(this.passFormControlName);

    /**
     * Попълва избрания user на едит.
     * Ако има FormControl 'user' в parentFormGroup.
     * Ако няма ще се опита да го get-не по ID
     */
    if (preSelectedUser) {
      /*
       * Следи за промени в user FormControl-a и когато намери валиден User спира да следи
       */
      const preselectedUserSubscriber = preSelectedUser.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe((user: User) => {
          if (user && user.fake_first_name) {
            this.data = of([new User().deserialize(user)]);
            preselectedUserSubscriber.unsubscribe();
          }
        });
    } else if (preSelectedUserId) {
      const preselectedUserIdSubscriber = preSelectedUserId.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe((userId: any) => {
          if (userId) {
            this.loadUserById(userId);
            preselectedUserIdSubscriber.unsubscribe();
          }
        });
    }
    if (this.roleType) {
      this.apiQuery.addFilter('roles.id', this.roleType.toString());
    }

    this.userSearch();
  }

  loadUserById(id: number) {
    this.loader = true;

    if (this.includes) {
      this.apiQuery.addIncludes(this.includes);
    }

    return this.usersService
      .show(id, this.apiQuery)
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        this.data = of([user]);
        this.loader = false;
        this.cdr.markForCheck();
      });
  }

  userSearch() {
    if (this.onlyActive) {
      this.apiQuery.addFilter('active', true);
    }

    if (this.includes) {
      this.apiQuery.addIncludes(this.includes);
    }

    this.input$
      .pipe(
        untilDestroyed(this),
        tap(() => (this.loader = true)),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap((term: any) => {
          // За да ме прави излишен request след като се избере нещо
          if (!term) {
            return of(this.lastResponse);
          }

          return this.usersService.index(this.apiQuery.addFilters({ fake_names: term })).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loader = false))
          );
        })
      )
      .subscribe((users: PaginatedResponse) => {
        this.data = of(users.data);
        this.lastResponse = users;
        this.loader = false;
        this.cdr.markForCheck();
      });
  }

  onChange(user: any) {
    if (this.parentFormGroup.get(this.userFormControlName)) {
      this.parentFormGroup.get(this.userFormControlName).patchValue(user);
    }

    this.select.emit(user);
    this.cdr.markForCheck();
  }

  add(searchTerm: string) {
    this.usersFormService.open(new User().deserialize({ first_name: searchTerm })).then(
      (response) => {
        this.loadUserById(response.id);
        this.parentFormGroup.get(this.passFormControlName).setValue(response.id);
      },
      (err) => {
        log.debug(err);
      }
    );
  }

  edit() {
    if (this.parentFormGroup.get(this.passFormControlName).value) {
      this.usersFormService
        .open(new User().deserialize({ id: this.parentFormGroup.get(this.passFormControlName).value }))
        .then(
          (response) => {
            this.loadUserById(response.id);
            this.parentFormGroup.get(this.passFormControlName).setValue(response.id);
          },
          (err) => {
            log.error(err);
          }
        );
    }
  }
}
