import { Injectable } from '@angular/core';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { difference, forEach, isArray, map, merge } from 'lodash';
import { AuthenticationService } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  private accesses = {};
  private isAdmin: boolean = null;
  private allRolesIds: Array<any> = [];

  constructor(private auth: AuthenticationService) {
    this.accesses = this.getFromStorage() || {};
  }

  public hasAccess(
    roles: Array<UserRoleType> = [],
    permissions: Array<string> = [],
    excludePassedRoles: boolean = false
  ): boolean {
    if (!isArray(roles)) {
      throw new Error('AccessService: roles parameter is not a Array!');
    }

    if (!isArray(permissions)) {
      throw new Error('AccessService: permissions parameter is not a Array!');
    }

    if (this.isUserIsAdministrator()) {
      return true;
    }

    if (excludePassedRoles) {
      if (this.allRolesIds.length <= 0) {
        this.getAllRoles();
      }
      roles = difference(this.allRolesIds, roles);
    }

    const key = roles.sort().toString() + '|' + permissions.sort().toString();

    let value = false;
    let hasRole = false;
    let hasPermissions = false;

    if (this.accesses[key] !== undefined) {
      return this.accesses[key];
    }

    if (roles.length > 0) {
      hasRole = this.hasOneOfRoles(roles);
    }

    if (permissions.length > 0) {
      forEach(permissions, (perm) => {
        if (this.hasPermission(perm)) {
          hasPermissions = true;
          return false;
        }
      });
    }

    if (roles.length > 0 && permissions.length > 0) {
      value = hasRole && hasPermissions;
    } else if (roles.length > 0 && permissions.length === 0) {
      value = hasRole;
    } else if (permissions.length > 0 && roles.length === 0) {
      value = hasPermissions;
    }

    const obj = {};
    obj[key] = value;

    merge(this.accesses, obj);

    this.setInStorage(this.accesses);
    return value;
  }

  public clearStorage(): void {
    this.accesses = {};
    this.isAdmin = null;
    localStorage.removeItem('accesses');
  }

  /**
   * Дали юзъра има роля
   * @param roleId id на роля
   */
  public hasRole(roleId: number): boolean {
    let hasPermission = false;

    forEach(this.auth.accessToken.profile.roles, (role: any) => {
      if (role.id === roleId) {
        hasPermission = true;
        return false;
      }
    });

    return hasPermission;
  }

  /**
   * Дали има поне една от посочените роли
   * @param roles Roles array
   */
  public hasOneOfRoles(roles: Array<UserRoleType> = []): boolean {
    let hasRole = false;
    forEach(roles, (id: UserRoleType) => {
      if (this.hasRole(id)) {
        hasRole = true;
      }
    });

    return hasRole;
  }

  private getAllRoles() {
    this.allRolesIds = map(this.auth.accessToken.profile.roles, 'id');
  }

  private isUserIsAdministrator(): boolean {
    if (this.isAdmin !== null) {
      return this.isAdmin;
    }
    this.isAdmin = false;
    forEach(this.auth.accessToken.profile.roles, (role: any) => {
      if (role.id === UserRoleType.ADMINISTRATOR) {
        this.isAdmin = true;
        return false;
      }
    });
    return this.isAdmin;
  }

  private getFromStorage() {
    return JSON.parse(localStorage.getItem('accesses'));
  }

  private setInStorage(accessObj: {}) {
    localStorage.setItem('accesses', JSON.stringify(accessObj));
  }

  /**
   * Дали юзъра има право
   * @param permission Кое право да търси
   */
  private hasPermission(permission: string): boolean {
    let hasPermission = false;

    forEach(this.auth.accessToken.profile.roles, (role: any) => {
      forEach(role.permissions, (permissionStr: any) => {
        if (permissionStr.name.toLowerCase() === permission.toLowerCase()) {
          hasPermission = true;
          return false;
        }
      });
      if (hasPermission) {
        return false;
      }
    });

    return hasPermission;
  }
}
