import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User } from '@app/settings/users/user.model';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { UsersService } from '@app/settings/users/users.service';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { environment } from '@env/environment';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { AccessService } from '@app/core/access/access.service';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NotificationsService } from '@app/notifications/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';

const log = new Logger('UsersViewComponent');

@UntilDestroy()
@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersViewComponent implements OnInit, OnDestroy {
  userId: number;
  user: User;
  env = environment;
  UserRoleType = UserRoleType;

  @ViewChild('perfectScroll', { static: true }) perfectScroll: PerfectScrollbarDirective;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    public activitiesService: ActivitiesService,
    public accessService: AccessService,
    public auth: AuthenticationService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.userId = Number(params.get('id'));
        this.loadData(this.userId);
      } else {
        log.error('Id is not found in route params!');
      }
    });
  }

  ngOnDestroy(): void {
    // крие sidebar-a s activity-тата и съобщенията
    this.activitiesService.hide();
  }

  /**
   * Отваря sidebar-a s activity-тата и съобщенията
   */
  openActivities(): void {
    this.activitiesService.show(LaravelModelsEnum.USER, this.userId, false);
  }

  loadData(id: number): void {
    const apiQuery: ApiQuery = new ApiQuery().addIncludes(
      'avatar',
      'title',
      'locales',
      'company',
      'companies',
      'contacts.type',
      'roles',
      'bank_accounts'
    );
    this.usersService
      .show(id, apiQuery)
      .pipe(untilDestroyed(this))
      .subscribe((user: User) => {
        this.user = user;
        this.cdr.markForCheck();
        this.perfectScroll.update();
      });
  }

  readAllNotifications() {
    this.notificationsService
      .readAllOfUser(this.user.id)
      .then(() => {
        this.toastrService.success('All notifications of user are marked as read!');
      })
      .catch((e) => log.debug('readAllNotifications catch', e));
  }
}
