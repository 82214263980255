<div class="modal-header">
  <h4 class="modal-title">
    {{ 'SALES.SALES_GUEST_NAMES_FORM.FORM_TITLE' | translate }}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form id="sale-guest-names-form" (ngSubmit)="save()" novalidate class="modal-body">
  <div class="table">
    <thead>
      <tr>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.TABLE_NUMBER_COLUMN_TITLE' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.HOTEL_BOOKING_ROOM_CONFIRMATION_NUMBER' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.HOTEL' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.SUPPLIER' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.CITY' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.TYPE' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.MEAL_PLAN_SHORT' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.CHECK_IN' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.NIGHTS' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.CHECK_OUT' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.REF_NUMBER_SHORT' | translate }}</th>
        <th>{{ 'SALES.SALES_GUEST_NAMES_FORM.PEOPLE' | translate }}</th>
      </tr>
    </thead>
    <tbody *ngFor="let item of items; let I = index">
      <tr>
        <td>
          {{ I + 1 }}
        </td>
        <td style="width: 180px">
          <input
            type="text"
            class="form-control"
            [disabled]="item.hotel_booking_room_id_disabled"
            [(ngModel)]="item.hotel_booking_room_confirmation_number"
            [ngModelOptions]="{ standalone: true }"
            [name]="'hotel_booking_room_confirmation_number_' + [I]"
          />
        </td>
        <td>
          {{ item?.booking?.hotel?.name }}
          <span *ngIf="item?.booking?.hotel?.stars" class="text-nowrap">
            ({{ item?.booking?.hotel?.stars }} <span class="star-ico-wrapper"><i class="fas fa-star"></i></span>)
          </span>
        </td>
        <td>
          {{ item?.booking?.supplier?.name }}
        </td>
        <td>
          {{ item?.booking?.hotel?.city?.name }}
          <span *ngIf="item?.booking?.hotel?.city?.country?.name">
            ({{ item?.booking?.hotel?.city?.country?.name }})
          </span>
        </td>

        <td>
          {{ item?.room?.type?.value }}
        </td>
        <td>
          {{ item?.room?.meal_plan?.value }}
        </td>
        <td>
          {{ item?.sale_final_item?.check_in | date : env.pipeDateFormat }}
        </td>
        <td>
          {{ getNightsCount(item?.sale_final_item?.check_in, item?.sale_final_item?.check_out) }}
        </td>
        <td>
          {{ item?.sale_final_item?.check_out | date : env.pipeDateFormat }}
        </td>
        <td>
          {{ item?.room?.ref_number }}
        </td>
        <td>
          {{ item?.sale_final_item?.sale_offer_item?.people }}
        </td>
      </tr>
      <tr>
        <td colspan="12">
          <div class="font-weight-bold text-left">
            {{ 'SALES.SALES_GUEST_NAMES_FORM.REQUEST_GUEST_NAMES' | translate }}
          </div>
          <div class="text-left" *ngIf="item?.sale_final_item?.sale_offer_item?.sale_request?.guest_names?.length <= 0">
            {{ 'SALES.SALES_GUEST_NAMES_FORM.NO_GUEST_NAMES_MSG' | translate }}
          </div>
          <div class="row">
            <div
              class="col-sm-2 text-left"
              *ngFor="let gName of item?.sale_final_item?.sale_offer_item?.sale_request?.guest_names; let J = index"
            >
              {{ gName }}
              <button
                (click)="copyText(gName)"
                class="btn btn-ico"
                type="button"
                [ngbTooltip]="'SALES.SALES_GUEST_NAMES_FORM.COPY_TOOLTIP' | translate"
                container="body"
              >
                <i class="fal fa-copy"></i>
              </button>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="12">
          <div class="font-weight-bold text-left">
            {{ 'SALES.SALES_GUEST_NAMES_FORM.FINAL_GUEST_NAMES' | translate }}
          </div>
          <div class="row">
            <div class="col-sm-2" *ngFor="let guestName of item.guest_names; let J = index">
              <label class="d-block text-left">
                {{ 'SALES.SALES_GUEST_NAMES_FORM.GUEST_NAME' | translate }} {{ J + 1 }}
              </label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="guestName.name"
                [ngModelOptions]="{ standalone: true }"
                [name]="'guest_names_' + [I] + '_' + [J]"
              />
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </div>
</form>

<div class="modal-footer">
  <button type="submit" class="btn btn-outline-dark btn-xs" form="sale-guest-names-form">
    {{ 'SALES.SALES_GUEST_NAMES_FORM.SAVE' | translate }}
  </button>
</div>
