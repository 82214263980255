<div class="modal-header">
  <h4 class="modal-title">
    <span><i class="fal fa-search"></i> {{ 'ALLOTMENT_FINDER.TITLE' | translate }} </span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body ps" [perfectScrollbar] #perfectScroll="ngxPerfectScrollbar">
  <div class="d-flex justify-content-between align-items-center">
    <div>
      <div class="d-flex additional-info" *ngIf="additionalInfo">
        <div class="mr-3">
          <div>
            <strong>{{ 'ALLOTMENT_FINDER.STATUS' | translate }} :</strong>
            {{ additionalInfo?.request?.status?.value }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.CHECK_IN' | translate }} :</strong>
            {{ additionalInfo?.request?.check_in | date : env.pipeDateFormat }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.NIGHTS' | translate }} :</strong>
            {{ additionalInfo?.request?.check_out | amDifference : additionalInfo?.request?.check_in : 'days' : true }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.CHECK_OUT' | translate }} :</strong>
            {{ additionalInfo?.request?.check_out | date : env.pipeDateFormat }}
          </div>
        </div>

        <div class="mr-3">
          <div>
            <strong>{{ 'ALLOTMENT_FINDER.COUNT' | translate }} :</strong>
            {{ additionalInfo?.request?.count }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.PEOPLE' | translate }} :</strong>
            {{ additionalInfo?.request?.people * additionalInfo?.request?.count }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.TYPE' | translate }} :</strong>
            <app-type-badge [type]="additionalInfo?.request?.type"></app-type-badge>
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.MEAL_PLAN_SHORT' | translate }} :</strong>
            <app-type-badge [type]="additionalInfo?.request?.meal_plan"></app-type-badge>
          </div>
        </div>

        <div class="mr-3">
          <div>
            <strong>{{ 'ALLOTMENT_FINDER.HOTEL' | translate }} :</strong>
            {{ additionalInfo?.request?.hotel?.name }}
          </div>

          <div style="max-width: 400px">
            <strong>{{ 'ALLOTMENT_FINDER.BUDGET' | translate }} :</strong>
            {{ additionalInfo?.request?.budget }}
          </div>

          <div style="max-width: 400px">
            <strong>{{ 'ALLOTMENT_FINDER.NOTE' | translate }} :</strong>
            {{ additionalInfo?.request?.note }}
          </div>

          <div style="max-width: 400px">
            <strong>{{ 'ALLOTMENT_FINDER.GUEST_NAMES' | translate }} :</strong>
            <span *ngFor="let guestName of additionalInfo?.request?.guest_names; let i = index">
              <ng-container *ngIf="guestName">
                {{ guestName }}<span *ngIf="i !== additionalInfo.request?.guest_names?.length - 1">, </span>
              </ng-container>
            </span>
          </div>
        </div>

        <div>
          <div>
            <strong>{{ 'ALLOTMENT_FINDER.EVENT_NAME' | translate }} :</strong>
            {{ additionalInfo?.event?.name }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.EVENT_DATE_FROM' | translate }} :</strong>
            {{ additionalInfo?.event?.date_from | date : env.pipeDateFormat }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.EVENT_DATE_TO' | translate }} :</strong>
            {{ additionalInfo?.event?.date_to | date : env.pipeDateFormat }}
          </div>

          <div>
            <strong>{{ 'ALLOTMENT_FINDER.EVENT_LOCATION' | translate }} :</strong>

            <ng-container *ngIf="additionalInfo?.event?.venue">
              {{ additionalInfo.event.venue?.city?.name }}, {{ additionalInfo.event.venue?.city?.country?.name }}
            </ng-container>

            <ng-container *ngIf="additionalInfo?.event?.city">
              {{ additionalInfo.event.city?.name }}, {{ additionalInfo.event.venue?.city?.name }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-items-end" [formGroup]="form">
      <div class="additional-filter-holder relative">
        <app-event-select
          [parentFormGroup]="form"
          [onlyIncoming]="false"
          [onlyActive]="false"
          [includes]="'venue'"
          (select)="eventFilterChange()"
        ></app-event-select>
      </div>

      <div class="additional-filter-holder-xs relative ml-2">
        <label>{{ 'ALLOTMENT_FINDER.RADIUS' | translate }}</label>
        <input (keyup)="eventFilterChange()" type="number" class="form-control" formControlName="radius" />
      </div>

      <div class="additional-filter-holder-xs relative ml-2 mr-2">
        <label>{{ 'ALLOTMENT_FINDER.ADDITIONAL_DAYS' | translate }}</label>
        <input (keyup)="eventFilterChange()" type="number" class="form-control" formControlName="additional_days" />
      </div>

      <div>
        <button
          [disabled]="!this.form.get('event').value || !this.form.get('radius').value"
          type="button"
          class="btn btn-success btn-input"
          (click)="loadData(state, true)"
        >
          {{ 'HOTELS.HOTELS_LIST.SEARCH' | translate }}
        </button>

        <button type="button" class="btn btn-danger btn-input" (click)="clearFilters()">
          {{ 'HOTELS.HOTELS_LIST.CLEAR_FILTERS' | translate }}
        </button>
      </div>

      <div class="ml-3">
        <button type="button" class="btn btn-secondary btn-input" aria-label="Add" (click)="add()">
          {{ 'ALLOTMENT_FINDER.ADD' | translate }}
        </button>
      </div>
    </div>
  </div>
  <kendo-grid
    [data]="data"
    [pageSize]="hotelBookingsRoomsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'ALLOTMENT_FINDER.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'ALLOTMENT_FINDER.ID' | translate }}"
      filter="numeric"
      [width]="150"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="hotel.name"
      title="{{ 'ALLOTMENT_FINDER.HOTEL' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('hotel.name')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="'/hotel-bookings/view'" [queryParams]="{ id: dataItem.booking.id }" target="_blank">
          {{ dataItem?.hotel?.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      width="80"
      field="hotel.stars"
      title="{{ 'ALLOTMENT_FINDER.STARS' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('hotel.stars')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="bookingTags"
      title="{{ 'ALLOTMENT_FINDER.TAGS' | translate }}"
      filter="text"
      [sortable]="false"
      [hidden]="isHiddenColumn('bookingTags', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-kendo-tag-select
          [filter]="filter"
          [model]="LaravelModel.HOTELBOOKING"
          field="bookingTags"
        ></app-kendo-tag-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let tag of dataItem?.booking?.tags; let i = index" [ngbTooltip]="tag" container="body">
          {{ tag }}<span *ngIf="i !== dataItem?.booking?.tags?.length - 1">, </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.events.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_LIST.EVENTS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.events.name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter" [field]="'event_id'" *ngIf="formPatched"> </app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngFor="let event of dataItem.booking?.events; let i = index">
          <a [routerLink]="['/events/view']" [queryParams]="{ id: event.id }" target="_blank">
            {{ event.formattedName }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.company.abbreviation"
      title="{{ 'ALLOTMENT_FINDER.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.company.abbreviation', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter" [field]="'booking.company_id'"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/settings/companies/view']"
          [queryParams]="{ id: dataItem?.booking.company_id }"
          target="_blank"
        >
          {{ dataItem?.booking?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.property.value"
      title="{{ 'ALLOTMENT_FINDER.PROPERTY' | translate }}"
      [hidden]="isHiddenColumn('booking.property.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_properties'"
          [field]="'booking.property_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.booking.property"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.note"
      title="{{ 'ALLOTMENT_FINDER.BOOKING_NOTE' | translate }}"
      [hidden]="isHiddenColumn('booking.note')"
      [filterable]="false"
      [sortable]="false"
      [width]="30"
      [columnMenu]="false"
      class="text-center"
      headerClass="text-center"
    >
      <ng-template kendoGridHeaderTemplate>
        <i class="fas fa-sticky-note"></i>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem?.booking?.note" [ngbTooltip]="dataItem?.booking?.note" container="body">
          <i class="fal fa-sticky-note"></i>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.cancellation_terms.value"
      title="{{ 'ALLOTMENT_FINDER.CANCELLATION_TERMS' | translate }}"
      [hidden]="isHiddenColumn('booking.cancellation_terms.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_cancellation_terms'"
          [field]="'booking.cancellation_terms_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.booking.cancellation_terms"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.supplier.name"
      title="{{ 'ALLOTMENT_FINDER.SUPPLIER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.supplier.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-supplier-select [filter]="filter" [field]="'booking.supplier_id'"></app-kendo-supplier-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.city.country.name"
      title="{{ 'ALLOTMENT_FINDER.COUNTRY' | translate }}"
      [hidden]="isHiddenColumn('hotel.city.country.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-country-select [field]="'hotel.city.country_id'" [filter]="filter"></app-kendo-country-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-country-box [country]="dataItem?.hotel?.city?.country"></app-country-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.city.name"
      title="{{ 'ALLOTMENT_FINDER.CITY' | translate }}"
      [hidden]="isHiddenColumn('hotel.city.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-city-select [filter]="filter" [field]="'hotel.city_id'"></app-kendo-city-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="form.get('event').value"
      field="distance_to_fair"
      title="{{ 'ALLOTMENT_FINDER.KM_TO_FAIR' | translate }}"
      [hidden]="isHiddenColumn('distance_to_fair')"
      [filterable]="false"
      [sortable]="false"
    ></kendo-grid-column>

    <kendo-grid-column
      field="hotel.distance_to_center"
      filter="numeric"
      title="{{ 'ALLOTMENT_FINDER.DISTANCE_TO_CENTER_SHORT' | translate }}"
      [hidden]="isHiddenColumn('hotel.distance_to_center')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="available_rooms"
      filter="numeric"
      title="{{ 'ALLOTMENT_FINDER.ROOMS_COUNT' | translate }}"
      [hidden]="isHiddenColumn('available_rooms')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="gte">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="type.value"
      title="{{ 'ALLOTMENT_FINDER.TYPE' | translate }}"
      [hidden]="isHiddenColumn('type.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select [groupType]="'rooms'" [filter]="filter"></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.type"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="meal_plan.value"
      title="{{ 'ALLOTMENT_FINDER.MEAL_PLAN_SHORT' | translate }}"
      [hidden]="isHiddenColumn('meal_plan.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'room_meal_plans'"
          [field]="'meal_plan_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.meal_plan"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_in"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'ALLOTMENT_FINDER.CHECK_IN' | translate }}"
      [hidden]="isHiddenColumn('check_in')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_in | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_out"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'ALLOTMENT_FINDER.CHECK_OUT' | translate }}"
      [hidden]="isHiddenColumn('check_out')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_out | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.cancellation_deadline"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'ALLOTMENT_FINDER.CANCELLATION_DEADLINE_SHORT' | translate }}"
      [hidden]="isHiddenColumn('booking.cancellation_deadline', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem?.booking?.cancellation_deadline | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="net_price_eur_avg"
      filter="numeric"
      title="{{ 'ALLOTMENT_FINDER.NET_PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('net_price_eur_avg')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.net_price_eur_avg | number : '1.2-2' }}
        {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="price_avg"
      filter="numeric"
      title="{{ 'ALLOTMENT_FINDER.PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('price')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.price_avg | number : '1.2-2' }}
        {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="margin_avg"
      filter="numeric"
      title="{{ 'ALLOTMENT_FINDER.MARGIN_AVG' | translate }}"
      [hidden]="isHiddenColumn('margin_avg')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.margin_avg | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <!--    <kendo-grid-column-->
    <!--      field="commission"-->
    <!--      [filterable]="false"-->
    <!--      title="{{ 'ALLOTMENT_FINDER.COMMISSION_AVG' | translate }}"-->
    <!--      [hidden]="isHiddenColumn('commission', true)"-->
    <!--    >-->
    <!--      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
    <!--        ??????????????-->
    <!--      </ng-template>-->
    <!--    </kendo-grid-column>-->

    <kendo-grid-column
      title="{{ 'ALLOTMENT_FINDER.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button *ngIf="returnValue" (click)="select(dataItem)" type="button" class="btn btn-xs btn-info">
          {{ 'ALLOTMENT_FINDER.SELECT' | translate }}
        </button>

        <app-edit-button (click)="edit(dataItem.booking)"></app-edit-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
    </ng-template>
  </kendo-grid>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark btn-xs" (click)="modal.dismiss()">
    {{ 'ALLOTMENT_FINDER.CLOSE' | translate }}
  </button>
</div>
