import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiQuery, GeoFenceFilter } from '@app/core/http/api-query';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';
import { SalesOffersService } from '@app/sales/sales/sales-offers.service';
import { Logger } from '@app/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { DataStateChangeEvent, RowClassArgs } from '@progress/kendo-angular-grid';
import { Room } from '@app/hotels/rooms/room.model';
import { Night } from '@app/hotels/rooms/night.model';
import { HotelBookingsService } from '@app/hotels/hotel-bookings/hotel-bookings.service';
import { environment } from '@env/environment';
import { Sale } from '@app/sales/sales/sale.model';
import { SalesFinalService } from '@app/sales/sales/sales-final.service';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { CurrenciesAdditionsService } from '@app/shared/services/currencies-additions.service';
import { SaleOfferItem } from '@app/sales/sales/sale-offer-item.model';
import { BookingPropertiesType } from '@app/settings/types/booking-properties-type.enum';

const log = new Logger('SalesFinalFormComponent');

@Component({
  selector: 'app-sales-final-form',
  templateUrl: './sales-final-form.component.html',
  styleUrls: ['./sales-final-form.component.scss'],
})
export class SalesFinalFormComponent implements OnInit {
  @Input() sale: Sale = null;
  @Input() offerId: number = null;
  @Input() saleFinalId: number = null;
  offer: SaleOffer = null;
  form: FormGroup;
  additionalForm: FormGroup = this.formBuilder.group({
    fullAvailable: [true],
  });
  currentOfferLineId: number = null;
  env = environment;
  totals = {
    price: 0,
    priceNightsCount: 0,
    netPrice: 0,
    netPriceNightsCount: 0,
    margin: 0,
    marginNightsCount: 0,
  };
  yearsRangeFormatted = moment().subtract(10, 'years').format('YYYY') + ':' + moment().add(10, 'years').format('YYYY');

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private salesOffersService: SalesOffersService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private hotelBookingsService: HotelBookingsService,
    private salesFinalService: SalesFinalService,
    private hotelBookingsFormService: HotelBookingsFormService
  ) {}

  ngOnInit() {
    // log.debug('this.offer', this.offer);

    this.loadData();

    this.createForm();

    this.patchForm();
  }

  save(closeAfter: boolean = true) {
    if (this.saleFinalId) {
      this.salesFinalService.update(this.sale.id, this.formatPost()).subscribe((res: any) => {
        if (closeAfter) {
          return this.modal.close({
            result: res,
          });
        } else {
          this.patchForm();
          this.getCurrentOfferLineHotelRooms();
        }
        this.translate.get('SALES.SALES_FINAL_FORM.FINAL_UPDATED_MSG').subscribe((trans: string) => {
          this.toastr.success(trans);
        });
      });
    } else {
      this.salesFinalService.store(this.sale.id, this.formatPost()).subscribe((res: any) => {
        log.debug('res', res);
        if (closeAfter) {
          return this.modal.close({
            result: res,
          });
        } else {
          this.saleFinalId = res.id;
          this.patchForm();
          this.getCurrentOfferLineHotelRooms();
        }
        this.translate.get('SALES.SALES_FINAL_FORM.FINAL_UPDATED_MSG').subscribe((trans: string) => {
          this.toastr.success(trans);
        });
      });
    }
  }

  getCurrentOfferLineHotelRooms() {
    this.offer?.items.forEach((offerLine: any) => {
      if (offerLine.id === this.currentOfferLineId) {
        this.getHotelRooms(offerLine);
      }
    });
  }

  formatPost() {
    const formatted = JSON.parse(JSON.stringify(this.form.value));

    formatted.items.forEach((item: any) => {
      log.debug('item', item);
      item.check_in = moment(item.check_in).format(this.env.apiPipeDateFormat);
      item.check_out = moment(item.check_out).format(this.env.apiPipeDateFormat);
    });

    log.debug('this.form.value', this.form.value, formatted);
    return formatted;
  }

  log(something: any) {
    log.debug(something);
    log.debug(this.form);
    log.debug(this.currentOfferLineId);
    log.debug(this.offer);
    log.debug('sf', this.saleFinalId);
  }

  /* tslint:disable */
  getHotelRooms(offerLIne: any, state: DataStateChangeEvent = null) {
    offerLIne.state = state;

    const query = new ApiQuery();
    let eventFilter = offerLIne.filters.event ? offerLIne.filters.event.id : null;

    // Мята toastr съобщение ако е въведен радиус а не е избран евент
    if (offerLIne.filters.radius && !offerLIne.filters.event) {
      this.translate.get('SALES.SALES_OFFERS_FORM.EVENT_NOT_FOUND').subscribe((trans: string) => {
        this.toastr.info(trans);
      });
    }

    // Проверява дали избрания във филтъра event има закачено venue
    if (offerLIne.filters.radius && offerLIne.filters.event && !offerLIne.filters.event.venue) {
      this.translate.get('SALES.SALES_OFFERS_FORM.EVENT_VENUE_NOT_FOUND').subscribe((trans: string) => {
        this.toastr.error(trans + offerLIne.filters.event.name);
      });
    }

    // Проверява дали избрания във филтъра event има закачено venue и дали то има координати
    if (
      offerLIne.filters.radius &&
      offerLIne.filters.event &&
      offerLIne.filters.event.venue &&
      (!offerLIne.filters.event.venue.lat || !offerLIne.filters.event.venue.long)
    ) {
      this.translate.get('SALES.SALES_OFFERS_FORM.EVENT_VENUE_LAT_LONG_NOT_FOUND').subscribe((trans: string) => {
        this.toastr.error(trans + offerLIne.filters.event.venue.name);
      });
    }

    // Ако има валиден филтър закача към query-то geofence
    if (
      offerLIne.filters.radius &&
      offerLIne.filters.event &&
      offerLIne.filters.event.venue &&
      offerLIne.filters.event.venue.lat &&
      offerLIne.filters.event.venue.long
    ) {
      const geoFenceFilter = new GeoFenceFilter();
      geoFenceFilter.lat = Number(offerLIne.filters.event.venue.lat);
      geoFenceFilter.long = Number(offerLIne.filters.event.venue.long);
      geoFenceFilter.inner_radius = 0;
      geoFenceFilter.outer_radius = Number(offerLIne.filters.radius);
      query.setGeoFence(geoFenceFilter, 'hotel.geofence');
      eventFilter = null;
    }

    const formatedPost = {
      'booking.property_id': offerLIne.filters.property_id ? offerLIne.filters.property_id : null,
      'booking.ref_number': offerLIne.filters.ref_number ? offerLIne.filters.ref_number : null,
      check_in: offerLIne.filters.check_in
        ? moment(offerLIne.filters.check_in).format(this.env.apiPipeDateFormat)
        : null,
      check_out: offerLIne.filters.check_out
        ? moment(offerLIne.filters.check_out).format(this.env.apiPipeDateFormat)
        : null,
      'booking.supplier_id': offerLIne.filters.supplier ? offerLIne.filters.supplier.id : null,
      event_id: offerLIne.filters.event ? offerLIne.filters.event.id : null,
      'hotel_booking_rooms.hotel_id': offerLIne.hotel_id,
      'hotel_booking_rooms.type_id': offerLIne.filters.type_id ? offerLIne.filters.type_id : null,
      meal_plan_id: offerLIne.filters.meal_plan_id ? offerLIne.filters.meal_plan_id : null,
    };

    // сетва филтър за дати след offerLIne.check_in, като пази state-a от таблицата
    const filter: DataStateChangeEvent = {
      filter: {
        filters: [{ field: 'check_in', operator: 'gte', value: moment(offerLIne.check_in).subtract(20, 'days') }],
        logic: 'and',
      },
      group: undefined,
      skip: offerLIne.state ? offerLIne.state.skip : 0,
      sort: offerLIne.state ? offerLIne.state.sort : undefined,
      take: offerLIne.state ? offerLIne.state.take : 0,
    };

    query.setDataSetFilters(filter).addFilters(formatedPost).addIncludes(
      'hotel.venues',
      'booking.supplier',
      'booking.company',
      'booking.property',
      // 'hotel.city.country',
      'type',
      'meal_plan',
      'nights',
      'nights.price_currency',
      'nights.net_price_currency'
    );

    if (this.additionalForm.get('fullAvailable').value) {
      query.addFilter('full_available', true);
    }

    this.hotelBookingsService.indexRooms(query).subscribe((res: any) => {
      res.data.forEach((room: Room) => {
        log.debug('room', room);

        room.hotel.venues.forEach((venue: any) => {
          if (venue.id === this.sale.event.venue_id) {
            room['distance_to_fair'] = venue.pivot.distance;
          }
        });

        // if (room.nights) {
        //   let price = 0;
        //   let netPrice = 0;
        //   room.nights.forEach((night: Night) => {
        //     price += Number(night.price);
        //     netPrice += Number(night.net_price);
        //   });
        //   room['price'] = price / room.nights.length;
        //   room['price_currency'] = room.nights[0].price_currency;
        //   room['net_price'] = CurrenciesAdditionsService.toEUR(
        //     netPrice / room.nights.length,
        //     room.nights[0].net_price_currency
        //   );
        //   // room['net_price_currency'] = room.nights[0].net_price_currency;
        //   room['client_margin'] = offerLIne.price - room['net_price'];
        // }

        room['count'] = room.available_rooms;
        room['price'] = room.price_avg;
        room['price_currency'] = room.booking.currency;
        room['net_price'] = room.net_price_eur_avg;
        room['client_margin'] = offerLIne.price - room['net_price'];

        // ако allotment_id на стаята съвпада с allotment_id от офертната линия слага allotment_id_match
        this.offer.items.forEach((offerItem: SaleOfferItem) => {
          if (offerItem.allotment_id === room.allotment_id) {
            room['allotment_id_match'] = true;
          }
        });

        // вдига общата бройка на стаите с толкова колкото са добавени в този final
        this.form.get('items').value.forEach((item: any) => {
          if (item.data.allotment_id === room.allotment_id && item.id) {
            room['count'] += Number(item.data['room_count']);
          }
        });

        room['room_count'] = null;
      });
      offerLIne.roomsResults = res;
      this.syncAvailableRooms();
    });

    // share-ва филтрите между всички offers
    this.offer.items.forEach((offerLine2: any) => {
      offerLine2.filters = offerLIne.filters;
    });
  }

  /* tslint:enable */

  // Слага клас на целия ред ако allotment_id_match e true
  roomRowCallback(context: RowClassArgs) {
    let allotmentIdMatch = false;

    if (context.dataItem.allotment_id_match) {
      allotmentIdMatch = true;
    }
    return {
      'kendo-row-allotment-id-match': allotmentIdMatch,
    };
  }

  /**
   * Смята колко свободни стаи има в allotment-a
   */
  syncAvailableRooms() {
    if (!this.offer.items) {
      return false;
    }

    this.offer.items.forEach((offerLine: any) => {
      if (offerLine.roomsResults) {
        offerLine.roomsResults.data.forEach((room: Room) => {
          room['available_count'] = room.count;

          this.form.get('items').value.forEach((item: any) => {
            if (room.allotment_id === item.data.allotment_id) {
              room['available_count'] -= Number(item.data.room_count);
            }
          });
        });
      }
    });
  }

  setCurrentOfferLine(id: number, offerLine: any) {
    if (offerLine) {
      this.getHotelRooms(offerLine);
    }

    if (id === this.currentOfferLineId) {
      return false;
    }
    if (this.form.invalid) {
      this.translate.get('SALES.SALES_FINAL_FORM.INVALID_VALUES_TOASTR_MSG').subscribe((trans: string) => {
        this.toastr.error(trans);
      });
      return false;
    }
    this.currentOfferLineId = id;

    setTimeout(() => {
      // this.form.get('items').patchValue(this.form.controls.items.value);
    });
  }

  getNightsCount(date1: string | Date, date2: string | Date) {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }

  addRow(room: any, offerLIne: any) {
    log.debug('room', room, 'offerLIne', offerLIne);

    const editedRoom = Object.assign({}, room);

    editedRoom.price = offerLIne.price;
    log.debug('editedRoom', editedRoom);

    /**
     * Ако дата-та на нощувката е в range-a на датите от офертата взима net_price.
     * Прави се с цел да осредни net_price само от нощувките, които реално ще се продадът, а не от целия allotment.
     */

    // editedRoom.nights.forEach((night: Night) => {
    //   if (
    //     moment(night.date).isBetween(moment(offerLIne.check_in), moment(offerLIne.check_out)) ||
    //     moment(night.date).isSame(moment(offerLIne.check_in))
    //   ) {
    //     avgNetPrice += Number(night.net_price);
    //     nightsInRange++;
    //     log.debug('night.date', night.date);
    //     log.debug('night.net_price', night.net_price);
    //   }
    // });
    //
    // editedRoom.net_price = CurrenciesAdditionsService.toEUR(
    //   avgNetPrice / nightsInRange,
    //   editedRoom.nights[0].net_price_currency
    // );

    editedRoom.net_price = null;

    // това са датите от стаята
    const editedRoomCheckInMoment = moment(editedRoom.check_in);
    const editedRoomCheckOutMoment = moment(editedRoom.check_out);
    editedRoom.check_in = editedRoomCheckInMoment.toDate();
    editedRoom.check_out = editedRoomCheckOutMoment.toDate();

    // това са датите на item-a(те се пращат към api-то)
    const checkInMoment = moment(offerLIne.check_in);
    const checkOutMoment = moment(offerLIne.check_out);
    let checkIn = checkInMoment.toDate();
    let checkOut = checkOutMoment.toDate();

    // не позволява check_in дата да е преди check_in дата на стаята
    if (checkInMoment.isBefore(editedRoomCheckInMoment)) {
      checkIn = editedRoomCheckInMoment.toDate();
    }

    // не позволява check_in дата да е след check_out дата на стаята
    if (checkInMoment.isAfter(editedRoomCheckOutMoment)) {
      checkIn = editedRoomCheckOutMoment.toDate();
    }

    // не позволява check_out дата да е преди check_in дата на стаята
    if (checkOutMoment.isBefore(editedRoomCheckInMoment)) {
      checkOut = editedRoomCheckInMoment.toDate();
    }

    // не позволява check_out дата да е след check_out дата на стаята
    if (checkOutMoment.isAfter(editedRoomCheckOutMoment)) {
      checkOut = editedRoomCheckOutMoment.toDate();
    }

    const item = {
      sale_offer_item_id: offerLIne.id,
      hotel_booking_room_id: room.id,
      count: room.room_count,
      data: editedRoom,
      check_in: checkIn,
      check_out: checkOut,
    };
    this.form.get('items').value.push(item);
    this.calculateTotals();
    this.syncAvailableRooms();
  }

  removeRow(rowIndex: number) {
    this.form.get('items').value.splice(rowIndex, 1);
    this.form.get('items').updateValueAndValidity();
    this.calculateTotals();
    this.syncAvailableRooms();
  }

  /**
   * Проверява дали подадената дата е в range от дати
   * @param date Дата
   * @param firstDateRange начална дата на range-a
   * @param secondDateRange крайна дата на range-a
   */
  isBetween(date: string, firstDateRange: string, secondDateRange: string): boolean {
    return moment(date).isBetween(moment(firstDateRange), moment(secondDateRange));
  }

  /**
   *  Проверява дали първата дата е преди или еднаква с втората
   * @param firstDate Първа дата
   * @param secondDate Втора дата
   */
  isBefore(firstDate: string, secondDate: string): boolean {
    return moment(firstDate).isSameOrBefore(moment(secondDate));
  }

  /**
   * edit hotel booking then reload current line search
   * @param hotelBooking HotelBooking
   * @param offerLine Offerline Трябва му за да знае след ъпдейт резултатите на кой line да ъпдейтне
   * @param allotmentId allotmentId
   */
  editHotelBooking(hotelBooking: HotelBooking, offerLine: any, allotmentId: string): void {
    // log.debug('hotelBooking', hotelBooking);
    this.hotelBookingsFormService.open(new HotelBooking().deserialize(hotelBooking), allotmentId).then(
      (res: any) => {
        log.debug(res);
        this.getHotelRooms(offerLine);
      },
      (res) => {
        log.debug(res);
      }
    );
  }

  addHotelBooking(offerLine: any): void {
    this.hotelBookingsFormService
      .open(
        new HotelBooking().deserialize({
          currency_id: offerLine.currency_id,
          currency: offerLine.currency,
          hotel_id: offerLine.hotel_id,
          hotel: offerLine.hotel,
          supplier_id: offerLine.supplier_id,
          supplier: offerLine.supplier,
          company_id: offerLine.company_id,
          events: [this.sale.event],
          rooms: [
            {
              check_in: offerLine.check_in,
              check_out: offerLine.check_out,
              count: offerLine.count,
              meal_plan_id: offerLine.meal_plan_id,
              type_id: offerLine.type_id,
              people: offerLine.people,
              fake_net_price: offerLine.net_price.toFixed(2),
              fake_price: offerLine.price.toFixed(2),
              fake_web_price: offerLine.price.toFixed(2),
            },
          ],
        })
      )
      .then(
        (response) => {
          this.getHotelRooms(offerLine);
        },
        (err) => {
          log.info('modal closed');
        }
      );
  }

  /**
   * Смята тоталите
   */
  calculateTotals(): void | boolean {
    this.totals.price = 0;
    this.totals.priceNightsCount = 0;
    this.totals.netPrice = 0;
    this.totals.netPriceNightsCount = 0;
    this.totals.margin = 0;
    this.totals.marginNightsCount = 0;
    if (!this.form.get('items').value) {
      return false;
    }

    this.form.get('items').value.forEach((item: any) => {
      log.debug('calc', item);

      if (item.data.nights) {
        let netPrice = 0;
        let nightsInRange = 0;
        item.data.nights.forEach((night: Night) => {
          /**
           * Ако дата-та на нощувката е в range-a на датите от офертата взима net_price.
           * Прави се с цел да осредни net_price само от нощувките, които реално ще се продадът, а не от целия allotment.
           */
          if (
            moment(night.date).isBetween(moment(item.check_in), moment(item.check_out)) ||
            moment(night.date).isSame(moment(item.check_in))
          ) {
            netPrice += Number(night.net_price);
            nightsInRange++;
          } else if (night.min_night) {
            netPrice += Number(night.net_price);
          }
        });

        log.debug('nightsInRange', nightsInRange);

        item.data['net_price'] = CurrenciesAdditionsService.toEUR(
          netPrice / nightsInRange,
          item.data.nights[0].net_price_currency
        );

        item.data['client_margin'] = Number(item.data.price) - item.data['net_price'];
      }

      this.totals.price += Number(item.data.price);
      this.totals.netPrice += Number(item.data.net_price);
      this.totals.margin += Number(item.data.client_margin);
      const nights = this.getNightsCount(item.check_in, item.check_out);
      this.totals.priceNightsCount += Number(item.data.price) * Number(item.data.room_count) * Number(nights);
      this.totals.netPriceNightsCount += Number(item.data.net_price) * Number(item.data.room_count) * Number(nights);
      this.totals.marginNightsCount += item.data.client_margin * item.data.room_count * Number(nights);
    });
  }

  editSelectedHotelBooking(hotelBooking: HotelBooking, nights: Array<Night>) {
    this.hotelBookingsFormService.open(new HotelBooking().deserialize(hotelBooking), null, nights).then(
      (res: any) => {
        this.patchForm();
      },
      (res: any) => {
        log.debug('res', res);
      }
    );
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      sale_offer_id: [this.offerId, [Validators.required]],
      payment_method_id: [null, [Validators.required]],
      payment_method: [null],
      client_penalty: [null],
      items: [[]],
    });
  }

  private patchForm(): void {
    if (this.saleFinalId) {
      const query = new ApiQuery().addIncludes(
        'items.hotel_booking_room.hotel.city.country',
        'items.hotel_booking_room.hotel.venues',
        'items.hotel_booking_room.booking.supplier',
        'items.hotel_booking_room.booking.company',
        'items.hotel_booking_room.type',
        'items.hotel_booking_room.nights',
        'items.hotel_booking_room.nights.net_price_currency',
        'items.hotel_booking_room.meal_plan',
        'items.sale_offer_item',
        'items.hotel_booking_nights.net_price_currency'
      );
      this.salesFinalService.show(this.sale.id, this.saleFinalId, query).subscribe((offer: SaleOffer) => {
        offer.items.map((item: any) => {
          item['hotel'] = item.hotel_booking_room.hotel;
          item['data'] = item.hotel_booking_room;
          item['data'].check_in = moment(item['data'].check_in).toDate();
          item['data'].check_out = moment(item['data'].check_out).toDate();
          item['data']['price'] = item.sale_offer_item.price;
          item['check_in'] = moment(item.check_in).toDate();
          item['check_out'] = moment(item.check_out).toDate();
          item['data']['room_count'] = item.count;

          // if (item.hotel_booking_room.nights) {
          //   let netPrice = 0;
          //   let nightsInRange = 0;
          //   item.hotel_booking_room.nights.forEach((night: Night) => {
          //     /**
          //      * Ако дата-та на нощувката е в range-a на датите от офертата взима net_price.
          //      * Прави се с цел да осредни net_price само от нощувките, които реално ще се продадът, а не от целия allotment.
          //      */
          //     if (
          //       moment(night.date).isBetween(
          //         moment(item.sale_offer_item.check_in),
          //         moment(item.sale_offer_item.check_out)
          //       ) ||
          //       moment(night.date).isSame(moment(item.sale_offer_item.check_in))
          //     ) {
          //       netPrice += Number(night.net_price);
          //       nightsInRange++;
          //     }
          //   });
          //
          //   item.hotel_booking_room['net_price'] = CurrenciesAdditionsService.toEUR(
          //     netPrice / nightsInRange,
          //     item.hotel_booking_room.nights[0].net_price_currency
          //   );
          //
          //   item.hotel_booking_room['client_margin'] =
          //     Number(item.sale_offer_item.price) - item.hotel_booking_room['net_price'];
          // }
          item.hotel_booking_room['net_price'] = null;
          item.hotel_booking_room['client_margin'] = null;

          item.hotel_booking_room.hotel.venues.forEach((venue: any) => {
            if (venue.id === this.sale.event.venue_id) {
              item['data']['distance_to_fair'] = venue.pivot.distance;
            }
          });

          return item;
        });

        log.debug('patch offer', offer);
        this.form.patchValue(offer);
        this.calculateTotals();
      });
    }
  }

  private loadData() {
    const query = new ApiQuery().addIncludes(
      'items.hotel.city.country',
      'items.hotel.venues',
      'items.type',
      'items.meal_plan',
      'items.currency',
      'items.supplier'
    );
    this.salesOffersService.show(this.sale.id, this.offerId, query).subscribe((offer: SaleOffer) => {
      this.offer = offer;

      this.offer.items.forEach((offerLIne: any) => {
        offerLIne['filters'] = {
          property_id: BookingPropertiesType.ADV,
          ref_number: null,
          check_in: null,
          check_out: null,
          supplier: null,
          event: this.sale.event,
          hotel: null,
          type_id: null,
          meal_plan_id: null,
          radius: null,
        };
        //  this.getHotelRooms(offerLIne);
      });
    });
  }
}
