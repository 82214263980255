<div class="container-fluid">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">User assignments</h5>
    </div>

    <div class="d-flex align-items-center">
      <app-kendo-types-select
        [field]="'sale_status_id'"
        (change)="this.saleStatusChange($event)"
        [groupType]="'sale_status'"
        class="mr-3"
      ></app-kendo-types-select>
      <app-kendo-types-select
        [field]="'sale_request_status_id'"
        (change)="this.saleRequestStatusChange($event)"
        [groupType]="'sale_request'"
      ></app-kendo-types-select>
      <div>
        <button (click)="clearFilters()" class="btn btn-link" type="button">
          {{ 'CLEAR_FILTERS' | translate }}
        </button>
      </div>
    </div>
  </div>

  <kendo-grid
    (dataStateChange)="loadData($event)"
    [columnMenu]="false"
    [data]="data"
    [filter]="state?.filter"
    [filterable]="true"
    [loading]="loader"
    [pageSize]="requestsResponse?.meta.per_page"
    [pageable]="false"
    [skip]="state?.skip"
    [sort]="state?.sort"
    [sortable]="false"
  >
    <kendo-grid-column field="fake_names" filter="text" title="Names">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-user-box [user]="dataItem"></app-user-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [field]="'roles.id'" filter="text" title="Roles">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let role of dataItem.roles" class="small">{{ role.name }}</div>
      </ng-template>

      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-role-select [field]="'roles.id'" [filter]="filter"></app-kendo-role-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" [filter]="false" title="Dashboard">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.assigned_sales_to_now_count }}
      </ng-template>
      <!--      <ng-template kendoGridFilterCellTemplate let-filter>-->
      <!--        <app-kendo-types-select-->
      <!--          [groupType]="'sale_request'"-->
      <!--          [filter]="filter"-->
      <!--          [field]="'assigned_sales_to_now.requests.status_id'"-->
      <!--        ></app-kendo-types-select>-->
      <!--      </ng-template>-->
      <ng-template kendoGridFooterTemplate>
        {{ totalToNow }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Total as agent">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.total }}
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        {{ total }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</div>
