<div
  class="event-view-wrapper relative view-with-static-right-sidebar ps"
  [ngClass]="{ 'sidebar-opened': activitiesService.isOpened }"
  [perfectScrollbar]
  #perfectScroll="ngxPerfectScrollbar"
>
  <app-side-button
    [icon]="'far fa-history'"
    [customClasses]="'btn-activity-floating additional-offset'"
    (click)="openActivities()"
    *ngIf="!activitiesService.isOpened && eventId"
  ></app-side-button>

  <div class="top">
    <div class="preview-header d-flex justify-content-between">
      <div class="preview-title-wrapper">
        <span class="title">{{ event?.name }}</span>
        <span class="additional-info">
          <span class="sub-title">
            {{ 'EVENTS.EVENTS_VIEW.ACTIVE' | translate }}
          </span>
          <span class="value mr-2">
            <app-active-indicator [active]="event?.active"></app-active-indicator>
          </span>

          <span class="sub-title">
            {{ 'EVENTS.EVENTS_VIEW.SHOW_TO_WEB' | translate }}
          </span>
          <span class="value mr-2" *ngIf="event?.active">
            <app-active-indicator [active]="event?.show_to_web"></app-active-indicator>
          </span>

          <app-type-badge [type]="event?.type"></app-type-badge>
        </span>
      </div>
      <div class="preview-btns-wrapper d-flex align-items-stretch">
        <app-seo-button
          class="mr-2"
          *ngIf="
            event &&
            accessService.hasAccess(
              [UserRoleType.IT, UserRoleType.MARKETING, UserRoleType.LEAD_GENERATION],
              ['events.translations']
            )
          "
          (click)="editSeo(event)"
        ></app-seo-button>

        <app-reminder-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.IT,
                UserRoleType.MARKETING
              ],
              []
            )
          "
          [reminderableId]="eventId"
          [reminderableType]="LaravelModel.EVENT"
        >
        </app-reminder-button>

        <button
          *ngIf="accessService.hasAccess([UserRoleType.LEAD_GENERATION], ['events.show'])"
          type="button"
          class="btn btn-sm btn-success mr-2 ml-2"
          (click)="edit()"
        >
          {{ 'EVENTS.EVENTS_VIEW.EDIT_EVENT_BTN' | translate }}
        </button>

        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['hotel_bookings.store']
            )
          "
          type="button"
          class="btn btn-sm btn-info"
          (click)="addBooking()"
        >
          {{ 'EVENTS.EVENTS_VIEW.NEW_BOOKING_BTN' | translate }}
        </button>
      </div>
    </div>

    <div class="d-flex flex-wrap">
      <div class="w-50 border-right">
        <div class="pl-3 pr-3">
          <div class="row">
            <div class="col-sm-6">
              <div *ngIf="event?.event_group?.name" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fal fa-layer-group"></i>
                  {{ 'EVENTS.EVENTS_VIEW.EVENT_GROUP' | translate }}
                </div>
                <div class="value">
                  {{ event?.event_group?.name }}
                </div>
              </div>

              <div *ngIf="event?.organizer" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-user"></i>
                  {{ 'EVENTS.EVENTS_VIEW.ORGANIZER' | translate }}
                </div>
                <div class="value">
                  {{ event?.organizer }}
                </div>
              </div>

              <div *ngIf="event?.industry" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-industry"></i>
                  {{ 'EVENTS.EVENTS_VIEW.INDUSTRY' | translate }}
                </div>
                <div class="value">
                  {{ event?.industry?.name }}
                </div>
              </div>

              <div *ngIf="event?.exhibitors" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fas fa-sort-numeric-down"></i>
                  {{ 'EVENTS.EVENTS_VIEW.EXHIBITORS' | translate }}
                </div>
                <div class="value">
                  {{ event?.exhibitors }}
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div *ngIf="event?.venue?.city" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fal fa-location-circle"></i>
                  {{ 'EVENTS.EVENTS_VIEW.CITY' | translate }}
                </div>
                <div class="value">
                  {{ event?.venue?.city?.name }}
                  <ng-container *ngIf="event?.venue?.city?.country">
                    , {{ event?.venue?.city?.country?.name }}
                  </ng-container>
                </div>
              </div>

              <div *ngIf="event?.city" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fal fa-location-circle"></i>
                  {{ 'EVENTS.EVENTS_VIEW.CITY' | translate }}
                </div>
                <div class="value">
                  {{ event?.city?.name }}
                  <ng-container *ngIf="event?.city?.country"> , {{ event?.city?.country?.name }} </ng-container>
                </div>
              </div>

              <div *ngIf="event?.venue" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-map-marker-check"></i>
                  {{ 'EVENTS.EVENTS_VIEW.VENUE' | translate }}
                </div>
                <div class="value">
                  {{ event?.venue?.name }}
                </div>
              </div>

              <div *ngIf="event?.year_exhibitors" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-calendar-day"></i>
                  {{ 'EVENTS.EVENTS_VIEW.YEAR_EXHIBITORS' | translate }}
                </div>
                <div class="value">
                  {{ event?.year_exhibitors }}
                </div>
              </div>

              <div *ngIf="event?.visitors" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fas fa-sort-numeric-down"></i>
                  {{ 'EVENTS.EVENTS_VIEW.VISITORS' | translate }}
                </div>
                <div class="value">
                  {{ event?.visitors }}
                </div>
              </div>

              <div *ngIf="event?.event_group?.repeat?.value" class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fa fa-repeat" aria-hidden="true"></i>
                  {{ 'EVENTS.EVENTS_VIEW.REPEAT' | translate }}
                </div>
                <div class="value">
                  {{ event?.event_group?.repeat?.value }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="m-0" />

        <div class="pl-3 pr-3 pb-3">
          <div class="row">
            <div class="col-sm-6" *ngIf="event?.link_exhibitors">
              <div class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-globe"></i>
                  {{ 'EVENTS.EVENTS_VIEW.LINK_EXHIBITORS' | translate }}
                </div>
                <div class="value word-break">
                  {{ event?.link_exhibitors }}
                </div>
              </div>
            </div>

            <div class="col-sm-3" *ngIf="event?.date_from">
              <div class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-calendar-plus"></i>
                  {{ 'EVENTS.EVENTS_VIEW.DATE_FROM' | translate }}
                </div>
                <div class="value">
                  {{ event?.date_from | date : env.pipeDateFormat }}
                </div>
              </div>
            </div>

            <div class="col-sm-3" *ngIf="event?.foreign_exhibitors">
              <div class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="fas fa-sort-amount-up"></i>
                  {{ 'EVENTS.EVENTS_VIEW.FOREIGN_EXHIBITORS' | translate }}
                </div>
                <div class="value">
                  {{ event?.foreign_exhibitors }}
                </div>
              </div>
            </div>

            <div class="col-sm-6" *ngIf="event?.website_url">
              <div class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-globe"></i>
                  {{ 'EVENTS.EVENTS_VIEW.WEBSITE_URL' | translate }}
                </div>
                <div class="value word-break">
                  {{ event?.website_url }}
                </div>
              </div>
            </div>

            <div class="col-sm-3" *ngIf="event?.date_to">
              <div class="mb-3 mt-3">
                <div class="sub-title">
                  <i class="far fa-calendar-minus"></i>
                  {{ 'EVENTS.EVENTS_VIEW.DATE_TO' | translate }}
                </div>
                <div class="value">
                  {{ event?.date_to | date : env.pipeDateFormat }}
                </div>
              </div>
            </div>

            <div class="col-sm-12" *ngIf="event?.note">
              <div class="note-box">
                <div class="sub-title">
                  <i class="far fa-sticky-note"></i>
                  {{ 'EVENTS.EVENTS_VIEW.NOTE' | translate }}
                </div>
                <div class="value m-0">
                  {{ event?.note }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-50" *ngIf="form && event">
        <div class="pt-2 pr-3 pl-3">
          <form id="event-search-form" [formGroup]="form" novalidate *ngIf="hotels?.length > 0">
            <div class="row align-items-end">
              <div class="col-sm-4">
                <!--                <app-hotel-select-->
                <!--                  [parentFormGroup]="form"-->
                <!--                  [filter]="'bookings_for_event_id'"-->
                <!--                  [filterValue]="eventId"-->
                <!--                  (select)="hotelsSearch()"-->
                <!--                ></app-hotel-select>-->

                <!--                <select class="form-control" formControlName="hotel" (change)="hotelsSearch()">-->
                <!--                  <option *ngFor="let hotel of hotels; let i = index" [value]="hotel">-->
                <!--                    {{hotel.name }}-->
                <!--                  </option>-->
                <!--                </select>-->
                <div class="form-group">
                  <label>{{ 'EVENTS.EVENTS_VIEW.HOTEL' | translate }}</label>
                  <ng-select [items]="hotels" formControlName="hotel" bindLabel="name" (change)="hotelsSearch()">
                  </ng-select>
                </div>
              </div>

              <div class="col-sm-3">
                <div class="form-group">
                  <label>{{ 'EVENTS.EVENTS_VIEW.TRANSPORT_TYPE' | translate }}</label>
                  <select class="form-control" formControlName="transport_type" (change)="hotelsSearch()">
                    <option [value]="TravelMode.DRIVING">
                      {{ 'EVENTS.EVENTS_VIEW.BY_CAR' | translate }}
                    </option>
                    <option [value]="TravelMode.TRANSIT">
                      {{ 'EVENTS.EVENTS_VIEW.BY_PUBLIC_TRANSPORT' | translate }}
                    </option>
                    <option [value]="TravelMode.WALKING">
                      {{ 'EVENTS.EVENTS_VIEW.BY_FOOT' | translate }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </form>

          <div class="d-flex mb-2">
            <div class="mr-3">
              <strong> <i class="far fa-clock"></i> {{ 'EVENTS.EVENTS_VIEW.TRAVEL_TIME' | translate }}:</strong>
              {{ gMapsResults?.duration?.text && form.valid ? gMapsResults?.duration?.text : '-' }}
            </div>
            <div>
              <strong> <i class="far fa-compass"></i> {{ 'EVENTS.EVENTS_VIEW.TRAVEL_DISTANCE' | translate }}:</strong>
              {{ gMapsResults?.distance?.text && form.valid ? gMapsResults?.distance?.text : '-' }}
            </div>
          </div>

          <agm-map
            class="agm-map"
            [latitude]="number(event?.venue?.lat)"
            [longitude]="number(event?.venue?.long)"
            [scrollwheel]="false"
            [zoom]="15"
            [fitBounds]="true"
            [fullscreenControl]="true"
            *ngIf="event && event?.venue && event?.venue?.lat && event?.venue?.long"
          >
            <agm-marker
              [markerDraggable]="false"
              [latitude]="number(event?.venue?.lat)"
              [longitude]="number(event?.venue?.long)"
              [agmFitBounds]="true"
              [iconUrl]="'assets/map_marker2.png'"
            >
              <agm-info-window>
                <div class="agm-infowindow">{{ event?.venue?.name }}</div>
              </agm-info-window>
            </agm-marker>

            <ng-container *ngFor="let hotel of hotels; let i = index">
              <agm-marker
                *ngIf="hotel.id === form.get('hotel')?.value?.id || !form.get('hotel')?.value?.id"
                [markerDraggable]="false"
                [latitude]="number(hotel.lat)"
                [longitude]="number(hotel.long)"
                [iconUrl]="'assets/map_marker.png'"
                [agmFitBounds]="true"
                (markerClick)="selectHotel(hotel.id)"
              >
                <!--                <agm-info-window #infowindow [isOpen]="true">-->
                <!--                  <div class="agm-infowindow">{{ hotel.name }}</div>-->
                <!--                </agm-info-window>-->
              </agm-marker>
            </ng-container>

            <agm-direction
              [visible]="direction"
              [renderOptions]="renderOptions"
              [markerOptions]="markerOptions"
              [origin]="direction.origin"
              [destination]="direction.destination"
              [travelMode]="form.get('transport_type').value"
            >
            </agm-direction>
          </agm-map>

          <div class="row mt-1">
            <div class="col-sm-4">
              <strong> {{ 'EVENTS.EVENTS_VIEW.HOTEL_BOOKINGS_COUNT_ADV' | translate }}: </strong>
              {{ event?.adv_hotel_bookings_count }}
            </div>
            <div class="col-sm-4">
              <strong> {{ 'EVENTS.EVENTS_VIEW.ROOMS_COUNT_ADV' | translate }}: </strong>
              {{ event?.adv_hotel_bookings_rooms_count }}
            </div>
            <div class="col-sm-4">
              <strong> {{ 'EVENTS.EVENTS_VIEW.CANCELED_ROOMS_COUNT_ADV' | translate }}: </strong>
              {{ event?.adv_canceled_rooms_count }}
            </div>
            <div class="col-sm-4">
              <strong> {{ 'EVENTS.EVENTS_VIEW.SOLD_ROOMS_COUNT' | translate }}: </strong>
              {{ event?.sold_rooms_count }}
            </div>
            <div class="col-sm-4">
              <strong> {{ 'EVENTS.EVENTS_VIEW.BLOCKED_ROOMS_COUNT' | translate }}: </strong>
              {{ event?.blocked_rooms_count }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="title2">
    {{ 'EVENTS.EVENTS_VIEW.HOTEL_BOOKINGS' | translate }}
  </div>

  <kendo-grid
    [data]="hotelBookingsData"
    [pageSize]="hotelBookingsRequestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="hotelBookingsState?.filter"
    [sort]="hotelBookingsState?.sort"
    [skip]="hotelBookingsState?.skip"
    [pageable]="true"
    (dataStateChange)="hotelBookingsLoadDataTable($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Hotel_bookings', grid)"
  >
    <kendo-grid-column
      [width]="55"
      class="text-center"
      headerClass="text-center"
      title="{{ 'EVENTS.EVENTS_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.name"
      title="{{ 'EVENTS.EVENTS_VIEW.HOTEL' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('hotel.name')"
      width="220"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a
          [routerLink]="['/hotels/view']"
          [queryParams]="{ id: dataItem?.hotel?.id }"
          class="hotel-link"
        >
          {{ dataItem?.hotel?.name }}
        </a>
        <span *ngIf="dataItem?.hotel?.stars">
          ({{ dataItem.hotel.stars }} <span class="star-ico-wrapper"> <i class="fas fa-star"></i> </span>)
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="bookingTags"
      title="{{ 'EVENTS.EVENTS_VIEW.TAGS' | translate }}"
      filter="text"
      [sortable]="false"
      [hidden]="isHiddenColumn('bookingTags', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-kendo-tag-select
          [filter]="filter"
          [model]="LaravelModel.HOTELBOOKING"
          field="bookingTags"
        ></app-kendo-tag-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let tag of dataItem?.booking?.tags; let i = index" [ngbTooltip]="tag" container="body">
          {{ tag }}<span *ngIf="i !== dataItem?.booking?.tags?.length - 1">, </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="event?.type_id !== eventTypes.BUSINESS_TRIP"
      field="distance_to_fair"
      title="{{ 'EVENTS.EVENTS_VIEW.DISTANCE_TO_FAIR' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('distance_to_fair')"
      [sortable]="false"
      [filterable]="false"
      [columnMenu]="false"
      width="60"
    ></kendo-grid-column>

    <kendo-grid-column
      field="hotel.distance_to_center"
      title="{{ 'EVENTS.EVENTS_VIEW.DISTANCE_TO_CENTER' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('hotel.distance_to_center')"
      width="80"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.supplier.name"
      title="{{ 'EVENTS.EVENTS_VIEW.SUPPLIER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.supplier.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-supplier-select [filter]="filter" [field]="'booking.supplier_id'"></app-kendo-supplier-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.company.abbreviation"
      title="{{ 'EVENTS.EVENTS_VIEW.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.company.abbreviation', true)"
      [width]="85"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter" [field]="'booking.company_id'"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/settings/companies/view']"
          [queryParams]="{ id: dataItem?.booking.company_id }"
          target="_blank"
        >
          {{ dataItem?.booking?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <!--    <kendo-grid-column field="channel.value" title="{{ 'EVENTS.EVENTS_VIEW.CHANNEL' | translate }}" filter="numeric">-->
    <!--      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
    <!--        <app-type-badge [type]="dataItem.channel"></app-type-badge>-->
    <!--      </ng-template>-->
    <!--    </kendo-grid-column>-->

    <kendo-grid-column
      field="booking.property.value"
      title="{{ 'EVENTS.EVENTS_VIEW.PROPERTY' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('booking.property.value')"
      width="100"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_properties'"
          [field]="'booking.property_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.booking?.property"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.city.country.name"
      title="{{ 'EVENTS.EVENTS_VIEW.COUNTRY' | translate }}"
      [hidden]="isHiddenColumn('hotel.city.country.name', true)"
      width="100"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-country-select [field]="'hotel.city.country_id'" [filter]="filter"></app-kendo-country-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.city.name"
      title="{{ 'EVENTS.EVENTS_VIEW.CITY' | translate }}"
      [hidden]="isHiddenColumn('hotel.city.name', true)"
      width="100"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-city-select [field]="'hotel.city_id'" [filter]="filter"></app-kendo-city-select>
      </ng-template>
    </kendo-grid-column>

    <!--    <kendo-grid-column-->
    <!--      field="commission_percent"-->
    <!--      title="{{ 'EVENTS.EVENTS_VIEW.COMMISSION_PERCENT' | translate }}"-->
    <!--      filter="numeric"-->
    <!--    ></kendo-grid-column>-->

    <kendo-grid-column
      field="booking.ref_number"
      title="{{ 'EVENTS.EVENTS_VIEW.REF_NUMBER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.ref_number', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="type.value"
      title="{{ 'EVENTS.EVENTS_VIEW.ROOM_TYPE' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('type.value')"
      width="100"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select [groupType]="'rooms'" [filter]="filter"></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.type"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="available_rooms"
      filter="numeric"
      title="{{ 'EVENTS.EVENTS_VIEW.ROOMS_COUNT_SHORT' | translate }}"
      [hidden]="isHiddenColumn('available_rooms')"
      width="65"
      [columnMenu]="false"
      format="0"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" operator="gte" [spinners]="false">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.cancellation_terms_id"
      title="{{ 'EVENTS.EVENTS_VIEW.CANCELLATION_TERMS' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('booking.cancellation_terms_id')"
      width="130"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_cancellation_terms'"
          [filter]="filter"
          [field]="'booking.cancellation_terms_id'"
        >
        </app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.booking?.cancellation_terms"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="meal_plan.value"
      title="{{ 'EVENTS.EVENTS_VIEW.MEAL_PLAN' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('meal_plan.value')"
      width="65"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'room_meal_plans'"
          [field]="'meal_plan_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.meal_plan"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_in"
      title="{{ 'EVENTS.EVENTS_VIEW.CHECK_IN' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('check_in')"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_in | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_out"
      title="{{ 'EVENTS.EVENTS_VIEW.CHECK_OUT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('check_out')"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_out | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="net_price_eur_avg"
      filter="numeric"
      title="{{ 'EVENTS.EVENTS_VIEW.NET_PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('net_price_eur_avg')"
      width="95"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.net_price_eur_avg | number : '1.2-2' }}
        {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="price_avg"
      filter="numeric"
      title="{{ 'EVENTS.EVENTS_VIEW.PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('price_avg', true)"
      width="95"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false">
        </kendo-grid-numeric-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.price_avg | number : '1.2-2' }}
        {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="web_price_avg"
      filter="numeric"
      title="{{ 'EVENTS.EVENTS_VIEW.WEB_PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('web_price_avg', true)"
      width="95"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-numeric-filter-cell [column]="column" [filter]="filter" [spinners]="false">
        </kendo-grid-numeric-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.web_price_avg | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.payment_deadline"
      title="{{ 'EVENTS.EVENTS_VIEW.PAYMENT_DEADLINE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('booking.payment_deadline', true)"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem?.booking?.payment_deadline | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.cancellation_deadline"
      title="{{ 'EVENTS.EVENTS_VIEW.CANCELLATION_DEADLINE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('booking.cancellation_deadline', true)"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem?.booking?.cancellation_deadline | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.show_to_web"
      title="{{ 'EVENTS.EVENTS_VIEW.SHOW_TO_WEB' | translate }}"
      filter="boolean"
      [width]="80"
      [class]="'text-center'"
      [hidden]="isHiddenColumn('booking.show_to_web', true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.booking.show_to_web"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      *ngIf="
        accessService.hasAccess(
          [
            UserRoleType.RESERVATION_AGENT,
            UserRoleType.RESERVATION_MANAGER,
            UserRoleType.CONTRACTING_AGENT,
            UserRoleType.CONTRACTING_MANAGER,
            UserRoleType.SALES_AGENT,
            UserRoleType.SALES_MANAGER,
            UserRoleType.CUSTOMER_CARE_AGENT,
            UserRoleType.CUSTOMER_CARE_MANAGER,
            UserRoleType.FINANCE_AGENT,
            UserRoleType.FINANCE_MANAGER,
            UserRoleType.LEAD_GENERATION,
            UserRoleType.IT,
            UserRoleType.MARKETING
          ],
          []
        )
      "
      class="text-center p-1"
      headerClass="text-center"
      field="booking.note"
      title="{{ 'EVENTS.EVENTS_VIEW.BOOKING_NOTE' | translate }}"
      [width]="30"
      [sortable]="false"
      [columnMenu]="false"
      [filterable]="false"
    >
      <ng-template kendoGridHeaderTemplate>
        <i class="fas fa-sticky-note"></i>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngIf="dataItem?.booking?.note" [ngbTooltip]="dataItem?.booking?.note" container="body">
          <i class="fal fa-sticky-note"></i>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'EVENTS.EVENTS_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-preview-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.IT,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER,
                UserRoleType.EXTERNAL_PARTNER_LEAD
              ],
              ['hotel_bookings.show']
            )
          "
          [link]="['/hotel-bookings/view']"
          [params]="{ id: dataItem.booking?.id }"
        ></app-preview-button>

        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.IT,
                UserRoleType.MARKETING,
                UserRoleType.EXTERNAL_PARTNER_LEAD,
                UserRoleType.EXTERNAL_PARTNER
              ],
              []
            )
          "
          type="button"
          class="btn btn-info btn-ico"
          (click)="selectHotel(dataItem?.booking?.hotel_id)"
        >
          <i class="fal fa-map-pin"></i>
        </button>

        <app-edit-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER
              ],
              ['hotel_bookings.show']
            )
          "
          (click)="hotelBookingsEdit(dataItem?.booking)"
        >
        </app-edit-button>

        <a
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER
              ],
              ['hotel_booking_cancellations.show']
            )
          "
          class="btn btn-warning btn-ico"
          [routerLink]="['/hotel-booking-cancellations/view']"
          [queryParams]="{ booking_id: dataItem?.booking?.id }"
          [ngbTooltip]="'EVENTS.EVENTS_VIEW.CREATE_CANCELLATION' | translate"
          container="body"
        >
          <i class="fal fa-ban"></i>
        </a>

        <app-delete-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER
              ],
              ['hotel_bookings.destroy']
            )
          "
          [swal]="{}"
          (confirm)="hotelBookingsDelete(dataItem?.booking)"
        >
        </app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="hotelBookingsLoadDataTable(hotelBookingsState)">
      </app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
