<div
  class="bank-account-view-wrapper relative view-with-static-right-sidebar ps"
  [ngClass]="{ 'sidebar-opened': activitiesService.isOpened }"
  [perfectScrollbar]
  #perfectScroll="ngxPerfectScrollbar"
>
  <app-side-button
    [icon]="'far fa-history'"
    [customClasses]="'btn-activity-floating'"
    (click)="openActivities()"
    *ngIf="!activitiesService.isOpened && bankAccountId"
  ></app-side-button>

  <div class="top">
    <div class="preview-header d-flex justify-content-between">
      <div class="preview-title-wrapper">
        <div class="d-flex align-items-center">
          <div class="title">
            <ng-container>
              {{ bankAccount?.displayName }}
            </ng-container>
          </div>
        </div>
      </div>

      <div class="preview-btns-wrapper d-flex align-items-stretch">
        <p-fileUpload
          [styleClass]="'btn btn-upload mr-2'"
          [chooseLabel]="'BANKS.BANK_ACCOUNTS_VIEW.IMPORT' | translate"
          #fileUploader
          name="file"
          [multiple]="false"
          [auto]="true"
          [showUploadButton]="false"
          [showCancelButton]="false"
          customUpload="true"
          (uploadHandler)="import($event, fileUploader)"
          mode="basic"
          [accept]="MediaLibraryAcceptTypes.EXCEL"
        ></p-fileUpload>

        <button type="button" class="btn btn-sm btn-success mr-2" (click)="openBankAccountStatementsMatchForm()">
          {{ 'BANKS.BANK_ACCOUNTS_VIEW.BANK_ACCOUNTS_STATEMENT_MATCH' | translate }}
        </button>

        <button type="button" class="btn btn-sm btn-success" (click)="edit()">
          {{ 'BANKS.BANK_ACCOUNTS_VIEW.EDIT_BANK_ACCOUNTS_BTN' | translate }}
        </button>
      </div>
    </div>

    <div class="p-3">
      <div class="row">
        <div class="col-sm-4">
          <div *ngIf="bankAccount?.name">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.NAME' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.name }}
            </div>
          </div>

          <div *ngIf="bankAccount?.abbreviation">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.ABBREVIATION' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.abbreviation }}
            </div>
          </div>

          <div *ngIf="bankAccount?.iban">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.IBAN' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.iban }}
            </div>
          </div>

          <div *ngIf="bankAccount?.bic">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.BIC' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.bic }}
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div *ngIf="bankAccount?.beneficiary_name">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.BENEFICIARY_NAME' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.beneficiary_name }}
            </div>
          </div>

          <div *ngIf="bankAccount?.address">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.ADDRESS' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.address }}
            </div>
          </div>

          <div *ngIf="bankAccount?.currency">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.CURRENCY' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.currency?.code }}
            </div>
          </div>

          <div *ngIf="bankAccount?.sort_code">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.SORT_CODE' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.sort_code }}
            </div>
          </div>

          <div *ngIf="bankAccount?.balance">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.START_BALANCE' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.balance }}
            </div>
          </div>

          <div *ngIf="bankAccount?.payment_calculation_date">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.PAYMENT_CALCULATION_DATE' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.payment_calculation_date | date : env.pipeDateFormat }}
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div *ngIf="bankAccount?.account_number">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.ACCOUNT_NUMBER' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.account_number }}
            </div>
          </div>

          <div class="current-balance-wrapper">
            <div class="sub-title">
              {{ 'BANKS.BANK_ACCOUNTS_VIEW.CURRENT_BALANCE' | translate }}
            </div>
            <div class="value">
              {{ bankAccount?.current_balance }}
            </div>
          </div>

          <div [formGroup]="form" class="d-flex align-items-end">
            <div class="form-group mb-0 form-group-last-balance">
              <label>{{ 'BANKS.BANK_ACCOUNTS_VIEW.LAST_BALANCE' | translate }}</label>
              <input type="number" class="form-control" formControlName="last_balance" />
            </div>
            <div>
              <button class="btn btn-success btn-input" (click)="updateLastBalance()" [disabled]="form.invalid">
                {{ 'BANKS.BANK_ACCOUNTS_VIEW.UPDATE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row align-items-center">
    <div class="col-auto">
      <div class="section-title">
        {{ 'BANKS.BANK_ACCOUNTS_VIEW.BANK_STATEMENTS' | translate }}
      </div>
    </div>
    <div class="col-auto ml-auto">
      <button type="button" class="btn btn-link" (click)="clearFilters()">
        {{ 'CLEAR_FILTERS' | translate }}
      </button>
    </div>
  </div>

  <kendo-grid
    [data]="bankStatementData"
    [pageSize]="bankStatementResponse?.meta.per_page"
    [sortable]="false"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="bankStatementState?.filter"
    [sort]="bankStatementState?.sort"
    [skip]="bankStatementState?.skip"
    [pageable]="true"
    (dataStateChange)="loadBankStatements($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'bank_statement', grid)"
  >
    <kendo-grid-column
      [width]="55"
      class="text-center"
      headerClass="text-center"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      filter="numeric"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.ID' | translate }}"
      [width]="100"
      [hidden]="isHiddenColumn('id', true)"
      [columnMenu]="false"
    ></kendo-grid-column>

    <kendo-grid-column
      field="amount"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.AMOUNT' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('amount')"
      [width]="150"
    ></kendo-grid-column>

    <kendo-grid-column
      field="payee"
      [width]="200"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.PAYEE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('payee')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="date"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('date')"
      [width]="150"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.date | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="description"
      [width]="200"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.DESCRIPTION' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('description')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="reference"
      [width]="200"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.REFERENCE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('reference')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="number"
      [width]="200"
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.CHECK_NUMBER' | translate }}"
      [filterable]="false"
      [sortable]="false"
      [hidden]="isHiddenColumn('number')"
    ></kendo-grid-column>

    <kendo-grid-column
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.LINKED_PAYMENTS' | translate }}"
      field="has_payment_links"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-kendo-universal-boolean-select [filter]="filter" [field]="'has_payment_links'">
        </app-kendo-universal-boolean-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngFor="let payment of dataItem.payments; let i = index">
          <div class="btn btn-link btn-xs" (click)="editPayment(payment)">
            #{{ payment?.id }} / {{ payment?.amount }}{{ payment.currency?.code }} /
            {{ payment?.date | date : env.pipeDateFormat }} / {{ payment?.reference || '-' }} /
            {{ payment?.clientable?.name }}
          </div>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'BANKS.BANK_ACCOUNTS_VIEW.ACTIONS' | translate }}"
      [class]="'text-center'"
      [includeInChooser]="false"
      [columnMenu]="false"
      [width]="60"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button
          *ngIf="dataItem?.payments?.length > 0"
          type="button"
          class="btn btn-danger btn-ico"
          [ngbTooltip]="'BANKS.BANK_ACCOUNTS_VIEW.UNLINK' | translate"
          container="body"
          [swal]="{
            titleText: 'BANKS.BANK_ACCOUNTS_VIEW.SWAL.TITLE' | translate,
            text: 'BANKS.BANK_ACCOUNTS_VIEW.SWAL.TEXT' | translate
          }"
          (confirm)="unlinkAll(dataItem)"
        >
          <i class="fa fa-chain-broken"></i>
        </button>

        <app-delete-button [swal]="{}" (confirm)="delete(dataItem)"> </app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadBankStatements(bankStatementState)"> </app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
