<div
  class="hotel-bookings-view-wrapper relative view-with-static-right-sidebar ps"
  [ngClass]="{ 'sidebar-opened': activitiesService.isOpened }"
  [perfectScrollbar]
  #perfectScroll="ngxPerfectScrollbar"
>
  <app-side-button
    [icon]="'far fa-history'"
    [customClasses]="'btn-activity-floating'"
    (click)="openActivities()"
    *ngIf="!activitiesService.isOpened && hotelBookingId"
  ></app-side-button>

  <div class="top">
    <div class="preview-header d-flex align-items-center justify-content-between">
      <div class="preview-title-wrapper">
        <span class="title">
          {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PAGE_TITLE' | translate }} #{{ hotelBookingId }}
        </span>
        <span class="additional-info">
          <span class="sub-title">
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SHOW_TO_WEB' | translate }}
          </span>
          <span class="value">
            <app-active-indicator [active]="hotelBooking?.show_to_web"></app-active-indicator>
          </span>

          <span class="sub-title">
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BREAKFAST' | translate }}
          </span>
          <span class="value">
            <app-active-indicator [active]="hotelBooking?.breakfast"></app-active-indicator>
          </span>

          <app-type-badge [type]="hotelBooking?.cancellation_terms"></app-type-badge>
          <app-type-badge [type]="hotelBooking?.property"></app-type-badge>
        </span>
      </div>

      <div class="preview-btns-wrapper d-flex align-items-center">
        <app-quick-set-assign-and-date
          *ngIf="hotelBooking"
          class="mr-2"
          [modelId]="hotelBookingId"
          [modelType]="LaravelModelsEnum.HOTELBOOKING"
          [preSelectedDate]="hotelBooking.assign_date"
          [preSelectedUserId]="hotelBooking.assign_to_user_id"
          [preSelectedRoleId]="hotelBooking.assign_to_role_id"
          [roleType]="[
            UserRoleType.RESERVATION_AGENT,
            UserRoleType.RESERVATION_MANAGER,
            UserRoleType.CONTRACTING_MANAGER,
            UserRoleType.CONTRACTING_AGENT
          ]"
          [selectableGroup]="false"
        >
        </app-quick-set-assign-and-date>

        <div>
          <app-media-library-button
            class="mr-2"
            *ngIf="hotelBooking?.id"
            [ngbTooltip]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_LIST.UPLOAD_DOCUMENTS' | translate"
            container="body"
            [icon]="'fal fa-file'"
            [acceptType]="MediaLibraryAcceptTypes.ALL"
            [collectionName]="'documents'"
            [modelId]="hotelBooking?.id"
            [modelType]="LaravelModelsEnum.BOOKING"
          >
          </app-media-library-button>
        </div>

        <div>
          <button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER,
                  UserRoleType.SALES_AGENT,
                  UserRoleType.SALES_MANAGER,
                  UserRoleType.CUSTOMER_CARE_AGENT,
                  UserRoleType.CUSTOMER_CARE_MANAGER,
                  UserRoleType.FINANCE_AGENT,
                  UserRoleType.FINANCE_MANAGER
                ],
                ['sales.mass_create']
              )
            "
            type="button"
            class="btn btn-sm btn-success mr-2"
            (click)="offerMultipleClientsOpen()"
          >
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.MULTIPLE_CLIENTS_OFFER' | translate }}
          </button>
        </div>

        <div>
          <app-reminder-button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER,
                  UserRoleType.SALES_AGENT,
                  UserRoleType.SALES_MANAGER,
                  UserRoleType.CUSTOMER_CARE_AGENT,
                  UserRoleType.CUSTOMER_CARE_MANAGER,
                  UserRoleType.FINANCE_AGENT,
                  UserRoleType.FINANCE_MANAGER,
                  UserRoleType.LEAD_GENERATION,
                  UserRoleType.IT,
                  UserRoleType.MARKETING
                ],
                []
              )
            "
            [reminderableId]="hotelBooking?.id"
            [reminderableType]="LaravelModelsEnum.HOTELBOOKING"
          ></app-reminder-button>
        </div>

        <div>
          <app-download-rooming-list-button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER
                ],
                ['hotel_bookings.export_sold_rooms']
              )
            "
            [ngClass]="'ml-2'"
            [bookingId]="hotelBooking?.id"
          ></app-download-rooming-list-button>
        </div>

        <div>
          <button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER
                ],
                ['hotel_bookings.show']
              )
            "
            type="button"
            class="btn btn-sm btn-success ml-2"
            (click)="edit(hotelBookingRoomsGrid)"
          >
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.EDIT_HOTEL_BOOKING_BTN' | translate }}
          </button>
        </div>

        <div>
          <button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER
                ],
                ['hotel_bookings.store']
              )
            "
            type="button"
            class="btn btn-sm btn-info ml-2"
            (click)="add()"
          >
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NEW_HOTEL_BOOKING_BTN' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3">
        <div *ngIf="hotelBooking?.hotel" class="mr-3">
          <div class="sub-title">
            <i class="far fa-hotel"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.HOTEL' | translate }}
          </div>
          <div class="value">
            <a [routerLink]="'/hotels/view'" [queryParams]="{ id: hotelBooking?.hotel?.id }" target="_blank">
              {{ hotelBooking?.hotel?.name }}
            </a>
            <span *ngIf="hotelBooking?.hotel?.stars">
              ({{ hotelBooking?.hotel?.stars }} <span class="star-ico-wrapper"> <i class="fas fa-star"></i></span>)
            </span>
          </div>
        </div>

        <div *ngIf="hotelBooking?.contracting_agent" class="mr-3">
          <div class="sub-title">
            <i class="far fa-user-tie"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CONTRACTING_AGENT' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.contracting_agent.names }}
          </div>
        </div>

        <div *ngIf="hotelBooking?.reservation_agent" class="mr-3">
          <div class="sub-title">
            <i class="far fa-user-tie"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.RESERVATION_AGENT' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.reservation_agent.names }}
          </div>
        </div>

        <div *ngIf="hotelBooking?.supplier" class="mr-3">
          <div class="sub-title">
            <i class="far fa-parachute-box"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SUPPLIER' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.supplier?.name }}
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div *ngIf="hotelBooking?.company" class="mr-3">
          <div class="sub-title">
            <i class="far fa-home-lg-alt"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COMPANY' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.company.shortName }}
          </div>
        </div>

        <div *ngIf="hotelBooking?.commission_percent" class="mr-3">
          <div class="sub-title">
            <i class="fal fa-badge-percent"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COMMISSION_PERCENT_SHORT' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.commission_percent }}
          </div>
        </div>

        <div *ngIf="hotelBooking?.deposit_percent" class="mr-3">
          <div class="sub-title">
            <i class="fal fa-badge-percent"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.DEPOSIT_PERCENT_SHORT' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.deposit_percent }}
          </div>
        </div>
      </div>

      <div class="col-sm-3">
        <div *ngIf="hotelBooking?.cancellation_charge_percent" class="mr-3">
          <div class="sub-title">
            <i class="fal fa-badge-percent"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CANCELLATION_CHARGE_PERCENT_SHORT' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.cancellation_charge_percent }}
          </div>
        </div>

        <div *ngIf="hotelBooking?.ref_number" class="mr-3">
          <div class="sub-title">
            <i class="fas fa-sort-numeric-down"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.REF_NUMBER' | translate }}
          </div>
          <div class="value">
            {{ hotelBooking?.ref_number }}
          </div>
        </div>

        <div *ngIf="hotelBooking?.currency" class="mr-3">
          <div class="sub-title">
            <i class="far fa-sack-dollar"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CURRENCY' | translate }}
          </div>
          <div class="value">
            <app-currency-box [currency]="hotelBooking?.currency"></app-currency-box>
          </div>
        </div>
      </div>

      <div
        *ngIf="
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_AGENT,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.IT,
              UserRoleType.MARKETING
            ],
            []
          )
        "
        class="col-sm-3"
      >
        <div *ngIf="hotelBooking?.note" class="note-box">
          <div class="sub-title">
            <i class="far fa-sticky-note"></i>
            {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NOTE' | translate }}
          </div>
          <div class="value m-0">
            {{ hotelBooking?.note }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="title2">
    {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.ROOMS' | translate }}
  </div>

  <kendo-grid
    #hotelBookingRoomsGrid
    [data]="hotelBookingsRoomsData"
    [pageSize]="hotelBookingsRoomsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="hotelBookingsRoomsState?.filter"
    [sort]="hotelBookingsRoomsState?.sort"
    [skip]="hotelBookingsRoomsState?.skip"
    [pageable]="true"
    (dataStateChange)="loadHotelBookingsRoomsData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    (detailExpand)="hotelBookingsRoomNnExpand($event)"
    [loading]="loading"
    #hotelBookingsRoomsGrid
    (excelExport)="onExcelExport($event, 'Rooms', hotelBookingsRoomsGrid, 2)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.ID' | translate }}"
      filter="numeric"
      [width]="150"
      [hidden]="isHiddenColumn('id', true)"
    ></kendo-grid-column>

    <kendo-grid-column
      field="booking.events.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_LIST.EVENTS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.events.name')"
      [filterable]="false"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter" [field]="'event_id'"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div *ngFor="let event of dataItem.booking?.events; let i = index">
          <a [routerLink]="['/events/view']" [queryParams]="{ id: event?.id }">
            {{ event.formattedName }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.company.abbreviation"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.company.shortName', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter" [field]="'booking.company_id'"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a
          [routerLink]="['/settings/companies/view']"
          [queryParams]="{ id: dataItem?.booking.company_id }"
          target="_blank"
        >
          {{ dataItem?.booking?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.property.value"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PROPERTY' | translate }}"
      [hidden]="isHiddenColumn('booking.property.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_properties'"
          [field]="'booking.property_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.booking.property"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.cancellation_terms.value"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CANCELLATION_TERMS' | translate }}"
      [hidden]="isHiddenColumn('booking.cancellation_terms.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'booking_cancellation_terms'"
          [field]="'booking.cancellation_terms_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.booking.cancellation_terms"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.supplier.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SUPPLIER' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('booking.supplier.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-supplier-select [filter]="filter" [field]="'booking.supplier_id'"></app-kendo-supplier-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.city.country.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COUNTRY' | translate }}"
      [hidden]="isHiddenColumn('hotel.city.country.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-country-select [field]="'hotel.city.country_id'" [filter]="filter"></app-kendo-country-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-country-box [country]="dataItem?.hotel?.city?.country"></app-country-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel.city.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CITY' | translate }}"
      [hidden]="isHiddenColumn('hotel.city.name', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-city-select [filter]="filter" [field]="'hotel.city_id'"></app-kendo-city-select>
      </ng-template>
    </kendo-grid-column>

    <!--    <kendo-grid-column-->
    <!--      field="km_to_fair"-->
    <!--      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.KM_TO_FAIR' | translate }}"-->
    <!--      [hidden]="isHiddenColumn('km_to_fair')"-->
    <!--    ></kendo-grid-column>-->

    <kendo-grid-column
      field="hotel.distance_to_center"
      filter="numeric"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.DISTANCE_TO_CENTER_SHORT' | translate }}"
      [hidden]="isHiddenColumn('hotel.distance_to_center')"
    ></kendo-grid-column>

    <kendo-grid-column
      field="available_rooms"
      [filterable]="false"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.FREE_ROOMS_ALLOTMENT' | translate }}"
      [hidden]="isHiddenColumn('available_rooms')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.available_rooms }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="type.value"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TYPE' | translate }}"
      [hidden]="isHiddenColumn('type.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select [groupType]="'rooms'" [filter]="filter"></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.type"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="meal_plan.value"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.MEAL_PLAN_SHORT' | translate }}"
      [hidden]="isHiddenColumn('meal_plan.value')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'room_meal_plans'"
          [field]="'meal_plan_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.meal_plan"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_in"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_IN' | translate }}"
      [hidden]="isHiddenColumn('check_in')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_in | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_out"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_OUT' | translate }}"
      [hidden]="isHiddenColumn('check_out')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_out | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="booking.cancellation_deadline"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CANCELLATION_DEADLINE_SHORT' | translate }}"
      [hidden]="isHiddenColumn('booking.cancellation_deadline', true)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem?.booking?.cancellation_deadline | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="net_price_eur_avg"
      filter="numeric"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NET_PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('net_price_eur_avg')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.net_price_eur_avg | number : '1.2-2' }}
        {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="price_avg"
      filter="numeric"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PRICE_AVG' | translate }}"
      [hidden]="isHiddenColumn('price_avg')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.price_avg | number : '1.2-2' }}
        {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="margin_avg"
      filter="numeric"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.MARGIN_AVG' | translate }}"
      [hidden]="isHiddenColumn('margin_avg')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.margin_avg | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER
              ],
              ['hotel_booking_cancellations.store']
            )
          "
          class="btn btn-warning btn-ico"
          [routerLink]="['/hotel-booking-cancellations/view']"
          [queryParams]="{ booking_id: dataItem.booking.id }"
          [ngbTooltip]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CREATE_CANCELLATION' | translate"
          container="body"
        >
          <i class="fal fa-ban"></i>
        </a>

        <app-delete-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER
              ],
              ['hotel_bookings.destroy']
            )
          "
          [swal]="{}"
          (confirm)="deleteHotelBookingRoom(dataItem.booking)"
        >
        </app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <div *kendoGridDetailTemplate="let dataItem">
      <div class="row">
        <div class="col-sm-3">
          <div class="table-box">
            <div class="table-box-title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.GENERAL' | translate }}</div>

            <div class="table-box-body">
              <div class="single-line d-flex" *ngIf="dataItem?.booking?.company">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COMPANY' | translate }}</div>
                <div class="value">
                  {{ dataItem?.booking?.company.shortName }}
                </div>
              </div>

              <div class="single-line d-flex" *ngIf="dataItem?.booking?.property">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PROPERTY' | translate }}</div>
                <div class="value">
                  <app-type-badge [type]="dataItem?.booking?.property"></app-type-badge>
                </div>
              </div>

              <ng-container *ngFor="let event of dataItem.booking?.events; let i = index">
                <div class="single-line d-flex">
                  <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.EDITION_NAME' | translate }}</div>
                  <div class="value">
                    <a [routerLink]="['/events/view']" [queryParams]="{ id: event?.id }" target="_blank">
                      {{ event.formattedName }}
                    </a>
                  </div>
                </div>
              </ng-container>

              <div class="single-line d-flex" *ngIf="dataItem?.hotel?.city?.country">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COUNTRY' | translate }}</div>
                <div class="value">
                  <app-country-box [country]="dataItem?.hotel?.city?.country"></app-country-box>
                </div>
              </div>

              <div class="single-line d-flex" *ngIf="dataItem?.hotel?.city">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CITY' | translate }}</div>
                <div class="value">
                  {{ dataItem?.hotel?.city?.name }}
                </div>
              </div>

              <div class="single-line d-flex" *ngIf="hotelBooking?.contracting_agent">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CONTRACTING_AGENT' | translate }}</div>
                <div class="value">
                  {{ hotelBooking?.contracting_agent.names }}
                </div>
              </div>

              <div class="single-line d-flex" *ngIf="hotelBooking?.reservation_agent">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.RESERVATION_AGENT' | translate }}</div>
                <div class="value">
                  {{ hotelBooking?.reservation_agent?.names }}
                </div>
              </div>

              <div class="single-line d-flex" *ngIf="dataItem?.type">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TYPE' | translate }}</div>
                <div class="value">
                  <app-type-badge [type]="dataItem.type"></app-type-badge>
                </div>
              </div>

              <div class="single-line d-flex" *ngIf="dataItem?.meal_plan">
                <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.MEAL_PLAN' | translate }}</div>
                <div class="value">
                  <app-type-badge [type]="dataItem.meal_plan"></app-type-badge>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="table-box">
            <div class="table-box-title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.DATES' | translate }}</div>

            <div class="table-box-body">
              <div class="row">
                <div class="col-sm-6">
                  <div class="single-line d-flex" *ngIf="dataItem?.check_in">
                    <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_IN' | translate }}</div>
                    <div class="value">
                      {{ dataItem.check_in | date : env.pipeDateFormat }}
                    </div>
                  </div>

                  <div class="single-line d-flex">
                    <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NIGHTS' | translate }}</div>
                    <div class="value">
                      {{
                        dataItem?.check_out
                          | amParse : env.apiPipeDateFormat
                          | amDifference : dataItem?.check_in : 'days'
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="single-line d-flex" *ngIf="dataItem?.check_out">
                    <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_OUT' | translate }}</div>
                    <div class="value">
                      {{ dataItem.check_out | date : env.pipeDateFormat }}
                    </div>
                  </div>

                  <div class="single-line d-flex" *ngIf="dataItem?.booking?.cancellation_deadline">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CANCELLATION_DEADLINE_SHORT' | translate }}
                    </div>
                    <div class="value text-danger">
                      {{ dataItem?.booking?.cancellation_deadline | date : env.pipeDateFormat }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="table mt-3">
                <thead>
                  <tr>
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.DATE' | translate }}</th>
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NET_PRICE' | translate }}</th>
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.AVAILABLE_ROOMS' | translate }}</th>
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TOTAL_ROOMS' | translate }}</th>
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BLOCKED_ROOMS' | translate }}</th>
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CANCELLED_ROOMS' | translate }}</th>
                    <!--                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BLOCKED_AMOUNT' | translate }}</th>-->
                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_ROOMS' | translate }}</th>
                    <!--                    <th class="text-wrap">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_AMOUNT' | translate }}</th>-->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let night of dataItem?.nights_info">
                    <td>{{ night.date | date : env.pipeDateFormat }}</td>
                    <td>
                      {{ night.total_net_amount / night.total | number : '1.2-2' }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </td>
                    <td>
                      <span
                        class="font-weight-bold"
                        [ngClass]="{ 'text-success': night?.available > 0, 'text-danger': night?.available <= 0 }"
                      >
                        {{ night?.available }}
                      </span>
                    </td>
                    <td>{{ night?.total }}</td>
                    <td>{{ night?.blocked }}</td>
                    <td>{{ night?.cancelled }}</td>
                    <!--                    <td>{{ night?.blocked_amount | number: '1.2-2' }}</td>-->
                    <td>{{ night?.sold }}</td>
                    <!--                    <td>{{ night?.sold_amount | number: '1.2-2' }}</td>-->
                  </tr>
                </tbody>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="table-box">
            <div class="table-box-title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PRICES' | translate }}</div>

            <div class="table-box-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="subtitle">
                    {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PER_ROOM' | translate }}
                    -
                    <app-type-badge [type]="dataItem.type"></app-type-badge>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TOTAL_NET_AMOUNT_AVG' | translate }}
                    </div>
                    <div class="value">
                      {{ dataItem?.meta_front?.totals?.total_net_amount || 0 | number : '1.2-2' }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BLOCKED_NET_AMOUNT_AVG' | translate }}
                    </div>
                    <div class="value">
                      {{ dataItem?.meta_front?.totals?.blocked_net_amount || 0 | number : '1.2-2' }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BLOCKED_AMOUNT_AVG' | translate }}
                    </div>
                    <div class="value">
                      {{ dataItem?.meta_front?.totals?.blocked_amount || 0 | number : '1.2-2' }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_NET_AMOUNT_AVG' | translate }}
                    </div>
                    <div class="value">
                      {{ dataItem?.meta_front?.totals?.sold_net_amount || 0 | number : '1.2-2' }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_AMOUNT_AVG' | translate }}
                    </div>
                    <div class="value">
                      {{ dataItem?.meta_front?.totals?.sold_amount || 0 | number : '1.2-2' }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_AMOUNT_MARGIN_AVG' | translate }}
                    </div>
                    <div class="value">
                      {{
                        dataItem?.meta_front?.totals?.sold_amount - dataItem?.meta_front?.totals?.sold_net_amount || 0
                          | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <!--                  <div class="single-line d-flex justify-content-between">-->
                  <!--                    <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.COMMISSION_PERCENT' | translate }}</div>-->
                  <!--                    <div class="value">-->
                  <!--                      {{ hotelBooking?.commission_percent || '-' }}-->
                  <!--                    </div>-->
                  <!--                  </div>-->
                </div>
                <div class="col-sm-12">
                  <div class="subtitle">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TOTALS' | translate }}</div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TOTAL_NET_AMOUNT' | translate }}</div>
                    <div class="value">
                      {{
                        dataItem?.meta_front?.totals?.total_net_amount * dataItem?.meta_front?.totals?.total || 0
                          | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BLOCKED_NET_AMOUNT' | translate }}</div>
                    <div class="value">
                      {{
                        dataItem?.meta_front?.totals?.blocked_net_amount * dataItem?.meta_front?.totals?.blocked || 0
                          | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BLOCKED_AMOUNT' | translate }}
                    </div>
                    <div class="value">
                      {{
                        dataItem?.meta_front?.totals?.blocked_amount * dataItem?.meta_front?.totals?.blocked || 0
                          | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_NET_AMOUNT' | translate }}
                    </div>
                    <div class="value">
                      {{
                        dataItem?.meta_front?.totals?.sold_net_amount * dataItem?.meta_front?.totals?.sold || 0
                          | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_AMOUNT' | translate }}
                    </div>
                    <div class="value">
                      {{
                        dataItem?.meta_front?.totals?.sold_amount * dataItem?.meta_front?.totals?.sold || 0
                          | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>

                  <div class="single-line d-flex justify-content-between">
                    <div class="title">
                      {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SOLD_AMOUNT_MARGIN' | translate }}
                    </div>
                    <div class="value">
                      {{
                        (dataItem?.meta_front?.totals?.sold_amount - dataItem?.meta_front?.totals?.sold_net_amount) *
                          dataItem?.meta_front?.totals?.sold || 0 | number : '1.2-2'
                      }}
                      {{ 'DEFAULT_CURRENCY' | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button
        (click)="loadHotelBookingsRoomsData(hotelBookingsRoomsState)"
      ></app-kendo-grid-refresh-button>
      <button
        type="button"
        class="btn kendo-export-btn"
        [disabled]="hotelBookingsRoomsGrid.loading"
        kendoGridExcelCommand
      >
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>

  <div class="d-flex justify-content-end" *ngIf="totals">
    <div class="booking-totals">
      <div class="d-flex justify-content-between">
        <div>{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BOOKING_TOTAL_NET_AMOUNT' | translate }}:</div>
        <strong
          >{{ totals?.booking_totals?.booking_total_net_amount | number : '1.2-2'
          }}{{ 'DEFAULT_CURRENCY' | translate }}</strong
        >
      </div>

      <div class="d-flex justify-content-between">
        <div>{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BOOKING_TOTAL_BLOCKED_NET_AMOUNT' | translate }}:</div>
        <strong
          >{{ totals?.booking_totals?.booking_total_blocked_net_amount | number : '1.2-2'
          }}{{ 'DEFAULT_CURRENCY' | translate }}</strong
        >
      </div>

      <div class="d-flex justify-content-between">
        <div>{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BOOKING_TOTAL_BLOCKED_AMOUNT' | translate }}:</div>
        <strong
          >{{ totals?.booking_totals?.booking_total_blocked_amount | number : '1.2-2'
          }}{{ 'DEFAULT_CURRENCY' | translate }}</strong
        >
      </div>

      <div class="d-flex justify-content-between">
        <div>{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BOOKING_TOTAL_SOLD_NET_AMOUNT' | translate }}:</div>
        <strong
          >{{ totals?.booking_totals?.booking_total_sold_net_amount | number : '1.2-2'
          }}{{ 'DEFAULT_CURRENCY' | translate }}</strong
        >
      </div>

      <div class="d-flex justify-content-between">
        <div>{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BOOKING_TOTAL_SOLD_AMOUNT' | translate }}:</div>
        <strong
          >{{ totals?.booking_totals?.booking_total_sold_amount | number : '1.2-2'
          }}{{ 'DEFAULT_CURRENCY' | translate }}</strong
        >
      </div>

      <div class="d-flex justify-content-between">
        <div>{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BOOKING_TOTAL_SOLD_AMOUNT_MARGIN' | translate }}:</div>
        <strong
          >{{
            totals?.booking_totals?.booking_total_sold_amount - totals.booking_totals?.booking_total_sold_net_amount
              | number : '1.2-2'
          }}{{ 'DEFAULT_CURRENCY' | translate }}</strong
        >
      </div>
    </div>
  </div>

  <div class="title2">
    {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.SALE_FINAL_ITEMS' | translate }}
  </div>

  <kendo-grid
    [data]="saleFinalsItemsData"
    [pageSize]="saleFinalsItemsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="saleFinalsItemsState?.filter"
    [sort]="saleFinalsItemsState?.sort"
    [skip]="saleFinalsItemsState?.skip"
    [pageable]="true"
    (dataStateChange)="loadSaleFinalItemsData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #saleFinalsItemsGrid
    (excelExport)="onExcelExport($event, 'Client_Break_down', saleFinalsItemsGrid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.client.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CLIENT_NAME' | translate }}"
      filter="numeric"
      [width]="150"
      [hidden]="isHiddenColumn('saleFinal.sale.client.name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter" [field]="'saleFinal.sale.client_id'"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="'/sales/view'" [queryParams]="{ id: dataItem.sale_final?.sale_id }" target="_blank">
          {{ dataItem.sale_final?.sale?.client?.name }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="saleFinal.sale.event.name"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.EVENT_NAME' | translate }}"
      filter="numeric"
      [hidden]="isHiddenColumn('saleFinal.sale.event.name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter" [field]="'saleFinal.sale.event_id'"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem.sale_final?.sale?.event_id }" target="_blank">
          {{ dataItem.sale_final?.sale?.event?.name }}
          {{ dataItem.sale_final?.sale?.event?.date_from | amDateFormat : 'YYYY' }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_in"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_IN' | translate }}"
      [hidden]="isHiddenColumn('check_in')"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_in | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="nights_excel_export"
      [width]="60"
      class="text-center"
      headerClass="text-center"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NIGHTS' | translate }}"
      [filterable]="false"
      [sortable]="false"
      [columnMenu]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_out | amDifference : dataItem.check_in : 'days' : true }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="check_out"
      filter="date"
      format="{0:dd/MM/yyyy}"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_OUT' | translate }}"
      [hidden]="isHiddenColumn('check_out')"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.check_out | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="count"
      filter="numeric"
      [columnMenu]="false"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.ROOMS' | translate }}"
      [hidden]="isHiddenColumn('count')"
      width="80"
    ></kendo-grid-column>

    <kendo-grid-column
      field="hotel_booking_room.type.value"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TYPE' | translate }}"
      [hidden]="isHiddenColumn('hotel_booking_room.type.value')"
      width="120"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'rooms'"
          [filter]="filter"
          [field]="'hotel_booking_room.type_id'"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.hotel_booking_room?.type"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel_booking_room.meal_plan.value"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.MEAL_PLAN_SHORT' | translate }}"
      [hidden]="isHiddenColumn('hotel_booking_room.meal_plan.value')"
      width="80"
      [columnMenu]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-type-select
          [groupType]="'room_meal_plans'"
          [field]="'hotel_booking_room.meal_plan_id'"
          [filter]="filter"
        ></app-kendo-type-select>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem?.hotel_booking_room?.meal_plan"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotel_booking_room.net_price"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NET_PRICE' | translate }}"
      [hidden]="isHiddenColumn('hotel_booking_room.net_price')"
      [filterable]="false"
      [sortable]="false"
      width="70"
      [columnMenu]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.hotel_booking_room?.net_price | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_offer_item.price"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PRICE' | translate }}"
      [hidden]="isHiddenColumn('sale_offer_item.price')"
      [filterable]="false"
      [sortable]="false"
      width="70"
      [columnMenu]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sale_offer_item?.price | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sale_final.sale.requests.status.value"
      title="{{ 'SALES.SALES_LIST.CLIENT_REQUESTS_STATUS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sale_final.sale.requests.status.value')"
      [sortable]="false"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-types-select
          [groupType]="'sale_request'"
          [filter]="filter"
          [field]="'sale_final.sale.requests.status_id'"
        ></app-kendo-types-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge
          *ngFor="let status of dataItem?.sale_final?.sale?.requestsUniqueStatuses; let i = index"
          [type]="status"
          class="d-block"
        ></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <!--    <kendo-grid-column-->
    <!--      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.ACTIONS' | translate }}"-->
    <!--      [width]="100"-->
    <!--      [class]="'text-center'"-->
    <!--      [includeInChooser]="false"-->
    <!--    >-->
    <!--      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
    <!--        -->
    <!--      </ng-template>-->
    <!--    </kendo-grid-column>-->

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button
        (click)="loadSaleFinalItemsData(saleFinalsItemsState)"
      ></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="saleFinalsItemsGrid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>

  <div class="title2">
    {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.HOTEL_BOOKING_PAYMENTS' | translate }}
  </div>

  <kendo-grid
    [data]="hotelBookingPaymentsData"
    [pageSize]="hotelBookingPaymentsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="hotelBookingPaymentsState?.filter"
    [sort]="hotelBookingPaymentsState?.sort"
    [skip]="hotelBookingPaymentsState?.skip"
    [pageable]="true"
    (dataStateChange)="loadHotelBookingPaymentsData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Hotel_Booking_Payments', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.NUMBER' | translate"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="paymentable.events"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.EVENT_YEAR' | translate"
      [filterable]="false"
      [sortable]="false"
      [hidden]="isHiddenColumn('paymentable.events')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div *ngFor="let event of dataItem.paymentable?.events">
          {{ event.name }}
          {{ event.date_from | amDateFormat : 'YYYY' }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="paymentable.ref_number"
      filter="text"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.REF_NUMBER' | translate"
      [sortable]="false"
      [filterable]="false"
      [hidden]="isHiddenColumn('paymentable.ref_number')"
      width="150"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.paymentable?.ref_number }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="date"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.DATE' | translate"
      [hidden]="isHiddenColumn('date')"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.date | amDateFormat : env.momentDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotelBooking.payment_deadline"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.PAYMENT_DEADLINE' | translate"
      [hidden]="isHiddenColumn('hotelBooking.payment_deadline')"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.paymentable?.payment_deadline | amDateFormat : env.momentDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="hotelBooking.cancellation_deadline"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CANCELLATION_DEADLINE' | translate"
      [hidden]="isHiddenColumn('hotelBooking.cancellation_deadline')"
      [sortable]="false"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.paymentable?.cancellation_deadline | amDateFormat : env.momentDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="paymentable.earliest_check_in"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CHECK_IN' | translate"
      [filterable]="false"
      [sortable]="false"
      [hidden]="isHiddenColumn('paymentable.earliest_check_in')"
      width="80"
      [columnMenu]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.paymentable?.earliest_check_in | amDateFormat : env.momentDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="amount"
      filter="numeric"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.AMOUNT' | translate"
      [hidden]="isHiddenColumn('amount')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div>
          <ng-container *ngIf="paymentTypes.EXPENSE === dataItem?.type_id"> -</ng-container>
          {{ dataItem.amount | number : '1.2-2' }}
          <app-currency-box [currency]="dataItem?.currency"></app-currency-box>
        </div>
      </ng-template>

      <ng-template kendoGridFooterTemplate>
        <div>
          {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TOTAL_AMOUNT' | translate }} :
          {{ hotelBooking?.total_amount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </div>

        <div>
          {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.TOTAL_PAID' | translate }} :
          {{ hotelBooking?.total_amount - hotelBooking?.balance | number : '1.2-2' }}
          {{ 'DEFAULT_CURRENCY' | translate }}
        </div>

        <div>
          {{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.BALANCE' | translate }} :
          <span [ngClass]="{ 'text-danger': hotelBooking?.balance > 0, 'text-success': hotelBooking?.balance <= 0 }">
            {{ hotelBooking?.balance | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
          </span>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      [hidden]="isHiddenColumn('created_at')"
      field="created_at"
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.CREATED_AT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      width="140"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.created_at.getDate() }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="is_paid"
      filter="boolean"
      [title]="'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.IS_PAID' | translate"
      [class]="'text-center'"
      [hidden]="isHiddenColumn('is_paid')"
      width="100"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.is_paid"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'HOTELS_BOOKINGS.HOTEL_BOOKINGS_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER
              ],
              ['payments.show']
            )
          "
          (click)="editPayment(dataItem)"
        >
        </app-edit-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button
        (click)="loadHotelBookingPaymentsData(hotelBookingPaymentsState)"
      ></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
