import { Model } from '@app/shared/models/model.model';
import { Client } from '@app/clients/clients/client.model';
import { Company } from '@app/settings/companies/company.model';
import { User } from '@app/settings/users/user.model';
import { RequestModel } from '@app/sales/requests/request.model';
import { Events } from '@app/events/events/events.model';
import { SaleRequest } from '@app/sales/sales/sale-request.model';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';
import { Type } from '@app/settings/types/types.model';
import { SaleFinal } from '@app/sales/sales/sale-final.model';
import * as moment from 'moment';
import { Night } from '@app/hotels/rooms/night.model';
import { SaleRequestsStatusType } from '@app/settings/types/sale-requests-status-type.enum';

export class Sale extends Model {
  id: number;
  client_id: number;
  client?: Client;
  company_id: number;
  company?: Company;
  customer_care_agent_id: number;
  customer_care_agent?: User;
  enquiry_id: number;
  enquiry?: RequestModel;
  event_id: number;
  event?: Events;
  note: string;
  reservation_agent_id: number;
  reservation_agent?: User;
  sales_agent_id: number;
  sales_agent?: User;
  status_id: number;
  status: Type;
  status_reason: string;
  requests: Array<SaleRequest>;
  offers: Array<SaleOffer>;
  source_id: number;
  source?: Type;
  campaign_id: number;
  campaign?: Type;
  channel_id?: number;
  channel?: Type;
  from_advertising: boolean;
  tags: Array<string>;
  finals: Array<SaleFinal>;
  total_amount?: number;
  balance?: number;
  assign_to_user_id: number;
  assign_to_user?: User;
  meta_front?: any;
  assign_date: string;
  sold_nights: Array<Night>;
  not_sold_activity?: any;

  deserialize(input: any): this {
    super.deserialize(input);

    if (input.reservation_agent) {
      this.reservation_agent = new User().deserialize(input.reservation_agent);
    }

    if (input.sales_agent) {
      this.sales_agent = new User().deserialize(input.sales_agent);
    }

    if (input.customer_care_agent) {
      this.customer_care_agent = new User().deserialize(input.customer_care_agent);
    }

    if (input.event) {
      this.event = new Events().deserialize(input.event);
    }

    if (input.status) {
      this.status = new Type().deserialize(input.status);
    }

    if (input.channel) {
      this.channel = new Type().deserialize(input.channel);
    }

    if (input.campaign) {
      this.campaign = new Type().deserialize(input.campaign);
    }

    if (input.source) {
      this.source = new Type().deserialize(input.source);
    }

    if (input.client) {
      this.client = new Client().deserialize(input.client);
    }

    if (input.offers) {
      this.offers = input.offers.map((offer: SaleOffer) => new SaleOffer().deserialize(offer));
    }

    if (input.company) {
      this.company = new Company().deserialize(input.company);
    }

    if (input.requests) {
      this.requests = input.requests.map((saleRequest: SaleRequest) => new SaleRequest().deserialize(saleRequest));
    }

    if (input.finals) {
      this.finals = input.finals.map((saleFinal: SaleFinal) => new SaleFinal().deserialize(saleFinal));
    }

    if (input.sold_nights) {
      this.sold_nights = input.sold_nights.map((night: Night) => new Night().deserialize(night));
    }

    if (input.assign_to_user) {
      this.assign_to_user = new User().deserialize(input.assign_to_user);
    }

    return this;
  }

  get firstArrivalDate() {
    if (this.requests.length <= 0) {
      return false;
    }
    if (this.requests.length === 1) {
      return this.requests[0].check_in;
    }

    let firstArrivalDate = this.requests[0].check_in;
    this.requests.forEach((request: SaleRequest) => {
      if (moment(request.check_in).isBefore(firstArrivalDate)) {
        firstArrivalDate = request.check_in;
      }
    });
    return firstArrivalDate;
  }

  get requestsRoomsCount() {
    let roomsCount = 0;
    if (this.requests && this.requests.length > 0) {
      this.requests.forEach((request: SaleRequest) => {
        roomsCount += Number(request.count);
      });
    }
    return roomsCount;
  }

  get requestsClosedRoomsCount() {
    let roomsCount = 0;
    if (this.requests && this.requests.length > 0) {
      this.requests.forEach((request: SaleRequest) => {
        if (request.status_id === SaleRequestsStatusType.CLOSED) {
          roomsCount += Number(request.count);
        }
      });
    }
    return roomsCount;
  }

  get requestsUniqueStatuses() {
    const uniqueStatuses: Array<Type> = [];

    this.requests.forEach((saleRequest: SaleRequest) => {
      const index = uniqueStatuses.findIndex((x) => x.id === saleRequest.status_id);

      if (index === -1) {
        uniqueStatuses.push(saleRequest.status);
      }
    });

    return uniqueStatuses;
  }

  get soldRoomsGuestUniqueNames() {
    const guestNames: Array<string> = [];

    if (this.sold_nights && this.sold_nights.length > 0) {
      this.sold_nights.forEach((night: Night) => {
        if (night.guest_names && night.guest_names.length > 0) {
          night.guest_names.forEach((name: string) => {
            if (guestNames.indexOf(name) < 0) {
              guestNames.push(name);
            }
          });
        }
      });
    }

    return guestNames;
  }
}
