<div
  class="clients-view-wrapper relative view-with-static-right-sidebar ps"
  [ngClass]="{ 'sidebar-opened': activitiesService.isOpened }"
  [perfectScrollbar]
  #perfectScroll="ngxPerfectScrollbar"
>
  <app-side-button
    [icon]="'far fa-history'"
    [customClasses]="'btn-activity-floating'"
    (click)="openActivities()"
    *ngIf="!activitiesService.isOpened && clientId"
  ></app-side-button>

  <div class="top">
    <div class="preview-header d-flex justify-content-between">
      <div class="preview-title-wrapper">
        <div class="d-flex align-items-center">
          <span class="icon-holder"><i class="far fa-user"></i></span>
          <div class="title">
            {{ client?.name }}
          </div>
          <div class="additional-info inline-block">
            <div class="sub-title ml-2">
              {{ 'COMPANIES.COMPANIES_VIEW.ACTIVE' | translate }}
            </div>

            <div class="value">
              <app-active-indicator [active]="client?.active"></app-active-indicator>
            </div>

            <app-type-badge [type]="client?.type"></app-type-badge>
          </div>
        </div>
      </div>
      <div class="preview-btns-wrapper d-flex align-items-stretch">
        <app-phone-calls-finder-button class="mr-2" [id]="clientId" [type]="LaravelModelsEnum.CLIENT">
        </app-phone-calls-finder-button>

        <app-reminder-button
          class="mr-2"
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING
              ],
              []
            )
          "
          [reminderableId]="clientId"
          [reminderableType]="LaravelModelsEnum.CLIENT"
        ></app-reminder-button>

        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['clients.show']
            )
          "
          type="button"
          class="btn btn-sm btn-success mr-2"
          (click)="edit()"
        >
          {{ 'CLIENTS.CLIENT_VIEW.EDIT_CLIENT_BTN' | translate }}
        </button>

        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.store']
            )
          "
          type="button"
          class="btn btn-sm btn-info"
          (click)="addSale()"
        >
          {{ 'CLIENTS.CLIENT_VIEW.ADD_SALE_BTN' | translate }}
        </button>
      </div>
    </div>

    <div class="d-flex">
      <div class="w-50 pb-3 border-right">
        <div class="pl-3">
          <div class="row">
            <div class="col-sm-7">
              <div class="section-title mt-2 mb-1">
                <i class="fab fa-gofore"></i>
                {{ 'CLIENTS.CLIENT_VIEW.GENERAL' | translate }}
              </div>

              <div>
                <div class="sub-title" *ngIf="client?.country">
                  <i class="far fa-globe-europe"></i>
                  {{ 'CLIENTS.CLIENT_VIEW.COUNTRY' | translate }}
                </div>
                <div class="value" *ngIf="client?.country">
                  <span
                    *ngIf="client?.country && client?.country?.code"
                    class="flag-icon"
                    [ngClass]="'flag-icon-' + (client?.country?.code | lowercase)"
                  >
                  </span>
                  {{ client?.country?.name }}
                </div>
              </div>

              <div>
                <div class="sub-title" *ngIf="client?.city">
                  <i class="fal fa-location-circle"></i>
                  {{ 'CLIENTS.CLIENT_VIEW.CITY' | translate }}
                </div>
                <div class="value" *ngIf="client?.city?.name">
                  {{ client?.city?.name }}
                </div>
              </div>

              <div>
                <div class="sub-title" *ngIf="client?.address">
                  <i class="fas fa-sort-numeric-down"></i>
                  {{ 'CLIENTS.CLIENT_VIEW.ADDRESS' | translate }}
                </div>
                <div class="value" *ngIf="client?.address">
                  {{ client?.address }}
                </div>
              </div>

              <div>
                <div *ngIf="client?.vat_number">
                  <div class="sub-title">
                    <i class="far fa-map-marker-check"></i>
                    {{ 'CLIENTS.CLIENT_VIEW.VAT_NUMBER' | translate }}
                  </div>
                  <div class="value">
                    {{ client.vat_number }}
                  </div>
                </div>
              </div>

              <div>
                <div *ngIf="client?.locales?.length > 0">
                  <div class="sub-title">
                    <i class="fal fa-language"></i>
                    {{ 'CLIENTS.CLIENT_VIEW.LANGUAGES_SPOKEN' | translate }}
                  </div>
                  <div *ngFor="let locale of client?.locales; let i = index" class="value">
                    {{ locale?.name }}<span *ngIf="i !== client?.locales?.length - 1">, </span>
                  </div>
                </div>
              </div>

              <div>
                <div *ngIf="client?.industry?.name">
                  <div class="sub-title">
                    <i class="far fa-industry"></i>
                    {{ 'CLIENTS.CLIENT_VIEW.INDUSTRY' | translate }}
                  </div>
                  <div class="value">
                    {{ client?.industry?.name }}
                  </div>
                </div>
              </div>

              <div>
                <div *ngIf="client?.responsible_person">
                  <div class="sub-title">
                    <i class="far fa-user-tie"></i>
                    {{ 'CLIENTS.CLIENT_VIEW.RESPONSIBLE_PERSON' | translate }}
                  </div>
                  <div class="value">
                    {{ client?.responsible_person }}
                  </div>
                </div>
              </div>

              <div>
                <div *ngIf="client?.tags && client?.tags?.length > 0">
                  <div class="sub-title">
                    <i class="far fa-tags"></i>
                    {{ 'CLIENTS.CLIENT_VIEW.TAGS' | translate }}
                  </div>
                  <div class="value">
                    <span *ngFor="let tag of client?.tags; let i = index">
                      {{ tag }}<span *ngIf="i !== client?.tags?.length - 1">, </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div *ngIf="client?.contacts?.length > 0">
                <div class="section-title mt-2 mb-1">
                  <i class="far fa-address-book"></i>
                  {{ 'CLIENTS.CLIENT_VIEW.CONTACTS' | translate }}
                </div>

                <app-contacts-box [contacts]="client?.contacts"></app-contacts-box>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 pt-3 pr-3 border-top" *ngIf="client?.description">
          <div class="pl-3">
            <div class="note-box">
              <div class="sub-title">
                <i class="far fa-sticky-note"></i>
                {{ 'CLIENTS.CLIENT_VIEW.DESCRIPTION' | translate }}
              </div>
              <div class="value m-0">
                {{ client?.description }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-50 pl-3 pt-2 pr-3 pb-3">
        <div *ngIf="client?.contact_persons?.length > 0">
          <div class="section-title mt-2 mb-1">
            <i class="far fa-address-book"></i>
            {{ 'CLIENTS.CLIENT_VIEW.CONTACT_PERSONS' | translate }}
          </div>

          <div class="row">
            <ng-container *ngFor="let contactPerson of client?.contact_persons; let i = index">
              <div class="col-sm-6 pr-3" *ngIf="contactPerson.show">
                <div class="w-100">
                  <div class="contact-person-name-wrapper">
                    {{ contactPerson?.title?.value }}
                    <strong class="contact-person-name">
                      {{ contactPerson?.first_name }}
                      {{ contactPerson?.last_name }}
                    </strong>
                    {{ contactPerson?.position }}
                  </div>
                </div>

                <div class="d-flex" *ngIf="contactPerson?.event_group?.name">
                  <div class="sub-title">{{ 'CLIENTS.CLIENT_VIEW.EVENT_GROUP' | translate }}</div>
                  <div class="value">
                    {{ contactPerson?.event_group?.name }}
                  </div>
                </div>

                <app-contacts-box [contacts]="contactPerson?.contacts"></app-contacts-box>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="title2">
    {{ 'CLIENTS.CLIENT_VIEW.SALES' | translate }}
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadDataTable($event)"
    [columnMenu]="true"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Clients', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'CLIENTS.CLIENT_VIEW.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="company.abbreviation"
      title="{{ 'CLIENTS.CLIENT_VIEW.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('company.abbreviation')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/settings/companies/view']" [queryParams]="{ id: dataItem?.company_id }" target="_blank">
          {{ dataItem?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.name"
      title="{{ 'CLIENTS.CLIENT_VIEW.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.name')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/sales/view']" [queryParams]="{ id: dataItem.id }">
          {{ dataItem.event.formattedName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.date_from"
      title="{{ 'CLIENTS.CLIENT_VIEW.EVENT_DATE_FROM' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      width="140"
      [hidden]="isHiddenColumn('event.date_from')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.event?.date_from | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.date_to"
      title="{{ 'CLIENTS.CLIENT_VIEW.EVENT_DATE_TO' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      width="140"
      [hidden]="isHiddenColumn('event.date_to')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.event?.date_to | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.venue.city.name"
      title="{{ 'CLIENTS.CLIENT_VIEW.CITY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.venue.city.name')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.event?.venue?.city?.name }}
        <span *ngIf="dataItem?.event?.venue?.city?.country">({{ dataItem?.event?.venue?.city?.country?.name }})</span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="tags"
      title="{{ 'CLIENTS.CLIENT_VIEW.TAGS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('tags')"
      [sortable]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span *ngFor="let tag of dataItem?.tags; let i = index">
          {{ tag }}<span *ngIf="i !== dataItem?.tags?.length - 1">, </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sales_agent.fake_first_name"
      title="{{ 'CLIENTS.CLIENT_VIEW.SALES_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sales_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
          [field]="'sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.sales_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="reservation_agent.fake_first_name"
      title="{{ 'CLIENTS.CLIENT_VIEW.RESERVATION_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('reservation_agent.fake_first_name')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
          [field]="'reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.reservation_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="from_advertising"
      title="{{ 'CLIENTS.CLIENT_VIEW.FROM_ADVERTISING' | translate }}"
      filter="boolean"
      [width]="130"
      [class]="'text-center'"
      [hidden]="isHiddenColumn('from_advertising')"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-active-indicator [active]="dataItem.from_advertising"></app-active-indicator>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="channel.value"
      title="{{ 'CLIENTS.CLIENT_VIEW.BOOKING_CHANEL' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('channel.value')"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="source.value"
      title="{{ 'CLIENTS.CLIENT_VIEW.SOURCE' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('source.value')"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="campaign.value"
      title="{{ 'CLIENTS.CLIENT_VIEW.CAMPAIGN' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('campaign.value')"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="status.value"
      title="{{ 'CLIENTS.CLIENT_VIEW.STATUS' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('status.value')"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-type-badge [type]="dataItem.status"></app-type-badge>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      width="100"
      field="requestsRoomsCount"
      title="{{ 'CLIENTS.CLIENT_VIEW.REQUESTED_ROOMS_COUNT' | translate }}"
      [filterable]="false"
      [sortable]="false"
      [hidden]="isHiddenColumn('requestsRoomsCount', true)"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="created_at"
      title="{{ 'CLIENTS.CLIENT_VIEW.CREATED_AT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('created_at')"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.created_at.getDate() }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'CLIENTS.CLIENT_VIEW.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.show']
            )
          "
          (click)="editSale(dataItem)"
        >
        </app-edit-button>

        <app-sale-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION,
                UserRoleType.MARKETING
              ],
              ['sales.show']
            )
          "
          [link]="['/sales/view']"
          [params]="{ id: dataItem.id }"
        >
        </app-sale-button>

        <app-delete-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              ['sales.destroy']
            )
          "
          [swal]="{}"
          (confirm)="deleteSale(dataItem)"
        >
        </app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadDataTable(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>
</div>
