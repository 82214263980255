<div class="dashboard-box1-wrapper mb-1">
  <div class="dashboard-box1-header d-flex justify-content-between">
    <div class="d-flex justify-content-between">
      <div class="dashboard-box1-title">
        {{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.UPCOMING_EVENTS_LIST_TITLE' | translate }}
      </div>

      <div class="collapse-trigger ml-2" (click)="toggle()">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-chevron-down" *ngIf="collapsed"></i>
          <i class="fal fa-chevron-up" *ngIf="!collapsed"></i>
        </button>
      </div>

      <div class="refresh-trigger ml-2" (click)="loadData()" *ngIf="!collapsed">
        <button type="button" class="btn btn-ico btn-success">
          <i class="fal fa-sync"></i>
        </button>
      </div>
    </div>

    <div>
      <small class="pointer btn-link" (click)="viewAllUpcomingEvents()">
        {{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.VIEW_ALL' | translate }}
      </small>
    </div>
  </div>

  <div class="dashboard-box1-body" [perfectScrollbar] *ngIf="!collapsed">
    <div *ngIf="!data">
      {{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.NO_UPCOMING_EVENTS_MSG' | translate }}
    </div>

    <div class="table" *ngIf="data && data.length > 0">
      <thead>
        <tr>
          <th>{{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.NUMBER' | translate }}</th>
          <th>{{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.EVENT' | translate }}</th>
          <th>{{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.START_DATE' | translate }}</th>
          <th>{{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.END_DATE' | translate }}</th>
          <th>{{ 'DASHBOARD.DASHBOARD_UPCOMING_EVENTS.LOCATION' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let event of data; let I = index">
          <td>{{ I + 1 }}</td>
          <td>
            <a [routerLink]="['/events/view']" [queryParams]="{ id: event.id }" target="_blank">
              {{ event.formattedName }}
            </a>
          </td>
          <td>{{ event.date_from | date : env.pipeDateFormat }}</td>
          <td>{{ event.date_to | date : env.pipeDateFormat }}</td>
          <td>{{ event.city?.country?.name }}, {{ event.city?.name }}</td>
        </tr>
      </tbody>
    </div>
  </div>
</div>
