import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, Logger } from '@app/core';
import { ApiQuery } from '@app/core/http/api-query';
import { SalesService } from '@app/sales/sales/sales.service';
import { Sale } from '@app/sales/sales/sale.model';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { ActivitiesService } from '@app/shared/components/activities/activities.service';
import { EventsFormService } from '@app/events/events/events-form.service';
import { Events } from '@app/events/events/events.model';
import { Venue } from '@app/settings/venues/venue.model';
import { VenuesFormService } from '@app/settings/venues/venues-form.service';
import * as moment from 'moment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { SalesRequestsFormService } from '@app/sales/sales/sales-requests-form.service';
import { startWith } from 'rxjs/internal/operators/startWith';
import { pairwise } from 'rxjs/operators';
import { SalesOffersFormService } from '@app/sales/sales/sales-offers-form.service';
import { SaleOffer } from '@app/sales/sales/sale-offer.model';
import { DndDropEvent } from 'ngx-drag-drop';
import { SalesOffersService } from '@app/sales/sales/sales-offers.service';
import { SalesFormService } from '@app/sales/sales/sales-form.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { SalesFinalFormService } from '@app/sales/sales/sales-final-form.service';
import { SaleFinal } from '@app/sales/sales/sale-final.model';
import { RequestsFormService } from '@app/sales/requests/requests-form.service';
import { RequestModel } from '@app/sales/requests/request.model';
import { Night } from '@app/hotels/rooms/night.model';
import { CurrenciesAdditionsService } from '@app/shared/services/currencies-additions.service';
import { SaleGuestNamesFormService } from '@app/sales/sales/sale-guest-names-form.service';
import { ClientsFormService } from '@app/clients/clients/clients-form.service';
import { Client } from '@app/clients/clients/client.model';
import { ProfileService } from '@app/profile/profile.service';
import { InvoicesFormService } from '@app/finance/invoices/invoices-form.service';
import { Invoice } from '@app/finance/invoices/invoice.model';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import {
  DataStateChangeEvent,
  GridComponent,
  GridDataResult,
  RowClassArgs,
  SelectableSettings,
} from '@progress/kendo-angular-grid';
import { InvoicesService } from '@app/finance/invoices/invoices.service';
import { environment } from '@env/environment';
import { InvoiceType } from '@app/finance/invoices/invoice-type.enum';
import { ClientabeType } from '@app/shared/components/clientable-select/clientabe-type.enum';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { Payment } from '@app/finance/payments/payment.model';
import { PaymentsService } from '@app/finance/payments/payments.service';
import { PaymentsFormService } from '@app/finance/payments/payments-form.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { HotelBooking } from '@app/hotels/hotel-bookings/hotel-booking.model';
import { HotelBookingsFormService } from '@app/hotels/hotel-bookings/hotel-bookings-form.service';
import { SaleRequest } from '@app/sales/sales/sale-request.model';
import { ClientHistoryService } from '@app/shared/components/client-history/client-history.service';
import { ContactTypes } from '@app/shared/components/contact/contact-types.enum';
import { PaymentsTypes } from '@app/finance/payments/payments-types.enum';
import { SalesFinalService } from '@app/sales/sales/sales-final.service';
import { EmailTemplateSenderService } from '@app/shared/components/email-template-sender/email-template-sender.service';
import { Templates } from '@app/settings/types/templates.enum';
import { Title } from '@angular/platform-browser';
import { WebOffersService } from '@app/sales/web-offers/web-offers.service';
import { WebOffersFormService } from '@app/sales/web-offers/web-offers-form.service';
import { WebOffer } from '@app/sales/web-offers/web-offer.model';
import { AccessService } from '@app/core/access/access.service';
import { SalesOffersOnlineBookingTermsFormService } from '@app/sales/sales/sales-offers-online-booking-terms-form.service';
import { SaleOfferItem } from '@app/sales/sales/sale-offer-item.model';
import { AllotmentFinderService } from '@app/shared/components/allotment-finder/allotment-finder.service';

const log = new Logger('SalesViewComponent');

@Component({
  selector: 'app-sales-view',
  templateUrl: './sales-view.component.html',
  styleUrls: ['./sales-view.component.scss'],
})
export class SalesViewComponent extends KendoGridComponent implements OnInit, OnDestroy {
  data: Sale;
  saleId: number;
  LaravelModelsEnum = LaravelModelsEnum;
  clientTime: any = null;
  form: FormGroup;
  allOffersCollapsed = true;
  UserRoleType = UserRoleType;
  clientInfoCollapsed = this.profileService.getSetting('clientInfoCollapsed', 'salesView');
  finalsSummary = {
    netPriceNightsCount: 0,
    priceNightsCount: 0,
    marginNightsCount: 0,
    roomCount: 0,
    nightsCount: 0,
    people: 0,
    breakfastPriceNightsCount: 0,
  };
  requestsTotal: { nights_count: number } = { nights_count: 0 };
  invoicesResponse: PaginatedResponse;
  invoicesData: GridDataResult;
  invoiceState: DataStateChangeEvent;
  invoiceApiQuery: ApiQuery = new ApiQuery();
  env = environment;
  InvoiceType = InvoiceType;
  ClientabeType = ClientabeType;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;
  CurrenciesAdditionsService = CurrenciesAdditionsService;
  requestsMassStatusId: number = null;
  clockInterval: any = null;
  ContactTypes = ContactTypes;
  PaymentsTypes = PaymentsTypes;

  selectableSettings: SelectableSettings = {
    checkboxOnly: true,
    mode: 'multiple',
  };

  paymentsExpensesResponse: PaginatedResponse;
  paymentsExpensesData: GridDataResult;
  paymentsExpensesState: DataStateChangeEvent;
  paymentsExpensesApiQuery: ApiQuery = new ApiQuery();

  dateNow = moment().format(environment.apiPipeDateFormat);

  templatesTypes = Templates;

  offerDragDisabled = true;

  webOffersResponse: PaginatedResponse;
  webOffersData: GridDataResult;
  webOffersState: DataStateChangeEvent;
  webOffersApiQuery: ApiQuery = new ApiQuery();

  offersMeta = {
    showAll: false,
    hiddenCount: 0,
  };

  onlineBookingTermsId: number;

  @ViewChild('requestsGrid', { static: false }) requestsGrid: GridComponent;

  constructor(
    public activitiesService: ActivitiesService,
    private route: ActivatedRoute,
    private router: Router,
    private salesService: SalesService,
    private eventsFormService: EventsFormService,
    private venuesFormService: VenuesFormService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private salesRequestsFormService: SalesRequestsFormService,
    private salesOffersFormService: SalesOffersFormService,
    private salesOffersService: SalesOffersService,
    private salesFormService: SalesFormService,
    private salesFinalFormService: SalesFinalFormService,
    private requestsFormService: RequestsFormService,
    private saleGuestNamesFormService: SaleGuestNamesFormService,
    private clientsFormService: ClientsFormService,
    private profileService: ProfileService,
    private invoiceFormService: InvoicesFormService,
    private invoicesService: InvoicesService,
    private paymentsService: PaymentsService,
    private paymentsFormService: PaymentsFormService,
    private hotelBookingsFormService: HotelBookingsFormService,
    private clientHistoryService: ClientHistoryService,
    protected kendoGridService: KendoGridService,
    private salesFinalService: SalesFinalService,
    private emailTemplateSenderService: EmailTemplateSenderService,
    private titleService: Title,
    private auth: AuthenticationService,
    private webOffersService: WebOffersService,
    private webOffersFormService: WebOffersFormService,
    private salesOffersOnlineBookingTermsService: SalesOffersOnlineBookingTermsFormService,
    public accessService: AccessService,
    public allotmentFinderService: AllotmentFinderService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((params) => {
      if (params.get('id')) {
        this.saleId = Number(params.get('id'));
        this.onlineBookingTermsId = Number(params.get('onlineBookingTerms'));
        this.createForm();
        this.loadSaleData(Number(params.get('id')), true);

        if (
          this.accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_AGENT,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.IT,
              UserRoleType.MARKETING,
              UserRoleType.EXTERNAL_PARTNER_LEAD,
            ],
            []
          )
        ) {
          this.activitiesService.auto(LaravelModelsEnum.SALE, this.saleId, false, true);
        }

        this.loadInvoicesData();
        this.loadPaymentsExpensesData();
        this.loadWebOffersData();
      } else {
        log.error('Id or booking_id is not found in route params!');
      }
    });
  }

  ngOnDestroy(): void {
    // крие sidebar-a s activity-тата и съобщенията
    this.activitiesService.hide();
  }

  /**
   *  Взема данните от API-то и ги пълни във формата и в масива
   * @param id Sale ID
   * @param attachAgentChange Дали да закача listener-ите за промяна на полетата(Агентите) във формата
   * @param patchFormEmitEvent Дали да емитне ивент, когато patch-ва формата
   */
  loadSaleData(id: number, attachAgentChange: boolean = false, patchFormEmitEvent: boolean = true): void {
    log.debug('loadSaleData');

    this.finalsSummary = {
      netPriceNightsCount: 0,
      priceNightsCount: 0,
      marginNightsCount: 0,
      roomCount: 0,
      nightsCount: 0,
      people: 0,
      breakfastPriceNightsCount: 0,
    };
    this.offersMeta.hiddenCount = 0;

    /**
     * Тези е хубаво да са повече, понеже се използват по модалите
     * с цел като се отвори модала да не се гетват на ново.
     * Ако се маха някой include да се провери първо дали трябва на някоя форма!
     */
    const apiQuery = new ApiQuery().addIncludes(
      'company.bank_accounts.currency', // предава се на формата за фактуриране, да не се маха
      'event.venue.city.country',
      'client',
      'client.contacts.type',
      'client.contact_persons.contacts.type',
      'client.contact_persons.title',
      'client.contact_persons.event_group',
      'client.city',
      'client.country',
      'client.type',
      'client.locales',
      'sales_agent.contacts',
      'customer_care_agent.contacts',
      'reservation_agent.contacts',
      'enquiry.hotel',
      'requests.hotel.type',
      'requests.type',
      'requests.meal_plan',
      'requests.status',
      'offers.items.hotel.city.country',
      'offers.client_comment_type',
      'offers.items.hotel.venues',
      'offers.items.type',
      'offers.items.request', // на венко му трябва за нещо http://git.provision.bg:81/t-rex/app/-/commit/afbb4d4163efb8dbaa2bce9d85db2a1e75a8f401
      'offers.items.meal_plan',
      'offers.items.currency',
      'offers.items.supplier',
      'offers.items.allotment.booking.property',
      'offers.items.allotment.nights.cancellation',
      'offers.items.net_price_currency',
      'offers.final',
      'source',
      'status',
      'campaign',
      'channel',
      'tags',
      'finals.payment_method',
      'finals.sale_offer',
      'finals.items',
      'finals.items.guest_names',
      'finals.items.hotel_booking_room.hotel.city.country',
      'finals.items.hotel_booking_room.meal_plan',
      'finals.items.hotel_booking_room.type',
      'finals.items.hotel_booking_room.booking.supplier',
      'finals.items.hotel_booking_room.booking.company.bank_accounts',
      'finals.items.sale_offer_item.net_price_currency',
      'finals.items.sale_offer_item.request', // на венко му трябва за нещо http://git.provision.bg:81/t-rex/app/-/commit/afbb4d4163efb8dbaa2bce9d85db2a1e75a8f401
      'finals.items.hotel_booking_room.booking.property',
      'finals.items.hotel_booking_room.booking.breakfast_price_per_night_currency',
      //  'finals.items.hotel_booking_room.nights.net_price_currency',
      'finals.items.hotel_booking_nights.net_price_currency',
      'finals.items.sale_offer_item'
    );

    this.salesService.show(id, apiQuery).subscribe((response: Sale) => {
      const temp = new Sale().deserialize(response);

      this.requestsTotal.nights_count = 0;
      temp.requests.forEach((request: any) => {
        this.requestsTotal.nights_count +=
          moment(request.check_out).diff(moment(request.check_in), 'days', true) * Number(request.count);
      });

      temp.offers.forEach((offer: any) => {
        offer['meta_front'] = {
          collapsed: true,
          form: {
            id: offer.id,
            name: offer.name,
            show_to_web: offer.show_to_web,
            cancellation_terms: offer.cancellation_terms,
            completed: offer.completed,
          },
          totals: {
            rooms_count: 0,
            nights_count: 0,
            people: 0,
            net_price_eur: 0,
            total_net_price_eur: 0,
            price: 0,
            total_price: 0,
            client_margin: 0,
            total_client_margin: 0,
            client_penalty: 0,
            supplier_penalty: 0,
          },
          selectedOffersLines: [],
          selectedOffersLinesTotals: {
            rooms_count: 0,
            nights_count: 0,
            people: 0,
            net_price_eur: 0,
            total_net_price_eur: 0,
            price: 0,
            total_price: 0,
            client_margin: 0,
            total_client_margin: 0,
            client_penalty: 0,
            supplier_penalty: 0,
          },
        };
        offer.items.forEach((item: any) => {
          if (item.hotel) {
            item.hotel.venues.forEach((venue: any) => {
              if (venue.id === temp.event.venue_id) {
                item['distance_to_fair'] = venue.pivot.distance;
              }
            });
          }
          offer['meta_front'].totals.people += Number(item.people) * Number(item.count);

          offer['meta_front'].totals.rooms_count += Number(item.count);
          offer['meta_front'].totals.nights_count += Number(
            moment(item.check_out).diff(moment(item.check_in), 'days', true) * Number(item.count)
          );

          offer['meta_front'].totals.net_price_eur += CurrenciesAdditionsService.toEUR(
            item.net_price,
            item.net_price_currency,
            item.exchange_rate
          );

          offer['meta_front'].totals.total_net_price_eur +=
            CurrenciesAdditionsService.toEUR(item.net_price, item.net_price_currency, item.exchange_rate) *
            item.count *
            this.getNightsCount(item.check_in, item.check_out);

          offer['meta_front'].totals.price += Number(item.price);

          offer['meta_front'].totals.total_price +=
            item.price * item.count * this.getNightsCount(item.check_in, item.check_out);

          offer['meta_front'].totals.client_margin +=
            item.price - CurrenciesAdditionsService.toEUR(item.net_price, item.net_price_currency, item.exchange_rate);

          offer['meta_front'].totals.total_client_margin +=
            item.price * item.count * this.getNightsCount(item.check_in, item.check_out) -
            CurrenciesAdditionsService.toEUR(
              item.net_price * item.count * this.getNightsCount(item.check_in, item.check_out),
              item.net_price_currency,
              item.exchange_rate
            );

          offer['meta_front'].totals.client_penalty += Number(item.client_penalty);

          offer['meta_front'].totals.supplier_penalty += Number(item.supplier_penalty);

          // cancellation logic start

          item['meta_front'] = {
            cancellationsCount: 0,
            finishedCancellationsCount: 0,
          };

          // if (item.allotment) {
          //   item.allotment.nights.forEach((night: Night) => {
          //     if (
          //       night.cancellation &&
          //       moment(night.date).isBetween(moment(item.check_in), moment(item.check_out), null, '[)')
          //     ) {
          //       item['meta_front'].cancellationsCount += 1;
          //
          //       if (night.cancellation.finished) {
          //         item['meta_front'].finishedCancellationsCount += 1;
          //       }
          //     }
          //   });
          // }
        });

        if (!offer.final) {
          this.offersMeta.hiddenCount++;
        }
      });

      temp.finals.forEach((final: any) => {
        log.debug('finallllllllllllll', final);
        final['meta_front'] = {
          form: {
            id: final.id,
            sale_offer_id: final.sale_offer_id,
            payment_method_id: final.payment_method_id,
            completed: final.completed,
          },
          totals: {
            price: 0,
            priceNightsCount: 0,
            netPrice: 0,
            netPriceNightsCount: 0,
            margin: 0,
            marginNightsCount: 0,
            roomCount: 0,
            nightsCount: 0,
            people: 0,
            breakfastPrice: 0,
            breakfastPriceNightsCount: 0,
          },
          selectedFinalLines: [],
          selectedFinalLinesTotals: {
            price: 0,
            priceNightsCount: 0,
            netPrice: 0,
            netPriceNightsCount: 0,
            margin: 0,
            marginNightsCount: 0,
            roomCount: 0,
            nightsCount: 0,
            people: 0,
            breakfastPrice: 0,
            breakfastPriceNightsCount: 0,
          },
        };

        final.items.forEach((item: any) => {
          const nights = this.getNightsCount(item.check_in, item.check_out);

          item['meta_front'] = {
            guestNamesArr: {},
          };

          if (item.hotel_booking_nights) {
            let netPrice = 0;

            item.hotel_booking_nights.forEach((night: any, index: number) => {
              // групира по room_id guest names масив, който съдържа guestNames confirmationNumber refNumber
              const guestNamesArrKey = night.hotel_booking_room_id;
              if (!item.meta_front.guestNamesArr[guestNamesArrKey]) {
                item.meta_front.guestNamesArr[guestNamesArrKey] = {
                  guestNames: [],
                  confirmationNumber: null,
                  refNumber: null,
                };
              }

              if (!item.meta_front.guestNamesArr[guestNamesArrKey].confirmationNumber) {
                item.meta_front.guestNamesArr[guestNamesArrKey].confirmationNumber = night.room.confirmation_number;
              }

              if (!item.meta_front.guestNamesArr[guestNamesArrKey].refNumber) {
                item.meta_front.guestNamesArr[guestNamesArrKey].refNumber = night.room.ref_number;
              }

              if (night.guest_names) {
                night.guest_names.forEach((guestName: string) => {
                  if (item.meta_front.guestNamesArr[guestNamesArrKey].guestNames.indexOf(guestName) < 0) {
                    item.meta_front.guestNamesArr[guestNamesArrKey].guestNames.push(guestName);
                  }
                });
              }

              netPrice += CurrenciesAdditionsService.toEUR(
                Number(night.net_price),
                night.net_price_currency,
                // night.exchange_rate
                item.sale_offer_item.exchange_rate
              );
            });

            item.hotel_booking_room['net_price'] = netPrice / nights / item.count;
          }
          // item.hotel_booking_room['client_margin'] = Number(item.sale_offer_item.price) - item.hotel_booking_room['net_price'];

          item.hotel_booking_room['client_margin'] =
            Number(item.sale_offer_item.price) -
            (item.hotel_booking_room['net_price'] +
              (item.hotel_booking_room.booking['breakfast_price_per_night'] /
                item.hotel_booking_room.booking['breakfast_price_per_night_exchange_rate'] || 0) *
                item.count *
                Number(item.sale_offer_item.people));

          final['meta_front']['totals'].netPrice += item.hotel_booking_room.net_price;
          final['meta_front']['totals'].netPriceNightsCount += item.hotel_booking_room.net_price * item.count * nights;
          final['meta_front']['totals'].price += Number(item.sale_offer_item.price);
          final['meta_front']['totals'].priceNightsCount += Number(item.sale_offer_item.price) * item.count * nights;
          final['meta_front']['totals'].margin += Number(item.hotel_booking_room['client_margin']);
          final['meta_front']['totals'].marginNightsCount +=
            Number(item.hotel_booking_room['client_margin']) * item.count * nights;
          final['meta_front']['totals'].roomCount += Number(item.count);
          final['meta_front']['totals'].nightsCount +=
            moment(item.check_out).diff(moment(item.check_in), 'days', true) * Number(item.count);
          final['meta_front']['totals'].people += Number(item.sale_offer_item.people) * Number(item.count);

          log.debug('itemitemitemitem', item);
          final['meta_front']['totals'].breakfastPrice +=
            item.hotel_booking_room.booking['breakfast_price_per_night'] /
              item.hotel_booking_room.booking['breakfast_price_per_night_exchange_rate'] || 0;
          final['meta_front']['totals'].breakfastPriceNightsCount +=
            (item.hotel_booking_room.booking['breakfast_price_per_night'] /
              item.hotel_booking_room.booking['breakfast_price_per_night_exchange_rate'] || 0) *
            item.count *
            nights *
            item.sale_offer_item.people;
        });

        this.finalsSummary.netPriceNightsCount += final.meta_front.totals.netPriceNightsCount;
        this.finalsSummary.priceNightsCount += final.meta_front.totals.priceNightsCount;
        this.finalsSummary.marginNightsCount += final.meta_front.totals.marginNightsCount;
        this.finalsSummary.roomCount += final.meta_front.totals.roomCount;
        this.finalsSummary.nightsCount += final.meta_front.totals.nightsCount;
        this.finalsSummary.people += final.meta_front.totals.people;

        this.finalsSummary.breakfastPriceNightsCount += final.meta_front.totals.breakfastPriceNightsCount;
      });

      this.data = temp;

      this.form.patchValue(response, { emitEvent: patchFormEmitEvent });
      if (attachAgentChange) {
        this.agentsChange();
      }
      if (response.client && response.client.city && response.client.city.timezone) {
        this.setLocalTimer(response.client.city.timezone);
      } else {
        clearInterval(this.clockInterval);
        this.clientTime = 'false';
      }

      if (this.data && this.data.client && this.data.client.name) {
        this.titleService.setTitle(this.data.client.name);
      }

      // Отваря модала за търмс ако има такъв параметър в адреса
      if (this.onlineBookingTermsId) {
        this.onlineBookingTermsEdit(this.onlineBookingTermsId);
      }
    });
  }

  /**
   * Отваря sidebar-a s activity-тата и съобщенията
   */
  openActivities(): void {
    this.activitiesService.show(LaravelModelsEnum.SALE, this.saleId, false);
  }

  /**
   *  Отваря модала с Event-a
   * @param event Event
   */
  editEvent(event: Events): void {
    this.eventsFormService.open(event).then((res: any) => {
      log.debug('close', res);
      if (res) {
        this.data.event = res;
      }
    });
  }

  /**
   *  Отваря модала с Venue-to
   * @param venue Venue
   */
  editVenue(venue: Venue): void {
    this.venuesFormService.open(venue).then((res: any) => {
      if (res) {
        this.data.event.venue = res;
      }
    });
  }

  /**
   * Връща разликата в дни между две дати
   * @param date1 Дата 1
   * @param date2 Дата 2
   */
  getNightsCount(date1: string | Date, date2: string | Date): number {
    if (!date1 || !date2) {
      return null;
    }
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    return moment2.diff(moment1, 'days');
  }

  /**
   * Отваря формата с request-ите
   */
  editRequest(): void {
    // this.activitiesService.optionsPass({ modalOpened: true });
    this.salesRequestsFormService
      .open(this.data, this.activitiesService.isOpened)
      .then((res: any) => {
        if (res) {
          this.loadSaleData(this.saleId);
        }
      })
      .finally(() => {
        //     this.activitiesService.optionsPass({ modalOpened: false });
      });
  }

  addOffer(): void {
    this.salesOffersFormService
      .open(this.data)
      .then((result: any) => {
        log.debug('@todo: handle close');
        this.loadSaleData(this.saleId);
        if (result.saveAndNew) {
          this.addOffer();
        }
      })
      .catch(() => {
        log.debug('x close');
      });
  }

  editOffer(offer: SaleOffer): void {
    this.salesOffersFormService
      .open(this.data, offer.id)
      .then((result: any) => {
        log.debug('@todo: handle close');
        this.loadSaleData(this.saleId);
        if (result.saveAndNew) {
          this.addOffer();
        }
      })
      .catch(() => {
        log.debug('x close');
      });
  }

  addFinalSale(offer: SaleOffer): void {
    this.salesFinalFormService
      .open(this.data, offer.id)
      .then((result: any) => {
        this.loadSaleData(this.saleId);
        this.loadInvoicesData();
      })
      .catch(() => {
        this.loadSaleData(this.saleId);
        this.loadInvoicesData();
      });
  }

  editFinalSale(saleFinal: SaleFinal) {
    this.salesFinalFormService
      .open(this.data, saleFinal.sale_offer_id, saleFinal.id)
      .then((result: any) => {
        this.loadSaleData(this.saleId);
        this.loadInvoicesData();
      })
      .catch(() => {
        this.loadSaleData(this.saleId);
        this.loadInvoicesData();
      });
  }

  deleteOffer(offerId: number) {
    this.salesOffersService.destroy(this.data.id, offerId).subscribe(() => {
      this.loadSaleData(this.saleId);
    });
  }

  /**
   *  Затваря и отваря оферта
   * @param offer Оферта
   */
  toggleOffer(offer: SaleOffer): void {
    offer.meta_front.collapsed = !offer.meta_front.collapsed;
  }

  /**
   * Отваря и затваря всички оферти
   */
  toggleAllOffers(): void {
    this.allOffersCollapsed = !this.allOffersCollapsed;
    this.data.offers.forEach((offer: SaleOffer) => {
      offer.meta_front.collapsed = this.allOffersCollapsed;
    });
  }

  /**
   * Премахва елемента когато започне да се влачи
   * @param item Item
   */
  onDragged(item: any) {
    const index = this.data.offers.indexOf(item);
    this.data.offers.splice(index, 1);
    log.debug('onDragged');
  }

  /**
   * Записва елемента на новото място
   * @param event $event
   * @param list List
   */
  onDrop(event: DndDropEvent, list?: any[]) {
    const index = event.index;
    list.splice(index, 0, event.data);
    log.debug('onDrop', this.data.offers);
    setTimeout(() => {
      this.saveOffersOrder();
    });
  }

  editSale() {
    if (this.data && this.data.id) {
      this.salesFormService.open(this.data).then(
        () => {
          this.loadSaleData(this.saleId, false, false);
          this.loadWebOffersData();
        },
        (err: any) => {
          log.debug(err);
        }
      );
    } else {
      this.translate.get('SALES.SALES_VIEW.NO_SALE_FOUND').subscribe((trans: string) => {
        this.toastr.error(trans);
      });
    }
  }

  editEnquiry(enquiry: RequestModel) {
    this.requestsFormService.open(enquiry).then(
      () => {
        this.loadSaleData(this.saleId);
      },
      (err: any) => {
        log.debug(err);
      }
    );
  }

  editGuestNames(saleFinalId: number) {
    this.saleGuestNamesFormService.open(this.saleId, saleFinalId).then(
      (res) => {
        this.loadSaleData(this.saleId);
      },
      (err) => {}
    );
  }

  editClient(client: Client) {
    log.debug('client', client);
    this.clientsFormService.open(new Client().deserialize(client)).then(
      (response) => {
        this.loadSaleData(this.saleId);

        if (response.client && response.client.city && response.client.city.timezone) {
          this.setLocalTimer(response.client.city.timezone);
        } else {
          clearInterval(this.clockInterval);
          this.clientTime = 'false';
        }
      },
      (res) => {
        log.debug(res);
      }
    );
  }

  /**
   * Скрива и показва инфото за клиента.
   * Записва промяната.
   */
  toggleClientInfo() {
    this.clientInfoCollapsed = !this.clientInfoCollapsed;
    this.profileService.setSetting('clientInfoCollapsed', this.clientInfoCollapsed, 'salesView').subscribe();
  }

  createInvoice(id: number) {
    log.debug('this.data.company', this.data.company);
    this.invoiceFormService
      .open(
        new Invoice().deserialize({
          clientable_id: this.data.client_id || null,
          clientable: this.data.client || null,
          clientable_type: ClientabeType.CLIENT,
          company_id: this.data.company_id || null,
          company: this.data.company || null,
          invoiceable_id: id,
          invoiceable_type: LaravelModelsEnum.SALE_FINAL,
        }),
        true
      )
      .then(
        (res) => {
          this.loadInvoicesData(this.invoiceState);
          this.loadSaleData(this.saleId);
        },
        (res) => {
          this.loadInvoicesData(this.invoiceState);
          log.debug(res);
        }
      );
  }

  loadInvoicesData(state: DataStateChangeEvent = null, venueId: number = null) {
    // Предотвратява правенето на излишен request ако user-а няма права
    // if (!AuthenticationService.checkPermission('invoices.index')) {
    //   // return console.info('Current user havent got permissions for INVOICES.INDEX');
    // }

    this.invoiceState = state;
    this.invoiceApiQuery
      .setDataSetFilters(this.invoiceState)
      .addIncludes(
        'company',
        'clientable',
        'type',
        'payment_method',
        'payments.company',
        'payments.clientable',
        'payments.type',
        'payments.payment_method',
        'payments.currency',
        'payments.fee_currency',
        'payments.bank_account',
        'invoiceable.items.hotel_booking_room.booking.company',
        'invoiceable.sale_offer',
        'deposit_invoices'
      )
      .addFilters({
        sale_id: this.saleId,
        hide_linked: true,
      });

    this.invoicesService.index(this.invoiceApiQuery).subscribe((response: PaginatedResponse) => {
      // взима всички company-та от всички hotel_booking-и и ги слага в един масив, за да се покажат в таблицата
      response.data.forEach((invoice: Invoice) => {
        invoice['meta_front'] = {
          booking_offices: [],
        };
        invoice.invoiceable.items.forEach((item: any) => {
          if (invoice.meta_front.booking_offices.indexOf(item.hotel_booking_room.booking.company.abbreviation) === -1) {
            invoice.meta_front.booking_offices.push(item.hotel_booking_room.booking.company.abbreviation);
          }
        });
      });

      this.invoicesResponse = response;
      this.invoicesData = response.getGridData();
    });
  }

  deleteInvoice(invoice: Invoice) {
    this.invoicesService.destroy(invoice.id).subscribe(() => {
      this.loadInvoicesData(this.invoiceState);
    });
  }

  editInvoice(invoice: Invoice) {
    this.invoiceFormService.open(invoice, true).then(
      (res) => {
        this.loadInvoicesData(this.invoiceState);
      },
      (res) => {
        this.loadInvoicesData(this.invoiceState);
      }
    );
  }

  afterCreatePayment() {
    this.loadInvoicesData();
    this.loadSaleData(this.saleId);
    this.loadPaymentsExpensesData();
  }

  editPayment(payment: Payment) {
    this.paymentsFormService.open(payment, true).then(
      (res) => {
        this.loadInvoicesData(this.invoiceState);
        this.loadPaymentsExpensesData(this.paymentsExpensesState);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  deletePayment(payment: Payment) {
    this.paymentsService.destroy(payment.id).subscribe(() => {
      this.loadInvoicesData(this.invoiceState);
      this.loadPaymentsExpensesData(this.paymentsExpensesState);
    });
  }

  saleOfferChange(saleOffer: SaleOffer) {
    this.salesOffersService.update(this.saleId, saleOffer).subscribe((res: any) => {
      this.translate.get('SALES.SALES_VIEW.OFFER_CHANGED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  saleFinalChange(saleFinal: SaleFinal) {
    this.salesFinalService.update(this.saleId, saleFinal).subscribe((res: any) => {
      this.translate.get('SALES.SALES_VIEW.FINAL_CHANGED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  editHotelBooking(
    hotelBooking: HotelBooking,
    nights: Array<Night>,
    hideBackdrop: boolean = false,
    final: SaleFinal = null
  ) {
    this.hotelBookingsFormService
      .open(new HotelBooking().deserialize(hotelBooking), null, nights, hideBackdrop, final)
      .then(
        (res: any) => {
          this.loadSaleData(this.saleId);
        },
        () => {}
      );
  }

  setRequestsStatus() {
    this.data.requests.forEach((request: SaleRequest) => {
      request.status_id = this.requestsMassStatusId;
    });
    this.updateRequests();
  }

  updateRequests() {
    this.salesService.requests(this.saleId, { requests: this.data.requests }).subscribe((res: any) => {
      this.translate.get('SALES.SALES_VIEW.REQUESTS_STATUS_CHANGED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  updateSaleNote(): void {
    const requiredFields = {
      id: this.form.value.id,
      company_id: this.form.value.company_id,
      client_id: this.form.value.client_id,
      event_id: this.form.value.event_id,
      from_advertising: this.form.value.from_advertising,
      note: this.data.note,
    };

    this.salesService.update(new Sale().deserialize(requiredFields)).subscribe(() => {
      this.translate.get('SALES.SALES_VIEW.NOTE_UPDATED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  updateSaleAssign(): void {
    this.form.get('assign_date').patchValue(moment().format(environment.apiPipeDateFormat));

    const requiredFields = {
      id: this.form.value.id,
      company_id: this.form.value.company_id,
      client_id: this.form.value.client_id,
      event_id: this.form.value.event_id,
      from_advertising: this.form.value.from_advertising,
      assign_to_user_id: this.form.value.assign_to_user_id,
      assign_to_role_id: this.form.value.assign_to_role_id,
      assign_date: this.form.value.assign_date,
    };

    this.salesService.update(new Sale().deserialize(requiredFields)).subscribe(() => {
      this.translate.get('SALES.SALES_VIEW.ASSIGN_UPDATED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  updateSaleAssignDate() {
    log.debug('updateSaleAssignDate');
    const requiredFields = {
      id: this.form.value.id,
      company_id: this.form.value.company_id,
      client_id: this.form.value.client_id,
      event_id: this.form.value.event_id,
      from_advertising: this.form.value.from_advertising,
      assign_date: this.form.value.assign_date,
    };

    this.salesService.update(new Sale().deserialize(requiredFields)).subscribe(() => {
      this.translate.get('SALES.SALES_VIEW.ASSIGN_DATE_UPDATED_MSG').subscribe((trans: string) => {
        this.toastr.success(trans);
      });
    });
  }

  openClientHistory(client_id: number): void {
    this.clientHistoryService.open(client_id).then(
      () => {},
      () => {}
    );
  }

  /**
   * Когато избраните оферти се променят
   */
  offersSelectionChange(offer: any): void {
    setTimeout(() => {
      // Занулява всички променливи
      offer.meta_front.selectedOffersLinesTotals = {
        rooms_count: 0,
        nights_count: 0,
        people: 0,
        net_price_eur: 0,
        total_net_price_eur: 0,
        price: 0,
        total_price: 0,
        client_margin: 0,
        total_client_margin: 0,
        client_penalty: 0,
        supplier_penalty: 0,
      };

      // Ако офер линията е селектната добавя стойностите
      offer.items.forEach((offerLine: any) => {
        if (offer.meta_front.selectedOffersLines.indexOf(offerLine.id) > -1) {
          offer.meta_front.selectedOffersLinesTotals.rooms_count += Number(offerLine.count);
          offer.meta_front.selectedOffersLinesTotals.nights_count +=
            moment(offerLine.check_out).diff(moment(offerLine.check_in), 'days', true) * Number(offerLine.count);

          offer.meta_front.selectedOffersLinesTotals.people += Number(offerLine.people) * Number(offerLine.count);

          offer.meta_front.selectedOffersLinesTotals.net_price_eur += CurrenciesAdditionsService.toEUR(
            offerLine.net_price,
            offerLine.net_price_currency,
            offerLine.exchange_rate
          );

          offer.meta_front.selectedOffersLinesTotals.total_net_price_eur +=
            CurrenciesAdditionsService.toEUR(
              offerLine.net_price,
              offerLine.net_price_currency,
              offerLine.exchange_rate
            ) *
            offerLine.count *
            this.getNightsCount(offerLine.check_in, offerLine.check_out);

          offer.meta_front.selectedOffersLinesTotals.price += Number(offerLine.price);

          offer.meta_front.selectedOffersLinesTotals.total_price +=
            offerLine.price * offerLine.count * this.getNightsCount(offerLine.check_in, offerLine.check_out);

          offer.meta_front.selectedOffersLinesTotals.client_margin +=
            offerLine.price -
            CurrenciesAdditionsService.toEUR(
              offerLine.net_price,
              offerLine.net_price_currency,
              offerLine.exchange_rate
            );

          offer.meta_front.selectedOffersLinesTotals.total_client_margin +=
            offerLine.price * offerLine.count * this.getNightsCount(offerLine.check_in, offerLine.check_out) -
            CurrenciesAdditionsService.toEUR(
              offerLine.net_price * offerLine.count * this.getNightsCount(offerLine.check_in, offerLine.check_out),
              offerLine.net_price_currency,
              offerLine.exchange_rate
            );

          offer.meta_front.selectedOffersLinesTotals.client_penalty += Number(offerLine.client_penalty);

          offer.meta_front.selectedOffersLinesTotals.supplier_penalty += Number(offerLine.supplier_penalty);
        }
      });
    });
  }

  /**
   * Когато избраните файнъли се променят
   */
  finalsSelectionChange(final: any): void {
    setTimeout(() => {
      // Занулява всички променливи
      final['meta_front'].selectedFinalLinesTotals = {
        netPrice: 0,
        netPriceNightsCount: 0,
        price: 0,
        priceNightsCount: 0,
        margin: 0,
        marginNightsCount: 0,
        roomCount: 0,
        nightsCount: 0,
        people: 0,
        breakfastPrice: 0,
        breakfastPriceNightsCount: 0,
      };

      // Ако final линията е селектната добавя стойностите
      final.items.forEach((finalItem: any) => {
        if (final['meta_front'].selectedFinalLines.indexOf(finalItem.id) > -1) {
          const nights = this.getNightsCount(finalItem.check_in, finalItem.check_out);

          final['meta_front'].selectedFinalLinesTotals.netPrice += finalItem.hotel_booking_room['net_price_currency'];
          final['meta_front'].selectedFinalLinesTotals.netPriceNightsCount +=
            finalItem.hotel_booking_room['net_price'] * finalItem.count * nights;
          final['meta_front'].selectedFinalLinesTotals.price += Number(finalItem.sale_offer_item.price);
          final['meta_front'].selectedFinalLinesTotals.priceNightsCount +=
            Number(finalItem.sale_offer_item.price) * finalItem.count * nights;
          final['meta_front'].selectedFinalLinesTotals.margin += Number(finalItem.hotel_booking_room['client_margin']);
          final['meta_front'].selectedFinalLinesTotals.marginNightsCount +=
            Number(finalItem.hotel_booking_room['client_margin']) * finalItem.count * nights;
          final['meta_front'].selectedFinalLinesTotals.roomCount += Number(finalItem.count);
          final['meta_front'].selectedFinalLinesTotals.nightsCount +=
            moment(finalItem.check_out).diff(moment(finalItem.check_in), 'days', true) * Number(finalItem.count);
          final['meta_front'].selectedFinalLinesTotals.people +=
            Number(finalItem.sale_offer_item.people) * Number(finalItem.count);

          final['meta_front'].selectedFinalLinesTotals.breakfastPrice +=
            finalItem.hotel_booking_room.booking['breakfast_price_per_night'] /
              finalItem.hotel_booking_room.booking['breakfast_price_per_night_exchange_rate'] || 0;
          final['meta_front'].selectedFinalLinesTotals.breakfastPriceNightsCount +=
            (finalItem.hotel_booking_room.booking['breakfast_price_per_night'] /
              finalItem.hotel_booking_room.booking['breakfast_price_per_night_exchange_rate'] || 0) *
            Number(finalItem.count) *
            nights *
            Number(finalItem.sale_offer_item.people);
        }
      });
    });
  }

  invoiceRowCallback(context: RowClassArgs) {
    let rowOpacity = false;

    if (context.dataItem.linked_id && context.dataItem.type_id === InvoiceType.PROFORMA) {
      rowOpacity = true;
    }
    return {
      'kendo-row-opacity': rowOpacity,
    };
  }

  loadPaymentsExpensesData(state: DataStateChangeEvent = null) {
    let userAccess = false;

    this.auth.accessToken.profile.roles.forEach((role: any) => {
      role.permissions.forEach((permission: any) => {
        if (permission.name === 'payments.index') {
          userAccess = true;
        }
      });
    });

    // Предотвратява правенето на излишен request ако user-а няма права
    if (!userAccess) {
      return console.info('Current user havent got permissions for PAYMENTS.INDEX');
    }

    this.paymentsExpensesState = state;
    this.paymentsExpensesApiQuery
      .setDataSetFilters(state)
      .addFilter('sale_id', this.saleId)
      .addIncludes(
        'bank_account',
        'company',
        'payment_method',
        'type',
        'clientable',
        'currency',
        'fee_currency',
        'paymentable'
      );

    this.paymentsService.index(this.paymentsExpensesApiQuery).subscribe((response: PaginatedResponse) => {
      response['meta_front'] = {
        total_amount: 0,
        total_paid: 0,
      };

      response.data.forEach((payment: Payment) => {
        let amountCopy = CurrenciesAdditionsService.toEUR(payment.amount, payment.currency, payment.exchange_rate);
        // ако е EXPENSE обръща в минус
        if (payment.type_id === PaymentsTypes.EXPENSE) {
          amountCopy = amountCopy * -1;
        }

        response['meta_front'].total_amount += amountCopy;

        if (payment.is_paid) {
          response['meta_front'].total_paid += amountCopy;
        }
      });

      this.paymentsExpensesResponse = response;
      this.paymentsExpensesData = response.getGridData();
    });
  }

  loadWebOffersData(state: DataStateChangeEvent = null) {
    // Предотвратява правенето на излишен request ако user-а няма права
    if (!AuthenticationService.checkPermission('web_offers.index')) {
      // return console.info('Current user havent got permissions for WEB_OFFERS.INDEX');
    }

    this.webOffersState = state;
    this.webOffersApiQuery.setDataSetFilters(state).addFilters({
      web_offerable_id: this.saleId,
      web_offerable_type: this.LaravelModelsEnum.SALE,
    });

    this.webOffersService.index(this.webOffersApiQuery).subscribe((response: PaginatedResponse) => {
      this.webOffersResponse = response;
      this.webOffersData = response.getGridData();
    });
  }

  addWebOffer() {
    const webOffer = new WebOffer().deserialize({
      web_offerable_id: this.saleId,
      web_offerable_type: LaravelModelsEnum.SALE,
    });
    this.webOffersFormService.open(webOffer, this.form.get('sales_agent_id').value).then(
      (res) => {
        this.loadWebOffersData(this.webOffersState);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  editWebOffer(webOffer: WebOffer) {
    this.webOffersFormService.open(webOffer, this.form.get('sales_agent_id').value).then(
      (res) => {
        this.loadWebOffersData(this.webOffersState);
      },
      (res) => {
        log.info('modal closed');
      }
    );
  }

  deleteWebOffer(webOffer: WebOffer) {
    this.webOffersService.destroy(webOffer.id).subscribe(() => {
      this.loadWebOffersData(this.webOffersState);
    });
  }

  test(test: any) {
    log.debug(test);
  }

  openEmailTemplateSender() {
    this.emailTemplateSenderService.open(this.saleId, this.templatesTypes.SALECLIENTOFFER).then(
      () => {},
      () => {}
    );
  }

  enableOffersDrag() {
    this.offerDragDisabled = false;
  }

  disableOffersDrag() {
    this.offerDragDisabled = true;
  }

  requestsGridexportToExcel(): void {
    this.requestsGrid.saveAsExcel();
  }

  onlineBookingTermsEdit(offerId: number) {
    this.salesOffersOnlineBookingTermsService.open(this.data, offerId).then(
      () => {
        if (this.onlineBookingTermsId) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { id: this.saleId },
            // skipLocationChange: true
          });
          this.onlineBookingTermsId = null;
        }

        this.loadSaleData(this.saleId);
      },
      () => {
        if (this.onlineBookingTermsId) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { id: this.saleId },
            // skipLocationChange: true
          });
          this.onlineBookingTermsId = null;
        }
      }
    );
  }

  sortOffers(direction: 'asc' | 'desc') {
    this.data.offers.sort((first: SaleOffer, second: SaleOffer) => {
      if (direction === 'desc') {
        return first.meta_front.totals.total_price - second.meta_front.totals.total_price;
      }

      if (direction === 'asc') {
        return second.meta_front.totals.total_price - first.meta_front.totals.total_price;
      }
    });
    this.saveOffersOrder();
  }

  openClientFinder(offerItem: SaleOfferItem) {
    this.allotmentFinderService.open(null, this.data.event_id, null, offerItem?.hotel?.name).then(
      () => {},
      () => {}
    );
  }

  private saveOffersOrder() {
    this.salesOffersService
      .order(this.saleId, {
        offers: this.data.offers.map((offer: SaleOffer) => {
          return { id: offer.id };
        }),
      })
      .subscribe((response: any) => {
        log.debug(response);
        this.translate.get('SALES.SALES_VIEW.SUCCESS_REORDER_OFFERS_MSG').subscribe((trans: string) => {
          this.toastr.success(trans);
        });
      });
  }

  /**
   *  Прави часовника в часовата зона на клиента,
   *  Първо занулява timeout-a.
   *  Работи заедно с двете променливи this.clockInterval и this.clientTime
   * @param timeZone Timezone
   *
   */
  private setLocalTimer(timeZone: string = null): void {
    clearInterval(this.clockInterval);
    if (timeZone) {
      this.clockInterval = setInterval(() => {
        this.clientTime = moment().tz(timeZone).format('HH:mm:ss');
      }, 1000);
    }
  }

  /**
   * Създава формата
   */
  private createForm(): void {
    this.form = this.formBuilder.group({
      id: [null],
      company_id: [null],
      client_id: [null],
      event_id: [null],
      sales_agent_id: [null],
      sales_agent: [null],
      reservation_agent_id: [null],
      reservation_agent: [null],
      customer_care_agent_id: [null],
      customer_care_agent: [null],
      requests: [null],
      from_advertising: [null],
      assign_to_user_id: [null],
      assign_to_role_id: [null],
      assign_date: [null],
    });
  }

  private getRequiredFields() {
    return {
      id: this.form.value.id,
      company_id: this.form.value.company_id,
      client_id: this.form.value.client_id,
      event_id: this.form.value.event_id,
      from_advertising: this.form.value.from_advertising,
    };
  }

  /**
   * Следи за промени при Агентите
   */
  private agentsChange(): void {
    this.form
      .get('sales_agent_id')
      .valueChanges // https://stackoverflow.com/questions/44898010/form-control-valuechanges-gives-the-previous-value
      // https://github.com/ReactiveX/rxjs/issues/4772
      .pipe(startWith(this.form.get('sales_agent_id').value as string), pairwise())
      .subscribe(([oldVal, newVal]: [any, any]) => {
        // Ъпдейтва само ако старата и новата стойност са различни
        if (oldVal !== newVal) {
          const requestData = { ...this.getRequiredFields(), ...{ sales_agent_id: newVal } };
          this.salesService.update(new Sale().deserialize(requestData)).subscribe(() => {
            this.translate.get('SALES.SALES_VIEW.SALES_AGENT_CHANGED_MSG').subscribe((trans: string) => {
              this.toastr.success(trans);
            });
          });
        }
      });

    this.form
      .get('reservation_agent_id')
      .valueChanges // https://stackoverflow.com/questions/44898010/form-control-valuechanges-gives-the-previous-value
      // https://github.com/ReactiveX/rxjs/issues/4772
      .pipe(startWith(this.form.get('reservation_agent_id').value as string), pairwise()) // https://github.com/ReactiveX/rxjs/issues/4772
      .subscribe(([oldVal, newVal]: [any, any]) => {
        // Ъпдейтва само ако старата и новата стойност са различни
        if (oldVal !== newVal) {
          const requestData = { ...this.getRequiredFields(), ...{ reservation_agent_id: newVal } };
          this.salesService.update(new Sale().deserialize(requestData)).subscribe(() => {
            this.translate.get('SALES.SALES_VIEW.RESERVATION_AGENT_CHANGED_MSG').subscribe((trans: string) => {
              this.toastr.success(trans);
            });
          });
        }
      });

    this.form
      .get('customer_care_agent_id')
      .valueChanges // https://stackoverflow.com/questions/44898010/form-control-valuechanges-gives-the-previous-value
      // https://github.com/ReactiveX/rxjs/issues/4772
      .pipe(startWith(this.form.get('customer_care_agent_id').value as string), pairwise()) // https://github.com/ReactiveX/rxjs/issues/4772
      .subscribe(([oldVal, newVal]: [any, any]) => {
        // Ъпдейтва само ако старата и новата стойност са различни
        if (oldVal !== newVal) {
          const requestData = { ...this.getRequiredFields(), ...{ customer_care_agent_id: newVal } };
          this.salesService.update(new Sale().deserialize(requestData)).subscribe(() => {
            this.translate.get('SALES.SALES_VIEW.CUSTOMER_CARE_TEAM_CHANGED_MSG').subscribe((trans: string) => {
              this.toastr.success(trans);
            });
          });
        }
      });
  }
}
