<div class="container-fluid">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">User calls</h5>
    </div>

    <div class="d-flex align-items-center gap-3" [formGroup]="form">
      <app-date-picker [required]="true" [FormControl]="form.get('start_at')" class="mr-2"></app-date-picker>
      <app-date-picker [required]="true" [FormControl]="form.get('end_at')" class="mr-2"></app-date-picker>

      <button type="button" class="btn btn-link" (click)="clearFilters()">
        {{ 'CLEAR_FILTERS' | translate }}
      </button>
    </div>
  </div>

  <kendo-grid
    (dataStateChange)="loadData($event)"
    [columnMenu]="false"
    [data]="data"
    [filter]="state?.filter"
    [filterable]="true"
    [loading]="loader"
    [pageSize]="requestsResponse?.meta.per_page"
    [pageable]="false"
    [skip]="state?.skip"
    [sort]="state?.sort"
    [sortable]="false"
  >
    <kendo-grid-column field="fake_names" filter="text" title="Names">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-user-box [user]="dataItem"></app-user-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="Roles" filter="text" [field]="'roles.id'">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="small" *ngFor="let role of dataItem.roles">{{ role.name }}</div>
      </ng-template>

      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-role-select [filter]="filter" [field]="'roles.id'"></app-kendo-role-select>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" title="Inbound" class="text-center" headerClass="text-center">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.phone_calls_inbound_count }}
      </ng-template>
      <!--        <ng-template kendoGridFooterTemplate>-->
      <!--          {{ totalToNow }}-->
      <!--        </ng-template>-->
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" title="Outbound" class="text-center" headerClass="text-center">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.phone_calls_outbound_count }}
      </ng-template>
      <!--        <ng-template kendoGridFooterTemplate>-->
      <!--          {{ totalToNow }}-->
      <!--        </ng-template>-->
    </kendo-grid-column>

    <kendo-grid-column [sortable]="false" title="Avg/day" class="text-center" headerClass="text-center">
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ (dataItem.phone_calls_inbound_count + dataItem.phone_calls_outbound_count) / days | number : '1.2-2' }}
      </ng-template>
      <!--        <ng-template kendoGridFooterTemplate>-->
      <!--          {{ totalToNow }}-->
      <!--        </ng-template>-->
    </kendo-grid-column>

    <!--      <kendo-grid-column-->
    <!--        title="Total"-->
    <!--      >-->
    <!--        <ng-template kendoGridCellTemplate let-dataItem>-->
    <!--          {{-->
    <!--          dataItem.assigned_sales_count-->
    <!--          }}-->
    <!--        </ng-template>-->
    <!--        <ng-template kendoGridFooterTemplate>-->
    <!--          {{ total }}-->
    <!--        </ng-template>-->
    <!--      </kendo-grid-column>-->
  </kendo-grid>
</div>
