<div class="mb-3">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <h5 class="title-secondary">
        {{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.ASSIGNMENTS_TABLE_TITLE' | translate }}
      </h5>
    </div>
    <div>
      <button type="button" class="btn btn-link" (click)="clearFilters()">
        {{ 'SALES.SALES_LIST.CLEAR_FILTERS' | translate }}
      </button>
    </div>
  </div>

  <kendo-grid
    [data]="data"
    [pageSize]="requestsResponse?.meta.per_page"
    [sortable]="true"
    [filterable]="true"
    [columnMenu]="true"
    [filter]="state?.filter"
    [sort]="state?.sort"
    [skip]="state?.skip"
    [pageable]="true"
    (dataStateChange)="loadData($event)"
    (columnVisibilityChange)="onVisibilityChange($event)"
    #grid
    (excelExport)="onExcelExport($event, 'Sales_assigned_to_you', grid)"
  >
    <kendo-grid-column
      [width]="70"
      class="text-center"
      headerClass="text-center"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.NUMBER' | translate }}"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ rowIndex + 1 }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="id"
      class="text-center"
      headerClass="text-center"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.ID' | translate }}"
      [hidden]="isHiddenColumn('id', true)"
      filter="numeric"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="company.name"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.COMPANY' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('company.name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-company-select [filter]="filter"></app-kendo-company-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/settings/companies/view']" [queryParams]="{ id: dataItem?.company_id }" target="_blank">
          {{ dataItem?.company?.shortName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.name"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.CLIENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('client.name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-client-select [filter]="filter"></app-kendo-client-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-client-box [client]="dataItem.client" [flag]="true"></app-client-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.country.name"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.CLIENT_COUNTRY' | translate }}"
      [hidden]="isHiddenColumn('client.country.name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-country-select [filter]="filter" [field]="'client.country_id'"></app-kendo-country-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-country-box [country]="dataItem?.client?.country" [flag]="false"></app-country-box>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="requests.status.value"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.REQUESTS_STATUS' | translate }}"
      filter="text"
      [sortable]="false"
      [hidden]="isHiddenColumn('requests.status.value', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-types-select
          [groupType]="'sale_request'"
          [filter]="filter"
          [field]="'requests.status_id'"
        ></app-kendo-types-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let status of dataItem?.meta_front?.requestsStatuses; let i = index">
          {{ status }} <span *ngIf="i + 1 !== dataItem?.meta_front?.requestsStatuses.length">,</span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="client.city.timezone_diff"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.CLIENT_TIME' | translate }}"
      [filterable]="false"
      [hidden]="isHiddenColumn('time', false)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <div
          class="active-time-wrapper"
          *ngIf="dataItem.meta_front?.clientTime"
          [ngbTooltip]="'DASHBOARD.DASHBOARD_ASSIGNMENTS.CLIENT_TIME' | translate"
          container="body"
        >
          <i class="fal fa-clock"></i> {{ dataItem.meta_front?.clientTime }}
        </div>
        <div class="active-time-wrapper" *ngIf="dataItem.meta_front?.clientTime === 'false'">
          <i class="fas fa-exclamation-triangle"></i>
          {{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.CLIENT_TIMEZONE_MISSING' | translate }}
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="event.name"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.EVENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('event.name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-event-select [filter]="filter"></app-kendo-event-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem>
        <a [routerLink]="['/events/view']" [queryParams]="{ id: dataItem?.event?.id }" target="_blank">
          {{ dataItem?.event?.formattedName }}
        </a>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="tags"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.TAGS' | translate }}"
      filter="text"
      [sortable]="false"
      [hidden]="isHiddenColumn('tags', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <app-kendo-tag-select [filter]="filter" [model]="LaravelModelsEnum.SALE"></app-kendo-tag-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span *ngFor="let tag of dataItem?.tags; let i = index">
          {{ tag }}<span *ngIf="i !== dataItem?.tags?.length - 1">, </span>
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="sales_agent.fake_first_name"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.SALES_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('sales_agent.fake_first_name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.SALES_AGENT"
          [field]="'sales_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.sales_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="reservation_agent.fake_first_name"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.RESERVATION_AGENT' | translate }}"
      filter="text"
      [hidden]="isHiddenColumn('reservation_agent.fake_first_name', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-user-select
          [filter]="filter"
          [roleType]="UserRoleType.RESERVATION_AGENT"
          [field]="'reservation_agent_id'"
        ></app-kendo-user-select>
      </ng-template>

      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.reservation_agent?.names }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      width="80"
      field="rooms_count"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.REQUESTED_ROOMS_COUNT' | translate }}"
      filter="numeric"
      format="0"
      [hidden]="isHiddenColumn('rooms_count', false)"
    >
    </kendo-grid-column>

    <kendo-grid-column
      field="arrival_request.check_in"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.FIRST_ARRIVAL_DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('аrrival_request.check_in', false)"
    >
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell [column]="column" [filter]="filter" operator="eq"></kendo-grid-date-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.arrival_request?.check_in | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="assign_date"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.ASSIGN_DATE' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('assign_date', false)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.assign_date | date : env.pipeDateFormat }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="updated_at"
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.UPDATED_AT' | translate }}"
      filter="date"
      format="{0:dd/MM/yyyy}"
      [hidden]="isHiddenColumn('updated_at', true)"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        {{ dataItem.updated_at?.getDateTime() }}
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.ACTIONS' | translate }}"
      [width]="100"
      [class]="'text-center'"
      [includeInChooser]="false"
    >
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <app-edit-button (click)="edit(dataItem)"></app-edit-button>
        <app-sale-button [link]="['/sales/view']" [params]="{ id: dataItem.id }"></app-sale-button>
        <app-delete-button [swal]="{}" (confirm)="delete(dataItem)"></app-delete-button>
      </ng-template>
    </kendo-grid-column>

    <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-input></kendo-pager-input>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
      <app-kendo-grid-refresh-button (click)="loadData(state)"></app-kendo-grid-refresh-button>
      <button type="button" class="btn kendo-export-btn" [disabled]="grid.loading" kendoGridExcelCommand>
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </ng-template>

    <kendo-grid-excel></kendo-grid-excel>
  </kendo-grid>

  <small class="d-block">
    <span class="text-danger">*</span>
    {{ 'DASHBOARD.DASHBOARD_ASSIGNMENTS.TIME_SORT_INFO_MSG' | translate }}
  </small>
</div>
