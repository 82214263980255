import { Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { ApiQuery } from '@app/core/http/api-query';
import { KendoGridService } from '@app/shared/kendo/kendo-grid.service';
import { SalesService } from '@app/sales/sales/sales.service';
import { KendoGridComponent } from '@app/shared/kendo/kendo-grid.component';
import { AuthenticationService, Logger } from '@app/core';
import { SalesFormService } from '@app/sales/sales/sales-form.service';
import { Sale } from '@app/sales/sales/sale.model';
import { environment } from '@env/environment';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaLibraryAcceptTypes } from '@app/shared/components/media-library/media-library-accept-types.enum';
import { LaravelModelsEnum } from '@app/shared/models/model.model';
import { CustomKendoFilter } from '@app/shared/kendo/custom-kendo-filter';
import { AccessService } from '@app/core/access/access.service';
import * as moment from 'moment';
import { DataSet } from '@app/shared/kendo/data-set';

const log = new Logger('SalesListComponent');

@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.scss'],
})
export class SalesListComponent extends KendoGridComponent implements OnInit {
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  apiQuery: ApiQuery = new ApiQuery();
  env = environment;
  UserRoleType = UserRoleType;
  isCurrentUserAdministratorOrFinance = false;
  MediaLibraryAcceptTypes = MediaLibraryAcceptTypes;
  LaravelModelsEnum = LaravelModelsEnum;
  loader = false;

  constructor(
    private salesService: SalesService,
    private salesFormService: SalesFormService,
    protected kendoGridService: KendoGridService,
    private auth: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    public accessService: AccessService
  ) {
    super(kendoGridService);
  }

  ngOnInit() {
    // this.loadData();

    this.route.queryParamMap.subscribe((params) => {
      this.state = new DataSet().addSort('created_at', 'desc');

      if (params.get('finals_to_issue_invoice')) {
        this.apiQuery.addFilter('finals_to_issue_invoice', JSON.parse(params.get('finals_to_issue_invoice'))); // parse to bool
      } else {
        const storageFilter = CustomKendoFilter.getStateFromStorage('SalesListComponent');
        if (storageFilter) {
          this.state = storageFilter;
        }
      }

      this.loadData(this.state);
    });
  }

  loadData(state: DataStateChangeEvent = null) {
    log.debug('loadData', state);
    this.loader = true;
    this.state = state;
    this.apiQuery
      .setDataSetFilters(state)
      .addIncludes(
        'company',
        'event',
        'event.event_group',
        'client.country',
        'client.city',
        'client.tags',
        'sales_agent',
        'customer_care_agent',
        'reservation_agent',
        'status',
        'channel',
        'campaign',
        'tags',
        'requests.type',
        'requests.status',
        'assign_to_user',
        'assign_to_role',
        'arrivalRequest',
        'source'
      );

    this.salesService.index(this.apiQuery).subscribe((response: PaginatedResponse) => {
      response.data.forEach((sale: Sale) => {
        sale['meta_front'] = {
          clientTime: null,
          clockInterval: null,
        };

        // За да може да се икспортират данните с пълни имена се прави тази магия тук...
        if (sale.sales_agent && sale.sales_agent.fake_first_name) {
          sale.sales_agent.fake_first_name = sale.sales_agent.names;
        }
        if (sale.reservation_agent && sale.reservation_agent.fake_first_name) {
          sale.reservation_agent.fake_first_name = sale.reservation_agent.names;
        }
        if (sale.customer_care_agent && sale.customer_care_agent.fake_first_name) {
          sale.customer_care_agent.fake_first_name = sale.customer_care_agent.names;
        }

        if (sale.client && sale.client.city && sale.client.city.timezone) {
          clearInterval(sale.meta_front.clockInterval);
          if (sale.client.city.timezone) {
            sale.meta_front.clockInterval = setInterval(() => {
              sale.meta_front.clientTime = moment().tz(sale.client.city.timezone).format('HH:mm:ss');
            }, 1000);
          }
        } else {
          clearInterval(sale.meta_front.clockInterval);
          sale.meta_front.clockInterval = 'false';
        }
      });
      this.requestsResponse = response;
      this.data = response.getGridData();
      this.loader = false;
    });

    CustomKendoFilter.pushStateInStorage('SalesListComponent', this.state);
  }

  add() {
    this.salesFormService.open().then(
      (response) => {
        this.loadData();
      },
      (err) => {
        log.info('modal closed');
      }
    );
  }

  edit(sale: Sale) {
    this.salesFormService
      .open(sale, null, false)
      .then(() => {
        this.loadData(this.state);
      })
      .then(
        (res) => {
          log.debug(res);
          this.loadData(this.state);
        },
        (res) => {}
      );
  }

  delete(sale: Sale) {
    this.salesService.destroy(sale.id).subscribe(() => {
      this.loadData(this.state);
    });
  }

  clearFilters() {
    this.router.navigate(['/sales']);

    this.apiQuery = new ApiQuery();
    this.state = null;
    setTimeout(() => {
      this.loadData(new DataSet().addSort('created_at', 'desc'));
    });
  }
}
