import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeTrackingComponent } from './time-tracking/time-tracking.component';
import { ReportsRoutingModule } from '@app/reports/reports-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ActivityListComponent } from './activity-list/activity-list.component';
import { SharedModule } from '@app/shared';
import { ClosedSalesComponent } from './closed-sales/closed-sales.component';
import { UserAssignmentsComponent } from '@app/reports/user-assignments/user-assignments.component';
import { UserCallsComponent } from '@app/reports/user-calls/user-calls.component';

@NgModule({
  declarations: [
    TimeTrackingComponent,
    ActivityListComponent,
    ClosedSalesComponent,
    UserAssignmentsComponent,
    UserCallsComponent,
  ],
  imports: [CommonModule, ReportsRoutingModule, FullCalendarModule, SharedModule],
})
export class ReportsModule {}
