import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AccessService } from '@app/core/access/access.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  // Вътрешните също трябва да имат changeDetection...
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {}
