import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { ApiQuery } from '@app/core/http/api-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { UsersService } from '@app/settings/users/users.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Logger } from '@app/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { AccessService } from '@app/core/access/access.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import moment from 'moment/moment';
import { environment } from '@env/environment';

const log = new Logger('UserSalesComponent');

@UntilDestroy()
@Component({
  selector: 'app-reports-user-calls-list',
  templateUrl: './user-calls.component.html',
  styleUrls: ['./user-calls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCallsComponent implements OnInit {
  public requestsResponse: PaginatedResponse;
  public data: GridDataResult;
  public state: DataStateChangeEvent;
  public loader = false;
  public form: FormGroup;
  public days = 0;
  protected apiQuery: ApiQuery = new ApiQuery();
  protected filters: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  };

  constructor(
    private usersService: UsersService,
    private router: Router,
    private accessService: AccessService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    const hasAccess = this.accessService.hasOneOfRoles([
      UserRoleType.ADMINISTRATOR,
      UserRoleType.SALES_MANAGER,
      UserRoleType.RESERVATION_MANAGER,
      UserRoleType.LEAD_GENERATION,
      UserRoleType.FINANCE_MANAGER,
      UserRoleType.FINANCE_AGENT,
    ]);

    if (!hasAccess) {
      this.router.navigate(['/']);
      return;
    }

    this.loadData();
  }

  loadData(state: DataStateChangeEvent = null) {
    this.loader = true;
    // this.state = {
    //   ...{
    //     filter: {
    //       filters: [
    //         { field: 'phone_calls_outbound_count.datetime', operator: 'gte', value: this.form.get('start_at').value },
    //         { field: 'phone_calls_outbound_count.datetime', operator: 'lte', value: this.form.get('end_at').value },
    //         { field: 'phone_calls_inbound_count.datetime', operator: 'gte', value: this.form.get('start_at').value },
    //         { field: 'phone_calls_inbound_count.datetime', operator: 'lte', value: this.form.get('end_at').value },
    //       ],
    //       logic: 'and',
    //     },
    //   },
    //   ...state,
    // };

    this.state = state;

    this.days = Math.abs(moment(this.form.get('start_at').value).diff(moment(this.form.get('end_at').value), 'days'));

    // const filter: DataStateChangeEvent = {
    //   // filter: {
    //   //   // filters: [{ field: 'date_from', operator: 'gte', value: moment() }],
    //   //   logic: 'and',
    //   // },
    //   group: undefined,
    //   skip: 0,
    //   sort: [
    //     {
    //       field: 'id',
    //       dir: 'asc'
    //     }
    //   ],
    //   take: 1000
    // };
    //
    // filter.filter = this.filters;

    log.debug('xxxx', this.form.value, this.state);

    this.apiQuery
      .setLimit(999)
      .setDataSetFilters(this.state)
      .addFilter('active', 1)
      .addFilter('employee', 1)
      .addFilter('phone_calls_count_limit_date_from', this.form.get('start_at').value)
      .addFilter('phone_calls_count_limit_date_to', this.form.get('end_at').value)
      // .addFilter('employee', 1)
      .addIncludes('phone_calls_outbound_count', 'phone_calls_inbound_count', 'roles');

    this.usersService
      .index(this.apiQuery)
      .pipe(untilDestroyed(this))
      .subscribe((response: PaginatedResponse) => {
        this.requestsResponse = response;
        this.data = response.getGridData();
        this.loader = false;

        // this.total = 0;
        //
        // response.data.forEach((user: any) => {
        //   //this.total += Number(user.phone_calls_outbound_count)+Н;
        // });

        this.cdr.markForCheck();
      });
  }

  // viewAllUpcomingEvents() {
  //   this.router.navigate(['/events'], {
  //     queryParams: {
  //       dataSet: new DataSet()
  //         .addFilter('date_from', moment().format(this.env.apiPipeDateFormat), 'gte')
  //         .getStringifyDataSet(),
  //     },
  //   });
  // }

  clearFilters() {
    this.router.navigate(['/user-calls']);

    this.apiQuery = new ApiQuery();
    this.state = null;

    this.form.get('start_at').setValue(moment().subtract(1, 'month').format(environment.apiPipeDateFormat));
    this.form.get('end_at').setValue(moment().format(environment.apiPipeDateFormat));

    setTimeout(() => {
      this.loadData();
    });
  }

  private createForm() {
    this.form = this.formBuilder.group({
      start_at: new FormControl(moment().subtract(1, 'month').format(environment.apiPipeDateFormat)),
      end_at: new FormControl(moment().format(environment.apiPipeDateFormat)),
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.loadData());
  }
}
