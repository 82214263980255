<div class="view-with-static-right-sidebar default-scroll" [ngClass]="{ 'sidebar-opened': activitiesService.isOpened }">
  <div class="sale-view-wrapper">
    <app-side-button
      *ngIf="
        !activitiesService.isOpened &&
        saleId &&
        accessService.hasAccess(
          [
            UserRoleType.RESERVATION_AGENT,
            UserRoleType.RESERVATION_MANAGER,
            UserRoleType.CONTRACTING_AGENT,
            UserRoleType.CONTRACTING_MANAGER,
            UserRoleType.SALES_AGENT,
            UserRoleType.SALES_MANAGER,
            UserRoleType.CUSTOMER_CARE_AGENT,
            UserRoleType.CUSTOMER_CARE_MANAGER,
            UserRoleType.FINANCE_AGENT,
            UserRoleType.FINANCE_MANAGER,
            UserRoleType.LEAD_GENERATION,
            UserRoleType.IT,
            UserRoleType.MARKETING,
            UserRoleType.EXTERNAL_PARTNER_LEAD
          ],
          []
        )
      "
      [icon]="'far fa-history'"
      [customClasses]="'btn-activity-floating'"
      (click)="openActivities()"
    ></app-side-button>

    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <div class="page-title">
          {{ 'SALES.SALES_VIEW.TITLE' | translate }} <span *ngIf="saleId">#{{ saleId }}</span>
        </div>

        <div
          class="so-name ml-2 pointer"
          *ngIf="data?.company"
          [routerLink]="['/settings/companies/view']"
          [queryParams]="{ id: data?.company.id }"
          [ngStyle]="data?.company?.color && { color: data?.company?.color, 'border-color': data?.company?.color }"
        >
          {{ data?.company?.shortName }}
        </div>

        <div class="ml-2 mb-2" *ngIf="data?.status">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.STATUS' | translate }}</div>
          <app-type-badge [type]="data?.status"></app-type-badge>
        </div>

        <div class="ml-2 mb-2" *ngIf="data?.source">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.SOURCE' | translate }}</div>
          <app-type-badge [type]="data?.source"></app-type-badge>
        </div>

        <div class="ml-2 mb-2" *ngIf="data?.channel">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.CHANNEL' | translate }}</div>
          <app-type-badge [type]="data?.channel"></app-type-badge>
        </div>

        <div class="ml-2 mb-2" *ngIf="data?.campaign">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.CAMPAIGN' | translate }}</div>
          <app-type-badge [type]="data?.campaign"></app-type-badge>
        </div>

        <div class="ml-2 mb-2">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.FROM_ADVERTISING' | translate }}</div>
          <app-active-indicator [active]="data?.from_advertising"></app-active-indicator>
        </div>

        <div class="ml-2 mb-2" *ngIf="data?.tags?.length > 0">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.TAGS' | translate }}</div>

          <span *ngFor="let tag of data?.tags; let i = index" class="single-tag">
            {{ tag }}<span *ngIf="i !== data?.tags?.length - 1">, </span>
          </span>
        </div>

        <div class="ml-2 mb-2" *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)">
          <div class="super-small-text">
            {{ 'SALES.SALES_VIEW.TOTAL_AMOUNT' | translate }}
          </div>
          {{ data?.total_amount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
        </div>

        <div class="ml-2 mb-2" *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)">
          <div class="super-small-text">
            {{ 'SALES.SALES_VIEW.BALANCE' | translate }}
          </div>
          <span [ngClass]="{ 'text-danger': data?.balance < 0, 'text-success': data?.balance >= 0 }">
            {{ data?.balance | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
          </span>
        </div>

        <div class="ml-2">
          <button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER,
                  UserRoleType.SALES_AGENT,
                  UserRoleType.SALES_MANAGER,
                  UserRoleType.CUSTOMER_CARE_AGENT,
                  UserRoleType.CUSTOMER_CARE_MANAGER,
                  UserRoleType.FINANCE_AGENT,
                  UserRoleType.FINANCE_MANAGER,
                  UserRoleType.LEAD_GENERATION,
                  UserRoleType.IT,
                  UserRoleType.MARKETING
                ],
                ['sales.finals.items.index']
              )
            "
            type="button"
            class="btn btn-sm btn-primary"
            (click)="openClientHistory(data.client_id)"
          >
            <i class="fal fa-history"></i>
            {{ 'SALES.SALES_VIEW.CLIENT_HISTORY' | translate }}
          </button>
        </div>
      </div>

      <div class="d-flex align-items-end">
        <div class="mr-1 mb-1" *ngIf="saleId">
          <app-media-library-button
            *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
            [ngbTooltip]="'SALES.SALES_VIEW.UPLOAD_DOCUMENTS' | translate"
            container="body"
            [icon]="'fal fa-file'"
            [acceptType]="MediaLibraryAcceptTypes.ALL"
            [collectionName]="'documents'"
            [modelId]="saleId"
            [modelType]="LaravelModelsEnum.SALE"
          >
          </app-media-library-button>
        </div>

        <div class="mr-3 mb-1">
          <app-download-vouchers-button
            *ngIf="
              data &&
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER,
                  UserRoleType.SALES_AGENT,
                  UserRoleType.SALES_MANAGER,
                  UserRoleType.CUSTOMER_CARE_AGENT,
                  UserRoleType.CUSTOMER_CARE_MANAGER,
                  UserRoleType.FINANCE_AGENT,
                  UserRoleType.FINANCE_MANAGER,
                  UserRoleType.LEAD_GENERATION
                ],
                ['sales.vouchers.download']
              )
            "
            [type]="'sales'"
            [id]="saleId"
            [companyId]="data.company_id"
          >
          </app-download-vouchers-button>
        </div>

        <div class="assign-to-wrapper">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.ASSIGN_TO' | translate }}</div>
          <app-user-and-group-select [parentFormGroup]="form" (select)="updateSaleAssign()" [selectableGroup]="false">
          </app-user-and-group-select>
        </div>

        <div class="assign-date-wrapper">
          <div class="super-small-text">{{ 'SALES.SALES_VIEW.ASSIGN_DATE' | translate }}</div>
          <app-date-picker
            (select)="updateSaleAssignDate()"
            [FormControl]="form.get('assign_date')"
            [required]="false"
            [minDate]="dateNow"
          ></app-date-picker>
        </div>

        <div class="d-flex align-items-stretch">
          <app-reminder-button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_AGENT,
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_AGENT,
                  UserRoleType.CONTRACTING_MANAGER,
                  UserRoleType.SALES_AGENT,
                  UserRoleType.SALES_MANAGER,
                  UserRoleType.CUSTOMER_CARE_AGENT,
                  UserRoleType.CUSTOMER_CARE_MANAGER,
                  UserRoleType.FINANCE_AGENT,
                  UserRoleType.FINANCE_MANAGER,
                  UserRoleType.LEAD_GENERATION,
                  UserRoleType.IT,
                  UserRoleType.MARKETING
                ],
                []
              )
            "
            modalType="md"
            [reminderableId]="saleId"
            [reminderableType]="LaravelModelsEnum.SALE"
          ></app-reminder-button>

          <button
            *ngIf="
              accessService.hasAccess(
                [
                  UserRoleType.RESERVATION_MANAGER,
                  UserRoleType.CONTRACTING_MANAGER,
                  UserRoleType.SALES_AGENT,
                  UserRoleType.SALES_MANAGER,
                  UserRoleType.CUSTOMER_CARE_AGENT,
                  UserRoleType.CUSTOMER_CARE_MANAGER,
                  UserRoleType.FINANCE_AGENT,
                  UserRoleType.FINANCE_MANAGER,
                  UserRoleType.LEAD_GENERATION
                ],
                ['sales.show']
              )
            "
            class="btn btn-primary ml-2"
            type="button"
            (click)="editSale()"
          >
            {{ 'SALES.SALES_VIEW.EDIT_SALE' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-10">
        <div class="info-wrapper">
          <ng-container *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)">
            <div
              class="active-time-wrapper"
              *ngIf="clientTime"
              [ngbTooltip]="'SALES.SALES_VIEW.CLIENT_TIME' | translate"
              container="body"
            >
              <i class="fal fa-clock"></i> {{ clientTime }}
            </div>

            <div class="active-time-wrapper" *ngIf="clientTime === 'false'">
              <i class="fas fa-exclamation-triangle"></i> {{ 'SALES.SALES_VIEW.CLIENT_TIMEZONE_MISSING' | translate }}
            </div>
          </ng-container>

          <div
            *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
            class="client-collapse-trigger"
            (click)="toggleClientInfo()"
          >
            <button
              [ngbTooltip]="
                clientInfoCollapsed
                  ? ('SALES.SALES_VIEW.SHOW_CLIENT_INFO' | translate)
                  : ('SALES.SALES_VIEW.HIDE_CLIENT_INFO' | translate)
              "
              container="body"
              type="button"
              class="btn btn-ico btn-success"
            >
              <i class="fal fa-chevron-down" *ngIf="clientInfoCollapsed"></i>
              <i class="fal fa-chevron-up" *ngIf="!clientInfoCollapsed"></i>
            </button>
          </div>

          <div
            class="d-flex flex-wrap"
            *ngIf="!clientInfoCollapsed && accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
          >
            <div class="d-flex w-100">
              <a class="client-name" [routerLink]="['/clients/view']" [queryParams]="{ id: data?.client?.id }">
                {{ data?.client?.name }}
                <span
                  *ngIf="data?.client?.country && data?.client?.country?.code"
                  class="flag-icon"
                  [ngClass]="'flag-icon-' + (data?.client?.country?.code | lowercase)"
                ></span>
              </a>

              <app-type-badge [type]="data?.client?.type" class="mr-2"></app-type-badge>

              <app-edit-button
                *ngIf="
                  data?.client &&
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.SALES_AGENT,
                      UserRoleType.SALES_MANAGER,
                      UserRoleType.CUSTOMER_CARE_AGENT,
                      UserRoleType.CUSTOMER_CARE_MANAGER,
                      UserRoleType.LEAD_GENERATION,
                      UserRoleType.FINANCE_AGENT,
                      UserRoleType.FINANCE_MANAGER
                    ],
                    ['clients.show']
                  )
                "
                (click)="editClient(data?.client)"
              >
              </app-edit-button>
            </div>

            <div>
              <div class="d-flex flex-wrap">
                <div class="section">
                  <div class="title" *ngIf="data?.client?.country || data?.client?.city">
                    {{ 'SALES.SALES_VIEW.LOCATION' | translate }}
                  </div>
                  <div class="d-flex" *ngIf="data?.client?.country?.name">
                    <div class="sub-title">
                      {{ 'SALES.SALES_VIEW.COUNTRY' | translate }}
                    </div>
                    <div class="value">
                      {{ data?.client?.country?.name }}
                    </div>
                  </div>

                  <div class="d-flex" *ngIf="data?.client?.city?.name">
                    <div class="sub-title">
                      {{ 'SALES.SALES_VIEW.CITY' | translate }}
                    </div>
                    <div class="value">
                      {{ data?.client?.city?.name }}
                    </div>
                  </div>

                  <div class="title" *ngIf="data?.client?.locales?.length > 0">
                    {{ 'SALES.SALES_VIEW.LANGUAGES_SPOKEN' | translate }}
                  </div>

                  <span class="value ml-0" *ngFor="let locale of data?.client?.locales; let i = index">
                    {{ locale?.name }}<span *ngIf="i !== data?.client?.locales.length - 1">,</span>
                  </span>
                </div>

                <div class="section wide" *ngIf="data?.client?.contacts?.length > 0">
                  <div class="title">{{ 'SALES.SALES_VIEW.CONTACTS' | translate }}</div>

                  <app-contacts-box [contacts]="data?.client?.contacts"></app-contacts-box>
                </div>
              </div>
            </div>

            <div>
              <div *ngIf="data?.client?.contact_persons?.length > 0">
                <div class="title">
                  {{ 'SALES.SALES_VIEW.CONTACT_PERSONS' | translate }}
                </div>

                <div class="d-flex flex-wrap">
                  <ng-container *ngFor="let contactPerson of data?.client?.contact_persons">
                    <div class="section" *ngIf="contactPerson.show">
                      <div class="contact-person-name-wrapper">
                        {{ contactPerson?.title?.value }}
                        <strong class="contact-person-name">
                          {{ contactPerson?.first_name }}
                          {{ contactPerson?.last_name }}
                        </strong>
                        {{ contactPerson?.position }}
                      </div>

                      <div class="d-flex" *ngIf="contactPerson?.event_group?.name">
                        <div class="sub-title">{{ 'SALES.SALES_VIEW.EVENT_GROUP' | translate }}</div>
                        <div class="value">
                          {{ contactPerson?.event_group?.name }}
                        </div>
                      </div>

                      <app-contacts-box [contacts]="contactPerson?.contacts"></app-contacts-box>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-3 mb-1" *ngIf="!clientInfoCollapsed" />
          <div class="row">
            <div class="col-sm-12">
              <div class="title">
                {{ 'SALES.SALES_VIEW.EVENT_DETAILS' | translate }}
              </div>
            </div>
            <div class="col-sm-12">
              <div class="d-flex flex-wrap">
                <div class="section" *ngIf="data?.event">
                  <div class="sub-title">
                    {{ 'SALES.SALES_VIEW.EVENT' | translate }}
                  </div>
                  <a [routerLink]="['/events/view']" [queryParams]="{ id: data?.event?.id }">
                    {{ data?.event?.formattedName }}
                  </a>

                  <app-edit-button
                    *ngIf="
                      accessService.hasAccess([UserRoleType.MARKETING, UserRoleType.LEAD_GENERATION], ['events.show'])
                    "
                    (click)="editEvent(data?.event)"
                  >
                  </app-edit-button>
                </div>
                <div class="section" *ngIf="data?.event?.venue">
                  <div class="sub-title">
                    {{ 'SALES.SALES_VIEW.VENUE' | translate }}
                  </div>
                  {{ data?.event?.venue?.name }}

                  <app-edit-button
                    *ngIf="accessService.hasAccess([UserRoleType.LEAD_GENERATION], ['venues.show'])"
                    (click)="editVenue(data?.event?.venue)"
                  >
                  </app-edit-button>
                </div>
                <div class="section" *ngIf="data?.event?.date_from">
                  <div class="sub-title">
                    {{ 'SALES.SALES_VIEW.FROM' | translate }}
                  </div>
                  {{ data?.event?.date_from | date : env.pipeDateFormat }}
                </div>
                <div class="section" *ngIf="data?.event?.date_to">
                  <div class="sub-title">
                    {{ 'SALES.SALES_VIEW.TO' | translate }}
                  </div>
                  {{ data?.event?.date_to | date : env.pipeDateFormat }}
                </div>
                <div class="section" *ngIf="data?.event?.venue?.city?.country?.name">
                  <div class="sub-title">
                    {{ 'SALES.SALES_VIEW.COUNTRY' | translate }}
                  </div>
                  {{ data?.event?.venue?.city?.country?.name }}
                </div>
                <div class="section" *ngIf="data?.event?.venue?.city?.name">
                  <div class="sub-title">
                    {{ 'SALES.SALES_VIEW.CITY' | translate }}
                  </div>
                  {{ data?.event?.venue?.city?.name }}
                </div>
              </div>
            </div>
          </div>

          <hr class="mt-3 mb-2" />

          <form id="sale-view-form" [formGroup]="form" novalidate *ngIf="form">
            <div class="d-flex justify-content-between">
              <div class="d-flex w-33 align-items-center">
                <div class="mb-2 mr-2 text-gray">
                  <i class="far fa-id-card"></i>
                  {{ 'SALES.SALES_VIEW.SALES_AGENT_AND_SALES_MANAGER' | translate }}
                </div>

                <app-user-select
                  class="w-50"
                  [parentFormGroup]="form"
                  [passFormControlName]="'sales_agent_id'"
                  [hideLabel]="true"
                  [roleType]="[UserRoleType.SALES_AGENT, UserRoleType.SALES_MANAGER]"
                  [includes]="'contacts'"
                ></app-user-select>

                <div>
                  <div *ngFor="let contact of form.get('sales_agent')?.value?.contacts" class="d-inline-block">
                    <div *ngIf="contact?.type_id == ContactTypes.EXTENSION" class="ml-2 mb-2 mr-2">
                      <i class="fas fa-phone-volume"></i> {{ contact?.value }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex w-33 align-items-center">
                <div class="mb-2 mr-2 text-gray">
                  <i class="far fa-clipboard-user"></i>
                  {{ 'SALES.SALES_VIEW.RESERVATION_AGENT_AND_RESERVATION_MANAGER' | translate }}
                </div>

                <app-user-select
                  class="w-50"
                  [parentFormGroup]="form"
                  [passFormControlName]="'reservation_agent_id'"
                  [hideLabel]="true"
                  [roleType]="[UserRoleType.RESERVATION_AGENT, UserRoleType.RESERVATION_MANAGER]"
                  [includes]="'contacts'"
                ></app-user-select>

                <div>
                  <div *ngFor="let contact of form.get('reservation_agent')?.value?.contacts" class="d-inline-block">
                    <div *ngIf="contact?.type_id == ContactTypes.EXTENSION" class="ml-2 mb-2 mr-2">
                      <i class="fas fa-phone-volume"></i> {{ contact?.value }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex w-33 align-items-center">
                <div class="mb-2 mr-2 text-gray">
                  <i class="far fa-clipboard-user"></i>
                  {{ 'SALES.SALES_VIEW.CUSTOMER_CARE_TEAM' | translate }}
                </div>

                <app-user-select
                  class="w-50"
                  [parentFormGroup]="form"
                  [passFormControlName]="'customer_care_agent_id'"
                  [hideLabel]="true"
                  [roleType]="UserRoleType.CUSTOMER_CARE_AGENT"
                  [includes]="'contacts'"
                ></app-user-select>

                <div>
                  <div *ngFor="let contact of form.get('customer_care_agent')?.value?.contacts" class="d-inline-block">
                    <div *ngIf="contact?.type_id == ContactTypes.EXTENSION" class="ml-2 mb-2 mr-2">
                      <i class="fas fa-phone-volume"></i> {{ contact?.value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-sm-2 d-flex flex-column">
        <div class="note-title mb-2">{{ 'SALES.SALES_VIEW.NOTE' | translate }} <i class="fal fa-sticky-note"></i></div>
        <div class="sale-note-wrapper flex-grow-1">
          <textarea
            *ngIf="data"
            class="sale-note-textarea"
            pInputTextarea
            [(ngModel)]="data.note"
            [ngModelOptions]="{ standalone: true }"
          >
          </textarea>

          <button
            *ngIf="data && accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)"
            (click)="updateSaleNote()"
            type="button"
            class="btn btn-note-update"
          >
            {{ 'SALES.SALES_VIEW.NOTE_UPDATE' | translate }}
          </button>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between mt-3 align-items-end">
      <div class="title2">
        {{ 'SALES.SALES_VIEW.CLIENT_REQUESTS' | translate }}

        <span class="btn btn-link enquiry-btn" *ngIf="data?.enquiry?.id" (click)="editEnquiry(data?.enquiry)">
          #{{ data?.enquiry?.id }}
        </span>

        <span class="btn btn-link btn-sm" *ngIf="data?.requests.length > 0" (click)="requestsGridexportToExcel()">
          {{ 'EXPORT_TO_EXCEL' | translate }}
        </span>
      </div>

      <div class="d-flex align-items-end">
        <div
          class="d-flex align-items-end mr-3"
          *ngIf="
            data?.requests.length > 0 &&
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              []
            )
          "
        >
          <div class="requests-mass-status-holder">
            <small>{{ 'SALES.SALES_VIEW.MASS_STATUS' | translate }}</small>
            <app-type-select
              [(model)]="requestsMassStatusId"
              [clearable]="false"
              [selectFirst]="true"
              [groupType]="'sale_request'"
            ></app-type-select>
          </div>

          <div>
            <button
              type="button"
              class="btn btn-success btn-sm"
              [swal]="{ titleText: 'You are about to set status of all requests.' }"
              (confirm)="setRequestsStatus()"
            >
              <i class="fal fa-level-down"></i>
            </button>
          </div>
        </div>

        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER,
                UserRoleType.FINANCE_AGENT,
                UserRoleType.FINANCE_MANAGER,
                UserRoleType.LEAD_GENERATION
              ],
              []
            )
          "
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="editRequest()"
        >
          <ng-container *ngIf="data?.requests.length <= 0">
            {{ 'SALES.SALES_VIEW.ADD_REQUEST' | translate }}
          </ng-container>

          <ng-container *ngIf="data?.requests.length > 0">
            {{ 'SALES.SALES_VIEW.EDIT_REQUEST' | translate }}
          </ng-container>
        </button>
      </div>
    </div>

    <div class="no-data-msg-wrapper" *ngIf="data?.requests?.length <= 0">
      {{ 'SALES.SALES_VIEW.NO_REQUESTS_MSG' | translate }}
    </div>

    <kendo-grid
      #requestsGrid="kendoGrid"
      style="max-height: 400px"
      [data]="data?.requests"
      *ngIf="data?.requests.length > 0"
      (excelExport)="onExcelExport($event, 'Clients_requests', requestsGrid)"
    >
      <kendo-grid-column
        [width]="60"
        class="text-center"
        headerClass="text-center"
        title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ rowIndex + 1 }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="160"
        class="text-center"
        headerClass="text-center"
        field="status.value"
        title="{{ 'SALES.SALES_VIEW.STATUS' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <app-type-select
            [(model)]="dataItem.status_id"
            [clearable]="false"
            [groupType]="'sale_request'"
            (modelChange)="updateRequests()"
          ></app-type-select>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="80"
        class="text-center"
        headerClass="text-center"
        field="check_in"
        title="{{ 'SALES.SALES_VIEW.CHECK_IN' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem?.check_in | date : env.pipeDateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        field="nights"
        [width]="30"
        class="text-center"
        footerClass="font-weight-bold text-center"
        headerClass="text-center"
        title="{{ 'SALES.SALES_VIEW.NIGHTS' | translate }}"
      >
        <ng-template kendoGridHeaderTemplate>
          <i class="fas fa-moon nights-ico"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem.check_out | amDifference : dataItem.check_in : 'days' : true }}
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          {{ requestsTotal.nights_count }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="80"
        class="text-center"
        headerClass="text-center"
        field="check_out"
        title="{{ 'SALES.SALES_VIEW.CHECK_OUT' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem?.check_out | date : env.pipeDateFormat }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="30"
        class="text-center"
        headerClass="text-center"
        field="people"
        title="{{ 'SALES.SALES_VIEW.PEOPLE' | translate }}"
      >
        <ng-template kendoGridHeaderTemplate>
          <i class="fas fa-user"></i>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          {{ dataItem?.people * dataItem.count }}
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="70"
        class="text-center"
        headerClass="text-center"
        field="count"
        title="{{ 'SALES.SALES_VIEW.ROOM_COUNT' | translate }}"
      ></kendo-grid-column>

      <kendo-grid-column
        [width]="70"
        class="text-center"
        headerClass="text-center"
        field="type.value"
        title="{{ 'SALES.SALES_VIEW.TYPE' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <app-type-badge [type]="dataItem.type"></app-type-badge>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        [width]="50"
        class="text-center"
        headerClass="text-center"
        field="meal_plan.value"
        title="{{ 'SALES.SALES_VIEW.MEAL_PLAN_SHORT' | translate }}"
      ></kendo-grid-column>

      <kendo-grid-column
        class="text-center"
        headerClass="text-center"
        title="{{ 'SALES.SALES_VIEW.HOTEL' | translate }}"
        field="hotel.name"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <a [routerLink]="['/hotels/view']" [queryParams]="{ id: dataItem?.hotel?.id }">
            {{ dataItem?.hotel?.name }}
          </a>
          <span *ngIf="dataItem.hotel_stars">
            {{ dataItem.hotel_stars }} <span class="star-ico-wrapper"><i class="fas fa-star"></i></span>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        class="text-center"
        headerClass="text-center"
        field="budget"
        title="{{ 'SALES.SALES_VIEW.BUDGET' | translate }}"
      >
      </kendo-grid-column>

      <kendo-grid-column
        class="text-center"
        headerClass="text-center"
        field="guest_names"
        title="{{ 'SALES.SALES_VIEW.GUEST_NAMES' | translate }}"
      >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
          <span *ngFor="let guestName of dataItem?.guest_names; let i = index">
            <ng-container *ngIf="guestName">
              {{ guestName }}<span *ngIf="i !== dataItem?.guest_names?.length - 1">, </span>
            </ng-container>
          </span>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
        class="text-center"
        headerClass="text-center"
        field="note"
        title="{{ 'SALES.SALES_VIEW.NOTE' | translate }}"
      >
      </kendo-grid-column>

      <kendo-grid-excel></kendo-grid-excel>
    </kendo-grid>

    <div class="d-flex justify-content-between align-items-center mt-3">
      <div class="title2">
        {{ 'SALES.SALES_VIEW.OFFERS' | translate }}
        <span class="offers-count" *ngIf="data?.offers?.length > 0"> ({{ data.offers.length }}) </span>

        <button
          (click)="toggleAllOffers()"
          type="button"
          class="btn btn-ico btn-success"
          *ngIf="data?.offers?.length > 0"
        >
          <i class="fal fa-chevron-down" *ngIf="allOffersCollapsed"></i>
          <i class="fal fa-chevron-up" *ngIf="!allOffersCollapsed"></i>
        </button>

        <button
          [swal]="{
            titleText: 'SALES.SALES_VIEW.CONFIRM_OFFERS_SORT_TITLE' | translate,
            text: 'SALES.SALES_VIEW.CONFIRM_OFFERS_SORT_TEXT' | translate
          }"
          (confirm)="sortOffers('desc')"
          type="button"
          class="btn btn-ico btn-warning"
          *ngIf="data?.offers?.length > 1"
        >
          <i class="fa fa-sort-amount-desc font-weight-light" aria-hidden="true"></i>
        </button>

        <button
          [swal]="{
            titleText: 'SALES.SALES_VIEW.CONFIRM_OFFERS_SORT_TITLE' | translate,
            text: 'SALES.SALES_VIEW.CONFIRM_OFFERS_SORT_TEXT' | translate
          }"
          (confirm)="sortOffers('asc')"
          type="button"
          class="btn btn-ico btn-warning"
          *ngIf="data?.offers?.length > 1"
        >
          <i class="fa fa-sort-amount-asc font-weight-light" aria-hidden="true"></i>
        </button>
      </div>

      <div *ngIf="data && data.offers.length !== offersMeta.hiddenCount" class="d-flex">
        <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="offersMeta.showAll">
          <label ngbButtonLabel class="btn-primary btn-sm">
            <input ngbButton type="radio" [value]="false" /> Hide({{ offersMeta.hiddenCount }})
          </label>
          <label ngbButtonLabel class="btn-primary btn-sm">
            <input ngbButton type="radio" [value]="true" /> Show all
          </label>
        </div>
      </div>

      <div>
        <app-email-templates-button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER
              ],
              []
            )
          "
          [saleId]="saleId"
        >
        </app-email-templates-button>

        <button
          *ngIf="
            accessService.hasAccess(
              [
                UserRoleType.RESERVATION_AGENT,
                UserRoleType.RESERVATION_MANAGER,
                UserRoleType.CONTRACTING_AGENT,
                UserRoleType.CONTRACTING_MANAGER,
                UserRoleType.SALES_AGENT,
                UserRoleType.SALES_MANAGER,
                UserRoleType.CUSTOMER_CARE_AGENT,
                UserRoleType.CUSTOMER_CARE_MANAGER
              ],
              []
            )
          "
          type="button"
          class="btn btn-sm btn-secondary"
          (click)="addOffer()"
        >
          {{ 'SALES.SALES_VIEW.ADD_OFFER' | translate }}
        </button>
      </div>
    </div>

    <div class="no-data-msg-wrapper" *ngIf="data?.offers?.length <= 0">
      {{ 'SALES.SALES_VIEW.NO_OFFERS_MSG' | translate }}
    </div>

    <div
      *ngIf="data?.offers?.length > 0"
      dndDropzone
      dndEffectAllowed="copyMove"
      (dndDrop)="onDrop($event, data.offers)"
    >
      <div dndPlaceholderRef class="draggable-placeholder"></div>

      <ng-container *ngFor="let offer of data.offers">
        <div
          class="offer-wrapper d-none"
          [class.d-block]="offersMeta.showAll || offer.final || data.offers.length === offersMeta.hiddenCount"
          [dndDraggable]="offer"
          [dndEffectAllowed]="'copyMove'"
          (dndMoved)="onDragged(offer)"
          [dndDisableIf]="offerDragDisabled"
        >
          <div class="offer-head d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="check-box-wrapper">
                <input type="checkbox" />
              </div>

              <div class="offer-name">
                {{ offer.name }}
              </div>

              <div class="collapse-trigger" (click)="toggleOffer(offer)">
                <button type="button" class="btn btn-ico btn-success">
                  <i class="fal fa-chevron-down" *ngIf="offer.meta_front?.collapsed"></i>
                  <i class="fal fa-chevron-up" *ngIf="!offer.meta_front?.collapsed"></i>
                </button>
              </div>

              <div class="draggable-trigger">
                <button
                  dndHandle
                  type="button"
                  class="btn btn-ico btn-success"
                  (mousedown)="enableOffersDrag()"
                  (mouseout)="disableOffersDrag()"
                >
                  <i class="fal fa-expand-arrows"></i>
                </button>
              </div>
            </div>

            <div class="d-flex align-items-end">
              <div class="online-booking-terms-btn-wrapper mr-2">
                <button
                  type="button"
                  class="btn btn-sm btn-info btn-online-booking-terms"
                  (click)="onlineBookingTermsEdit(offer.id)"
                >
                  <ng-container *ngIf="!offer.sale_online_booking_terms">
                    {{ 'SALES.SALES_VIEW.UPLOAD_ONLINE_BOOKING_TERMS' | translate }}
                  </ng-container>

                  <ng-container *ngIf="offer.sale_online_booking_terms">
                    {{ 'SALES.SALES_VIEW.CHANGE_ONLINE_BOOKING_TERMS' | translate }}
                  </ng-container>
                </button>

                <a
                  class="btn btn-sm btn-info btn-addon"
                  target="_blank"
                  [routerLink]="['/sales/view']"
                  [queryParams]="{ id: saleId, onlineBookingTerms: offer.id }"
                >
                  <i class="fa fa-external-link" aria-hidden="true"></i>
                </a>
              </div>

              <div *ngIf="offer?.client_comment_type?.value" class="mr-3">
                <small>{{ 'SALES.SALES_VIEW.CLIENT_COMMENT_TYPE' | translate }}</small>
                <div>
                  <app-type-badge [type]="offer?.client_comment_type"></app-type-badge>
                </div>
              </div>

              <div class="offer-comment-holder mr-3" *ngIf="offer?.client_comment">
                <small>{{ 'SALES.SALES_VIEW.CLIENT_COMMENT' | translate }}</small>
                <div class="offer-comment" [ngbTooltip]="offer?.client_comment" container="body">
                  {{ offer?.client_comment }}
                </div>
              </div>

              <div
                *ngIf="
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.SALES_AGENT,
                      UserRoleType.SALES_MANAGER,
                      UserRoleType.CUSTOMER_CARE_AGENT,
                      UserRoleType.CUSTOMER_CARE_MANAGER,
                      UserRoleType.FINANCE_AGENT,
                      UserRoleType.FINANCE_MANAGER
                    ],
                    []
                  )
                "
                class="custom-control custom-checkbox mr-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="offer.meta_front.form.completed"
                  [attr.id]="'permission' + '-' + offer.id"
                  (change)="saleOfferChange(offer.meta_front.form)"
                />
                <label class="custom-control-label" [attr.for]="'permission' + '-' + offer.id">
                  {{ 'SALES.SALES_FORM.OFFER_COMPLETED' | translate }}
                </label>
              </div>

              <div
                class="offer-terms-holder mr-2"
                *ngIf="
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.SALES_AGENT,
                      UserRoleType.SALES_MANAGER,
                      UserRoleType.CUSTOMER_CARE_AGENT,
                      UserRoleType.CUSTOMER_CARE_MANAGER,
                      UserRoleType.FINANCE_AGENT,
                      UserRoleType.FINANCE_MANAGER
                    ],
                    []
                  )
                "
              >
                <div class="d-flex justify-content-between">
                  <small>{{ 'SALES.SALES_OFFERS_FORM.CANCELLATION_TERMS' | translate }}</small>

                  <small class="pointer btn-link" (click)="saleOfferChange(offer.meta_front.form)">
                    {{ 'SALES.SALES_OFFERS_FORM.UPDATE' | translate }}
                  </small>
                </div>
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="offer.meta_front.form.cancellation_terms"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>

              <button
                type="button"
                class="btn btn-sm btn-warning mr-2"
                (click)="addFinalSale(offer)"
                *ngIf="
                  !offer?.final &&
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.FINANCE_MANAGER
                    ],
                    []
                  )
                "
              >
                {{ 'SALES.SALES_VIEW.ADD_FINAL_SALE' | translate }}
              </button>

              <button
                type="button"
                class="btn btn-sm btn-warning mr-2"
                (click)="editFinalSale(offer?.final)"
                *ngIf="
                  offer?.final &&
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.FINANCE_MANAGER
                    ],
                    []
                  )
                "
              >
                {{ 'SALES.SALES_VIEW.EDIT_FINAL_SALE' | translate }}
              </button>

              <button
                *ngIf="
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.SALES_AGENT,
                      UserRoleType.SALES_MANAGER,
                      UserRoleType.CUSTOMER_CARE_AGENT,
                      UserRoleType.CUSTOMER_CARE_MANAGER
                    ],
                    []
                  )
                "
                type="button"
                class="btn btn-sm btn-secondary mr-2"
                (click)="editOffer(offer)"
              >
                {{ 'SALES.SALES_VIEW.EDIT_OFFER' | translate }}
              </button>

              <button
                *ngIf="
                  accessService.hasAccess(
                    [
                      UserRoleType.SALES_AGENT,
                      UserRoleType.SALES_MANAGER,
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.CUSTOMER_CARE_AGENT,
                      UserRoleType.CUSTOMER_CARE_MANAGER
                    ],
                    []
                  )
                "
                type="button"
                class="btn btn-sm btn-danger"
                [swal]="{}"
                (confirm)="deleteOffer(offer.id)"
              >
                {{ 'SALES.SALES_VIEW.DELETE_OFFER' | translate }}
              </button>
            </div>
          </div>

          <div class="offer-body" *ngIf="!offer.meta_front?.collapsed">
            <kendo-grid
              style="max-height: 400px"
              [columnMenu]="true"
              [data]="offer.items"
              (columnVisibilityChange)="onVisibilityChange($event, 'offerTable')"
              [selectable]="selectableSettings"
              [kendoGridSelectBy]="'id'"
              [selectedKeys]="offer.meta_front.selectedOffersLines"
              (selectionChange)="offersSelectionChange(offer)"
              #offersGrid
              (excelExport)="onExcelExport($event, 'Offers', offersGrid, 2)"
            >
              <kendo-grid-checkbox-column
                [width]="32"
                [columnMenu]="false"
                showSelectAll="true"
                class="text-center"
                headerClass="text-center"
              >
              </kendo-grid-checkbox-column>

              <kendo-grid-column
                [width]="60"
                class="text-center"
                headerClass="text-center"
                footerClass="font-weight-bold "
                title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
                [hidden]="isHiddenColumn('offer.number', false, 'offerTable')"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ rowIndex + 1 }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  {{ 'SALES.SALES_VIEW.TOTALS' | translate }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="hotel.name"
                title="{{ 'SALES.SALES_VIEW.HOTEL' | translate }}"
                [hidden]="isHiddenColumn('offer.hotel.name', false, 'offerTable')"
                [columnMenu]="false"
                [width]="202"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div
                    class="d-inline-block text-truncate font-weight-bold"
                    style="max-width: 200px"
                    [ngbTooltip]="dataItem?.hotel?.name + '(' + dataItem?.hotel?.stars + ')'"
                    container="body"
                  >
                    <a [routerLink]="['/hotels/view']" [queryParams]="{ id: dataItem?.hotel?.id }">
                      {{ dataItem?.hotel?.name }}
                    </a>
                    <span *ngIf="dataItem.hotel?.stars">
                      {{ dataItem.hotel.stars }} <span class="star-ico-wrapper"><i class="fas fa-star"></i></span>
                    </span>
                  </div>
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  <button
                    type="button"
                    class="btn kendo-export-btn"
                    [disabled]="offersGrid.loading"
                    kendoGridExcelCommand
                  >
                    {{ 'EXPORT_TO_EXCEL' | translate }}
                  </button>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="allotment.booking.note"
                title="{{ 'SALES.SALES_VIEW.BOOKING_NOTE' | translate }}"
                [hidden]="isHiddenColumn('allotment.booking.note', false, 'offerTable')"
                [columnMenu]="false"
                [width]="30"
              >
                <ng-template kendoGridHeaderTemplate>
                  <i class="fas fa-sticky-note"></i>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div *ngIf="dataItem?.allotment?.booking?.note" class="d-inline-block">
                    <ng-template #tipContent>
                      <div [innerHTML]="dataItem?.allotment?.booking?.note"></div>
                    </ng-template>
                    <span tooltipClass="with-html" [ngbTooltip]="tipContent" container="body">
                      <i class="fal fa-sticky-note"></i>
                    </span>
                  </div>

                  <a
                    target="_blank"
                    *ngIf="dataItem?.allotment?.booking"
                    [routerLink]="'/hotel-bookings/view'"
                    [queryParams]="{ id: dataItem?.allotment?.booking?.id }"
                    class="btn btn-primary btn-ico"
                    [ngbTooltip]="'SALES.SALES_VIEW.PREVIEW_BOOKING' | translate"
                    container="body"
                  >
                    <i class="fal fa-external-link-alt"></i>
                  </a>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="supplier.name"
                title="{{ 'SALES.SALES_VIEW.SUPPLIER' | translate }}"
                [hidden]="isHiddenColumn('offer.supplier.name', false, 'offerTable')"
                [columnMenu]="false"
                [width]="150"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div
                    class="d-inline-block text-truncate"
                    style="width: 150px"
                    [ngbTooltip]="dataItem?.supplier?.name"
                    container="body"
                  >
                    {{ dataItem.supplier?.name }}
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="allotment.booking.cancellation_deadline"
                title="{{ 'SALES.SALES_VIEW.CANCELLATION_DEADLINE' | translate }}"
                [hidden]="isHiddenColumn('allotment.booking.cancellation_deadline', false, 'offerTable')"
                [columnMenu]="false"
                [width]="90"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.allotment?.booking?.cancellation_deadline | date : env.pipeDateFormat }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="allotment.booking.property.value"
                title="{{ 'SALES.SALES_VIEW.BOOKING_STATUS' | translate }}"
                [hidden]="isHiddenColumn('offer.allotment.booking.property', false, 'offerTable')"
                [columnMenu]="false"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-type-badge
                    [type]="dataItem?.allotment?.booking?.property"
                    style="width: 50px"
                    [ngbTooltip]="dataItem?.allotment?.booking?.property?.value"
                    container="body"
                  ></app-type-badge>

                  <!--                  <div class="badge badge-dark" *ngIf="dataItem?.meta_front?.cancellationsCount">-->
                  <!--                    {{ 'SALES.SALES_VIEW.CANCELLATION_FINISHED' | translate }}-->
                  <!--                  </div>-->

                  <!--                  <div-->
                  <!--                    class="badge badge-dark"-->
                  <!--                    *ngIf="-->
                  <!--                      dataItem?.meta_front?.cancellationsCount && !dataItem?.meta_front?.finishedCancellationsCount-->
                  <!--                    "-->
                  <!--                  >-->
                  <!--                    {{ 'SALES.SALES_VIEW.CANCELLATION_IN_PROGRESS' | translate }}-->
                  <!--                  </div>-->
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="ref_number"
                title="{{ 'SALES.SALES_VIEW.REF_NUMBER_SHORT' | translate }}"
                [hidden]="isHiddenColumn('ref_number', false, 'offerTable')"
                [columnMenu]="false"
                [width]="110"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <div
                    class="d-inline-block text-truncate"
                    style="width: 110px"
                    [ngbTooltip]="dataItem?.ref_number"
                    container="body"
                  >
                    {{ dataItem.ref_number }}
                  </div>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center pl-0 pr-0"
                field="check_in"
                title="{{ 'SALES.SALES_VIEW.CHECK_IN' | translate }}"
                [hidden]="isHiddenColumn('offer.check_in', false, 'offerTable')"
                [columnMenu]="false"
                [width]="88"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem?.check_in | date : env.pipeDateFormat }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                footerClass="text-center font-weight-bold"
                title="{{ 'SALES.SALES_VIEW.NIGHTS' | translate }}"
                field="nights"
                [hidden]="isHiddenColumn('offer.nights', false, 'offerTable')"
                [columnMenu]="false"
                [width]="30"
              >
                <ng-template kendoGridHeaderTemplate>
                  <i class="fas fa-moon nights-ico"></i>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.check_out | amDifference : dataItem.check_in : 'days' : true }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  <!--  {{ offer?.meta_front?.selectedOffersLinesTotals?.net_price_eur || offer?.meta_front?.totals?.net_price_eur }} {{ 'DEFAULT_CURRENCY' | translate }}-->

                  {{
                    offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.nights_count
                      : offer?.meta_front?.totals?.nights_count
                  }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center pl-0 pr-0"
                field="check_out"
                title="{{ 'SALES.SALES_VIEW.CHECK_OUT' | translate }}"
                [hidden]="isHiddenColumn('offer.check_out', false, 'offerTable')"
                [columnMenu]="false"
                [width]="88"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem?.check_out | date : env.pipeDateFormat }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="type.value"
                title="{{ 'SALES.SALES_VIEW.TYPE' | translate }}"
                [hidden]="isHiddenColumn('offer.type.value', false, 'offerTable')"
                [columnMenu]="false"
                [width]="50"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-type-badge
                    [type]="dataItem.type"
                    style="width: 50px"
                    [ngbTooltip]="dataItem?.type?.value"
                    container="body"
                  ></app-type-badge>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center pl-0 pr-0"
                footerClass="text-center font-weight-bold "
                field="count"
                title="{{ 'SALES.SALES_VIEW.ROOM_COUNT' | translate }}"
                [hidden]="isHiddenColumn('offer.count', false, 'offerTable')"
                [columnMenu]="false"
                [width]="60"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.count }}

                  <span
                    [ngClass]="
                      dataItem?.count <= dataItem?.allotment?.nights[0]?.room?.available_rooms
                        ? 'text-success'
                        : 'text-danger'
                    "
                    *ngIf="dataItem?.allotment?.nights[0]?.room"
                    [ngbTooltip]="'SALES.SALES_VIEW.AVAILABLE_ROOMS' | translate"
                    container="body"
                    (click)="openClientFinder(dataItem)"
                    class="pointer"
                  >
                    /
                    {{ dataItem.allotment.nights[0].room?.available_rooms }}
                    {{ 'SALES.SALES_VIEW.AVAILABLE_SHORT' | translate }}
                  </span>
                </ng-template>
                <ng-template kendoGridFooterTemplate>
                  {{
                    offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.rooms_count
                      : offer?.meta_front?.totals?.rooms_count
                  }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center pl-0 pr-0"
                footerClass="text-center font-weight-bold"
                field="people"
                title="{{ 'SALES.SALES_VIEW.PEOPLE' | translate }}"
                [hidden]="isHiddenColumn('offer.people', false, 'offerTable')"
                [columnMenu]="false"
                [width]="30"
              >
                <ng-template kendoGridHeaderTemplate>
                  <i class="fas fa-user"></i>
                </ng-template>

                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.people * dataItem.count }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  {{
                    offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.people
                      : offer?.meta_front?.totals?.people
                  }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                field="meal_plan.value"
                title="{{ 'SALES.SALES_VIEW.MEAL_PLAN_SHORT' | translate }}"
                [hidden]="isHiddenColumn('offer.meal_plan.value', false, 'offerTable')"
                [width]="40"
                [columnMenu]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-type-badge [type]="dataItem.meal_plan"></app-type-badge>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                footerClass="text-center font-weight-bold "
                title="{{ 'SALES.SALES_VIEW.CLIENT_PRICE' | translate }}"
                field="price_eur"
                [hidden]="isHiddenColumn('offer.price', false, 'offerTable')"
                [width]="80"
                [columnMenu]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.price | number : '1.2-2' }}
                  {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  <!--  {{ offer?.meta_front?.selectedOffersLinesTotals?.price || offer?.meta_front?.totals?.price | number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}-->

                  {{
                    (offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.total_price
                      : offer?.meta_front?.totals?.total_price
                    ) | number : '1.2-2'
                  }}
                  {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                footerClass="text-center font-weight-bold "
                title="{{ 'SALES.SALES_VIEW.NET_PRICE' | translate }}"
                field="net_price_eur"
                [hidden]="isHiddenColumn('offer.net_price', false, 'offerTable')"
                [width]="80"
                [columnMenu]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem?.net_price | number : '1.2-2' }}
                  <app-currency-box [currency]="dataItem?.net_price_currency"></app-currency-box>

                  <app-currency-converter
                    class="d-block"
                    [fromCurrency]="dataItem?.net_price_currency"
                    [value]="dataItem?.net_price"
                    [exchangeRate]="dataItem?.exchange_rate"
                  >
                  </app-currency-converter>
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  <!--  {{ offer?.meta_front?.selectedOffersLinesTotals?.net_price_eur || offer?.meta_front?.totals?.net_price_eur }} {{ 'DEFAULT_CURRENCY' | translate }}-->

                  {{
                    (offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.total_net_price_eur
                      : offer?.meta_front?.totals?.total_net_price_eur
                    ) | number : '1.2-2'
                  }}
                  {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                footerClass="text-center font-weight-bold "
                title="{{ 'SALES.SALES_VIEW.CLIENT_MARGIN' | translate }}"
                field="margin"
                [hidden]="isHiddenColumn('offer.margin', false, 'offerTable')"
                [width]="80"
                [columnMenu]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{
                    dataItem.price - CurrenciesAdditionsService.toEUR(dataItem.net_price, dataItem.net_price_currency)
                      | number : '1.2-2'
                  }}
                  {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  <!--   {{ offer?.meta_front?.selectedOffersLinesTotals?.client_margin || offer?.meta_front?.totals?.client_margin| number: '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}-->

                  {{
                    (offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.total_client_margin
                      : offer?.meta_front?.totals?.total_client_margin
                    ) | number : '1.2-2'
                  }}
                  {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                footerClass="text-center font-weight-bold "
                field="client_penalty"
                title="{{ 'SALES.SALES_VIEW.CLIENT_PENALTY_CLEAN' | translate }}"
                [hidden]="isHiddenColumn('offer.client_penalty', true, 'offerTable')"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.client_penalty | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  {{
                    (offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.client_penalty
                      : offer?.meta_front?.totals?.client_penalty
                    ) | number : '1.2-2'
                  }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                class="text-center"
                headerClass="text-center"
                footerClass="text-center font-weight-bold "
                field="supplier_penalty"
                title="{{ 'SALES.SALES_VIEW.SUPPLIER_PENALTY_CLEAN' | translate }}"
                [hidden]="isHiddenColumn('offer.supplier_penalty', true, 'offerTable')"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.supplier_penalty | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
                </ng-template>

                <ng-template kendoGridFooterTemplate>
                  {{
                    (offer?.meta_front?.selectedOffersLines?.length > 0
                      ? offer?.meta_front?.selectedOffersLinesTotals?.supplier_penalty
                      : offer?.meta_front?.totals?.supplier_penalty
                    ) | number : '1.2-2'
                  }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-excel></kendo-grid-excel>
            </kendo-grid>
          </div>
        </div>
      </ng-container>
    </div>

    <div *ngIf="accessService.hasAccess([UserRoleType.EXTERNAL_PARTNER_LEAD], [], true)">
      <div class="title2">
        {{ 'SALES.SALES_VIEW.FINALS' | translate }}
      </div>

      <div class="no-data-msg-wrapper" *ngIf="data?.finals?.length <= 0">
        {{ 'SALES.SALES_VIEW.NO_FINALS_MSG' | translate }}
      </div>

      <div class="sale-finals-scroll" *ngIf="data?.finals?.length > 0">
        <div class="single-final" *ngFor="let dataItemFinal of data?.finals; let i = index">
          <div class="final-head d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <div class="cell offer-name" [ngbTooltip]="dataItemFinal?.sale_offer?.name" container="body">
                {{ dataItemFinal?.sale_offer?.name }}
              </div>

              <div class="cell" *ngIf="dataItemFinal?.payment_method">
                <div class="super-small-text">{{ 'SALES.SALES_VIEW.PAYMENT_METHOD' | translate }}</div>
                <app-type-badge [type]="dataItemFinal?.payment_method"></app-type-badge>
              </div>

              <div class="cell" *ngIf="dataItemFinal?.client_penalty">
                <div class="super-small-text">{{ 'SALES.SALES_VIEW.CLIENT_PENALTY' | translate }}</div>
                {{ dataItemFinal?.client_penalty }}
              </div>

              <div
                class="cell"
                *ngIf="dataItemFinal?.sale_offer?.cancellation_terms"
                [ngbTooltip]="dataItemFinal.sale_offer.cancellation_terms"
                container="body"
              >
                <div class="super-small-text">{{ 'SALES.SALES_VIEW.CANCELLATION_TERMS' | translate }}</div>
                {{ dataItemFinal?.sale_offer?.cancellation_terms }}
              </div>
            </div>

            <div class="d-flex">
              <div>
                <app-download-vouchers-button
                  *ngIf="
                    accessService.hasAccess(
                      [
                        UserRoleType.RESERVATION_AGENT,
                        UserRoleType.RESERVATION_MANAGER,
                        UserRoleType.CONTRACTING_AGENT,
                        UserRoleType.CONTRACTING_MANAGER,
                        UserRoleType.SALES_AGENT,
                        UserRoleType.SALES_MANAGER,
                        UserRoleType.CUSTOMER_CARE_AGENT,
                        UserRoleType.CUSTOMER_CARE_MANAGER,
                        UserRoleType.FINANCE_AGENT,
                        UserRoleType.FINANCE_MANAGER,
                        UserRoleType.LEAD_GENERATION
                      ],
                      []
                    )
                  "
                  [type]="'sale_finals'"
                  [id]="dataItemFinal.id"
                  [companyId]="data.company_id"
                  class="mr-3"
                >
                </app-download-vouchers-button>
              </div>

              <div
                *ngIf="
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_AGENT,
                      UserRoleType.CONTRACTING_MANAGER
                    ],
                    []
                  )
                "
                class="custom-control custom-checkbox mt-1 mr-3"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  [(ngModel)]="dataItemFinal.meta_front.form.completed"
                  [attr.id]="'permission' + '-' + dataItemFinal.id"
                  (change)="saleFinalChange(dataItemFinal.meta_front.form)"
                />
                <label class="custom-control-label" [attr.for]="'permission' + '-' + dataItemFinal.id">
                  {{ 'SALES.SALES_FORM.FINAL_COMPLETED' | translate }}
                </label>
              </div>

              <div>
                <button
                  *ngIf="accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], [])"
                  type="button"
                  class="btn btn-xs btn-warning"
                  (click)="createInvoice(dataItemFinal.id)"
                >
                  {{ 'SALES.SALES_VIEW.CREATE_INVOICE' | translate }}
                </button>

                <app-edit-button
                  *ngIf="
                    accessService.hasAccess(
                      [
                        UserRoleType.RESERVATION_AGENT,
                        UserRoleType.RESERVATION_MANAGER,
                        UserRoleType.CONTRACTING_AGENT,
                        UserRoleType.CONTRACTING_MANAGER,
                        UserRoleType.FINANCE_MANAGER
                      ],
                      []
                    )
                  "
                  (click)="editFinalSale(dataItemFinal)"
                >
                </app-edit-button>

                <button
                  *ngIf="
                    accessService.hasAccess(
                      [
                        UserRoleType.RESERVATION_AGENT,
                        UserRoleType.RESERVATION_MANAGER,
                        UserRoleType.CONTRACTING_AGENT,
                        UserRoleType.CONTRACTING_MANAGER,
                        UserRoleType.SALES_AGENT,
                        UserRoleType.SALES_MANAGER,
                        UserRoleType.CUSTOMER_CARE_AGENT,
                        UserRoleType.CUSTOMER_CARE_MANAGER,
                        UserRoleType.LEAD_GENERATION
                      ],
                      []
                    )
                  "
                  [ngbTooltip]="'SALES.SALES_VIEW.GUEST_NAMES' | translate"
                  container="body"
                  type="button"
                  class="btn btn-primary btn-ico"
                  (click)="editGuestNames(dataItemFinal.id)"
                >
                  <i class="fal fa-users"></i>
                </button>
              </div>
            </div>
          </div>

          <kendo-grid
            [columnMenu]="false"
            scrollable="none"
            [data]="dataItemFinal?.items"
            [selectable]="selectableSettings"
            [kendoGridSelectBy]="'id'"
            [selectedKeys]="dataItemFinal.meta_front.selectedFinalLines"
            (selectionChange)="finalsSelectionChange(dataItemFinal)"
            #finalsGrid
            (excelExport)="onExcelExportFinals($event, 'Finals', finalsGrid, 2)"
          >
            <kendo-grid-checkbox-column
              [width]="32"
              [columnMenu]="false"
              showSelectAll="true"
              class="text-center"
              headerClass="text-center p-1"
            >
            </kendo-grid-checkbox-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ rowIndex + 1 }}

                <app-download-vouchers-button
                  [type]="'sale_final_items'"
                  [id]="
                    dataItemFinal?.meta_front?.selectedFinalLines?.length > 0
                      ? dataItemFinal?.meta_front?.selectedFinalLines
                      : dataItem.id
                  "
                  [companyId]="data.company_id"
                  class="d-inline-block"
                >
                </app-download-vouchers-button>
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{ 'SALES.SALES_VIEW.TOTALS' | translate }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.HOTEL' | translate }}"
              field="hotel_booking_room.hotel.name"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a
                  [routerLink]="['/hotels/view']"
                  [queryParams]="{ id: dataItem?.hotel_booking_room?.hotel?.id }"
                  target="_blank"
                >
                  {{ dataItem?.hotel_booking_room?.hotel?.name }}
                </a>
                <span *ngIf="dataItem?.hotel_booking_room?.hotel?.stars" class="text-nowrap">
                  ({{ dataItem?.hotel_booking_room?.hotel?.stars
                  }}<span class="star-ico-wrapper"><i class="fas fa-star"></i></span>)
                </span>
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                <button type="button" class="btn kendo-export-btn" kendoGridExcelCommand>
                  {{ 'EXPORT_TO_EXCEL' | translate }}
                </button>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.SUPPLIER' | translate }}"
              field="hotel_booking_room.booking.supplier.name"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.booking?.supplier?.name }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.BOOKING_STATUS' | translate }}"
              field="hotel_booking_room.booking.property.value"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.booking?.property?.value }}

                <button
                  *ngIf="dataItem?.hotel_booking_room?.booking"
                  (click)="
                    editHotelBooking(
                      dataItem?.hotel_booking_room?.booking,
                      dataItem?.hotel_booking_nights,
                      true,
                      dataItemFinal
                    )
                  "
                  class="btn btn-info btn-ico"
                  [ngbTooltip]="'SALES.SALES_VIEW.EDIT_BOOKING' | translate"
                  container="body"
                >
                  <i class="fal fa-eye"></i>
                </button>

                <a
                  target="_blank"
                  *ngIf="dataItem?.hotel_booking_room?.booking"
                  [routerLink]="'/hotel-bookings/view'"
                  [queryParams]="{ id: dataItem?.hotel_booking_room?.booking?.id }"
                  class="btn btn-primary btn-ico"
                  [ngbTooltip]="'SALES.SALES_VIEW.PREVIEW_BOOKING' | translate"
                  container="body"
                >
                  <i class="fal fa-external-link-alt"></i>
                </a>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.BOOKING_OFFICE_SHORT' | translate }}"
              field="hotel_booking_room.booking.company.abbreviation"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.booking?.company?.abbreviation }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.REF_NUMBER_SHORT' | translate }}"
              field="EXPORT"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngFor="let guestNameArr of dataItem?.meta_front?.guestNamesArr | keyvalue; let ind = index">
                  {{ guestNameArr.value.refNumber }}
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.CHECK_IN' | translate }}"
              field="check_in"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.check_in | date : env.pipeDateFormat }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              width="30"
              title="{{ 'SALES.SALES_VIEW.NIGHTS' | translate }}"
              field="EXPORT"
            >
              <ng-template kendoGridHeaderTemplate>
                <i class="fas fa-moon nights-ico"></i>
              </ng-template>

              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.check_out | amDifference : dataItem?.check_in : 'days' : true }}
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.nightsCount
                    : dataItemFinal.meta_front?.totals?.nightsCount
                }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.CHECK_OUT' | translate }}"
              field="check_out"
              width="90"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.check_out | date : env.pipeDateFormat }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.TYPE' | translate }}"
              width="70"
              field="hotel_booking_room.type.value"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.type?.value }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              title="{{ 'SALES.SALES_VIEW.ROOM_COUNT_SHORT' | translate }}"
              [width]="58"
              field="count"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem.count }}
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.roomCount
                    : dataItemFinal.meta_front?.totals?.roomCount
                }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              width="30"
              field="EXPORT"
              title="{{ 'SALES.SALES_VIEW.PEOPLE' | translate }}"
            >
              <ng-template kendoGridHeaderTemplate>
                <i class="fas fa-user"></i>
              </ng-template>

              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.sale_offer_item?.people * dataItem.count }}
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.people
                    : dataItemFinal.meta_front?.totals?.people
                }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.GUEST_NAMES' | translate }}"
              width="160"
              field="EXPORT"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div *ngFor="let guestNameArr of dataItem?.meta_front?.guestNamesArr | keyvalue; let ind = index">
                  <strong>{{ guestNameArr.value.confirmationNumber }}</strong>

                  <div *ngFor="let guestName of guestNameArr.value.guestNames; let ind = index">
                    {{ guestName }}<span *ngIf="dataItem?.guest_names?.length !== ind + 1">,</span>
                  </div>
                </div>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold "
              title="{{ 'SALES.SALES_VIEW.MEAL_PLAN_SHORT' | translate }}"
              width="60"
              field="hotel_booking_room.meal_plan.value"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.meal_plan?.value }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              title="{{ 'SALES.SALES_VIEW.CLIENT_PRICE' | translate }}"
              width="80"
              field="EXPORT"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.sale_offer_item?.price | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  (dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.priceNightsCount
                    : dataItemFinal.meta_front?.totals?.priceNightsCount
                  ) | number : '1.2-2'
                }}
                {{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              title="{{ 'SALES.SALES_VIEW.NET_PRICE' | translate }}"
              width="80"
              field="EXPORT"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.net_price | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  (dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.netPriceNightsCount
                    : dataItemFinal.meta_front?.totals?.netPriceNightsCount
                  ) | number : '1.2-2'
                }}{{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              title="{{ 'SALES.SALES_VIEW.BREAKFAST_PRICE' | translate }}"
              width="80"
              field="EXPORT"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div>
                  {{ dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night || 0 | number : '1.2-2' }}
                  <app-currency-box
                    [currency]="dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night_currency"
                  ></app-currency-box>
                </div>

                <small>
                  {{
                    dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night /
                      dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night_exchange_rate || 0
                      | number : '1.2-2'
                  }}
                  {{ 'DEFAULT_CURRENCY' | translate }}
                </small>

                <app-payment-button
                  [paymentable_type]="LaravelModelsEnum.SALE_FINAL_ITEM"
                  [paymentable_id]="dataItem.id"
                  [hideBackdrop]="true"
                  [additionalJson]="{
                    company_id: dataItem?.hotel_booking_room?.booking?.company_id,
                    company: dataItem?.hotel_booking_room?.booking?.company,
                    amount:
                      dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night *
                        dataItem?.sale_offer_item?.people *
                        (dataItem?.check_out | amDifference : dataItem?.check_in : 'days' : true | number : '1.2-2') *
                        dataItem.count | number : '1.2-2',
                    currency_id: dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night_currency_id,
                    private_note:
                      'Breakfast payment for sale ID: ' +
                      saleId +
                      ', final: ' +
                      dataItemFinal?.sale_offer?.name +
                      ', line: ' +
                      (rowIndex + 1),
                    clientable_id: dataItem?.hotel_booking_room?.hotel_id,
                    clientable: dataItem?.hotel_booking_room?.hotel
                  }"
                  (close)="afterCreatePayment()"
                  *ngIf="dataItem?.hotel_booking_room?.booking?.breakfast_price_per_night > 0"
                >
                </app-payment-button>
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  (dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.breakfastPriceNightsCount
                    : dataItemFinal.meta_front?.totals?.breakfastPriceNightsCount
                  ) | number : '1.2-2'
                }}{{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column
              class="text-center"
              headerClass="text-center p-1"
              footerClass="font-weight-bold text-center"
              title="{{ 'SALES.SALES_VIEW.CLIENT_MARGIN' | translate }}"
              width="80"
              field="EXPORT"
            >
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{ dataItem?.hotel_booking_room?.client_margin | number : '1.2-2' }}
                {{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>

              <ng-template kendoGridFooterTemplate>
                {{
                  (dataItemFinal.meta_front?.selectedFinalLines?.length > 0
                    ? dataItemFinal.meta_front?.selectedFinalLinesTotals?.marginNightsCount
                    : dataItemFinal.meta_front?.totals?.marginNightsCount
                  ) | number : '1.2-2'
                }}
                {{ 'DEFAULT_CURRENCY' | translate }}
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-excel></kendo-grid-excel>
          </kendo-grid>
        </div>
      </div>

      <div class="finals-summary-scroll mt-2" *ngIf="data?.finals?.length > 0">
        <div class="table mb-0">
          <thead class="summary-thead">
            <tr>
              <th style="width: auto"></th>

              <th width="30"><i class="fas fa-moon nights-ico"></i></th>
              <th width="160"></th>
              <th width="20">{{ 'SALES.SALES_VIEW.ROOM_COUNT_SHORT' | translate }}</th>

              <th width="30"><i class="fas fa-user"></i></th>

              <th width="220"></th>

              <th width="80">{{ 'SALES.SALES_VIEW.TOTAL_CLIENT_PRICE' | translate }}</th>
              <th width="80">{{ 'SALES.SALES_VIEW.TOTAL_NET_PRICE' | translate }}</th>

              <th width="80">{{ 'SALES.SALES_VIEW.TOTAL_BREAKFAST_PRICE' | translate }}</th>
              <th width="80">{{ 'SALES.SALES_VIEW.TOTAL_CLIENT_MARGIN' | translate }}</th>
            </tr>
          </thead>

          <tbody>
            <tr class="font-weight-bold total-tr">
              <td class="text-left">{{ 'SALES.SALES_VIEW.FINALS_SUMMARY' | translate }}</td>

              <td>{{ finalsSummary.nightsCount }}</td>
              <td></td>
              <td>{{ finalsSummary.roomCount }}</td>

              <td>{{ finalsSummary.people }}</td>

              <td></td>

              <td>{{ finalsSummary.priceNightsCount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}</td>

              <td>{{ finalsSummary.netPriceNightsCount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}</td>

              <td>
                {{ finalsSummary.breakfastPriceNightsCount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
              </td>
              <td>{{ finalsSummary.marginNightsCount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}</td>
            </tr>
          </tbody>
        </div>
      </div>

      <div
        *ngIf="
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.SALES_AGENT,
              UserRoleType.SALES_MANAGER,
              UserRoleType.CUSTOMER_CARE_AGENT,
              UserRoleType.CUSTOMER_CARE_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER,
              UserRoleType.LEAD_GENERATION,
              UserRoleType.ACCOUNTANT
            ],
            ['invoices.index']
          )
        "
      >
        <div class="title2 mt-3">
          {{ 'SALES.SALES_VIEW.FINANCE' | translate }}
        </div>

        <div class="no-data-msg-wrapper" *ngIf="invoicesResponse?.data.length <= 0">
          {{ 'SALES.SALES_VIEW.NO_FINANCE_MSG' | translate }}
        </div>
        <kendo-grid
          *ngIf="invoicesResponse?.data.length > 0"
          [data]="invoicesData"
          [pageSize]="invoicesResponse?.meta.per_page"
          [sortable]="false"
          [sort]="invoiceState?.sort"
          [skip]="invoiceState?.skip"
          [pageable]="true"
          (dataStateChange)="loadInvoicesData($event)"
          [rowClass]="invoiceRowCallback"
          #invoicesGrid
          (excelExport)="onExcelExport($event, 'Finance', invoicesGrid, 2)"
        >
          <kendo-grid-column
            [width]="50"
            class="text-center"
            headerClass="text-center"
            title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ rowIndex + 1 }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            headerClass="text-center"
            class="text-center"
            [width]="50"
            field="id"
            title="{{ 'SALES.SALES_VIEW.ID' | translate }}"
            filter="numeric"
          ></kendo-grid-column>

          <kendo-grid-column
            field="company.shortName"
            title="{{ 'SALES.SALES_VIEW.COMPANY' | translate }}"
            filter="text"
            width="50"
          ></kendo-grid-column>

          <kendo-grid-column
            field="booking_offices"
            title="{{ 'SALES.SALES_VIEW.BOOKING_OFFICE_SHORT' | translate }}"
            filter="text"
            width="50"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span
                *ngFor="let compnay of dataItem?.meta_front?.booking_offices; let i = index"
                class="single-booking-office"
              >
                {{ compnay }}<span *ngIf="i !== dataItem?.meta_front?.booking_offices?.length - 1">, </span>
              </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column width="70" field="type.value" title="{{ 'SALES.SALES_VIEW.TYPE' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <app-type-badge [type]="dataItem.type"></app-type-badge>
              <small *ngIf="dataItem?.linked_id && dataItem?.type_id === InvoiceType.PROFORMA">
                {{ 'SALES.SALES_VIEW.LINKED_TO_ORIGINAL' | translate }} #{{ dataItem?.linked_id }}
              </small>
              <small *ngIf="dataItem?.linked_id && dataItem?.type_id === InvoiceType.ORIGINAL">
                {{ 'SALES.SALES_VIEW.LINKED_TO_PROFORMA' | translate }} #{{ dataItem?.linked_id }}
              </small>
            </ng-template>
          </kendo-grid-column>

          <!--      <kendo-grid-column-->
          <!--        field="cleanClientableType"-->
          <!--        title="{{ 'SALES.SALES_VIEW.CLIENTABLE_TYPE' | translate }}"-->
          <!--      ></kendo-grid-column>-->

          <kendo-grid-column
            field="clientable.name"
            title="{{ 'SALES.SALES_VIEW.CLIENTABLE_NAME' | translate }}"
          ></kendo-grid-column>

          <kendo-grid-column
            field="invoiceable.sale_offer.name"
            title="{{ 'SALES.SALES_VIEW.OFFER_NAME' | translate }}"
          ></kendo-grid-column>

          <kendo-grid-column
            field="date"
            title="{{ 'SALES.SALES_VIEW.DATE' | translate }}"
            filter="date"
            format="{0:dd/MM/yyyy}"
            width="70"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem?.date | date : env.pipeDateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="due_date"
            title="{{ 'SALES.SALES_VIEW.DUE_DATE' | translate }}"
            filter="date"
            format="{0:dd/MM/yyyy}"
            width="70"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem?.due_date | date : env.pipeDateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="payment_method.value" title="{{ 'SALES.SALES_VIEW.PAYMENT_METHOD' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <app-type-badge [type]="dataItem.payment_method"></app-type-badge>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="total_amount" title="{{ 'SALES.SALES_VIEW.TOTAL_AMOUNT' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.total_amount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
            </ng-template>

            <ng-template kendoGridFooterTemplate let-column>
              {{ data?.total_amount | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="balance" title="{{ 'SALES.SALES_VIEW.BALANCE' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span [ngClass]="{ 'text-danger': dataItem.balance < 0, 'text-success': dataItem.balance >= 0 }">
                {{ dataItem.balance | number : '1.2-2' }} {{ 'DEFAULT_CURRENCY' | translate }}
              </span>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="number"
            title="{{ 'SALES.SALES_VIEW.INVOICE_NUMBER' | translate }}"
            filter="numeric"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.formatted_number }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="deposit_percent"
            title="{{ 'SALES.SALES_VIEW.DEPOSIT_PERCENT' | translate }}"
            filter="numeric"
            width="70"
          ></kendo-grid-column>

          <kendo-grid-column
            field="balance_due_date"
            title="{{ 'SALES.SALES_VIEW.BALANCE_DUE_DATE' | translate }}"
            filter="date"
            format="{0:dd/MM/yyyy}"
            width="70"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem?.balance_due_date | date : env.pipeDateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column title="{{ 'SALES.SALES_VIEW.PAYMENT_TYPE' | translate }}" width="50">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <ng-container *ngIf="dataItem.deposit_percent">
                {{ 'SALES.SALES_VIEW.PAYMENT_TYPE_DEPOSIT' | translate }}
              </ng-container>

              <ng-container *ngIf="dataItem.deposit_invoices.length > 0">
                {{ 'SALES.SALES_VIEW.PAYMENT_TYPE_BALANCE' | translate }}
              </ng-container>

              <ng-container *ngIf="!dataItem.deposit_percent && dataItem.deposit_invoices.length <= 0">
                {{ 'SALES.SALES_VIEW.PAYMENT_TYPE_FULL' | translate }}
              </ng-container>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            title="{{ 'SALES.SALES_VIEW.ACTIONS' | translate }}"
            [width]="100"
            [class]="'text-center'"
            [includeInChooser]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <app-edit-button
                *ngIf="
                  accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], ['invoices.show'])
                "
                (click)="editInvoice(dataItem)"
              ></app-edit-button>

              <app-download-invoice-button
                *ngIf="
                  accessService.hasAccess(
                    [
                      UserRoleType.RESERVATION_AGENT,
                      UserRoleType.RESERVATION_MANAGER,
                      UserRoleType.CONTRACTING_MANAGER,
                      UserRoleType.SALES_AGENT,
                      UserRoleType.SALES_MANAGER,
                      UserRoleType.CUSTOMER_CARE_AGENT,
                      UserRoleType.CUSTOMER_CARE_MANAGER,
                      UserRoleType.FINANCE_AGENT,
                      UserRoleType.FINANCE_MANAGER
                    ],
                    ['invoices.download']
                  )
                "
                [invoice]="dataItem"
              >
              </app-download-invoice-button>

              <app-payment-button
                *ngIf="
                  dataItem.type_id != InvoiceType.PROFORMA &&
                  accessService.hasAccess(
                    [UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER],
                    ['payments.store']
                  )
                "
                [paymentable_type]="LaravelModelsEnum.INVOICE"
                [paymentable_id]="dataItem.id"
                (close)="afterCreatePayment()"
              >
              </app-payment-button>

              <app-delete-button
                *ngIf="
                  accessService.hasAccess(
                    [UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER],
                    ['invoices.destroy']
                  )
                "
                [swal]="{}"
                (confirm)="deleteInvoice(dataItem)"
              >
              </app-delete-button>
            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
            <app-kendo-grid-refresh-button (click)="loadInvoicesData(invoiceState)"></app-kendo-grid-refresh-button>

            <button type="button" class="btn kendo-export-btn" [disabled]="invoicesGrid.loading" kendoGridExcelCommand>
              {{ 'EXPORT_TO_EXCEL' | translate }}
            </button>
          </ng-template>

          <kendo-grid-excel></kendo-grid-excel>

          <div *kendoGridDetailTemplate="let dataItem">
            <kendo-grid
              [data]="dataItem.payments"
              [sortable]="false"
              [filterable]="false"
              [columnMenu]="false"
              [pageable]="false"
            >
              <kendo-grid-column
                [width]="70"
                class="text-center"
                headerClass="text-center"
                title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ rowIndex + 1 }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="id" title="{{ 'SALES.SALES_VIEW.ID' | translate }}" [width]="120">
              </kendo-grid-column>

              <kendo-grid-column field="company.shortName" title="{{ 'SALES.SALES_VIEW.COMPANY' | translate }}">
              </kendo-grid-column>

              <kendo-grid-column field="amount" title="{{ 'SALES.SALES_VIEW.AMOUNT' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span *ngIf="dataItem?.type?.id === PaymentsTypes.EXPENSE">-</span
                  >{{ dataItem.amount | number : '1.2-2' }}
                  <app-currency-box [currency]="dataItem?.currency" class="d-inline-block"></app-currency-box>
                  <app-currency-converter
                    [value]="dataItem.amount"
                    [fromCurrency]="dataItem?.currency"
                    [exchangeRate]="dataItem?.exchange_rate"
                  ></app-currency-converter>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="date" title="{{ 'SALES.SALES_VIEW.DATE' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem?.date | date : env.pipeDateFormat }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="payment_method" title="{{ 'SALES.SALES_VIEW.PAYMENT_METHOD' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-type-badge [type]="dataItem.payment_method"></app-type-badge>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="fee_amount" title="{{ 'SALES.SALES_VIEW.FEE_AMOUNT' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem.fee_amount | number : '1.2-2' }}
                  <app-currency-box [currency]="dataItem?.fee_currency"></app-currency-box>
                  <app-currency-converter
                    [value]="dataItem.fee_amount"
                    [fromCurrency]="dataItem?.fee_currency"
                    [exchangeRate]="dataItem?.fee_exchange_rate"
                  ></app-currency-converter>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column title="{{ 'SALES.SALES_VIEW.BANK_ACCOUNT' | translate }}">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  {{ dataItem?.bank_account?.displayName }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column
                [width]="50"
                [class]="'text-center'"
                headerClass="text-center"
                title="{{ 'SALES.SALES_VIEW.PAID' | translate }}"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-active-indicator [active]="dataItem.is_paid"></app-active-indicator>
                </ng-template>
              </kendo-grid-column>

              <!--          <kendo-grid-column-->
              <!--            field="cleanClientableType"-->
              <!--            title="{{ 'SALES.SALES_VIEW.CLIENTABLE_TYPE' | translate }}">-->
              <!--          </kendo-grid-column>-->

              <!--          <kendo-grid-column-->
              <!--            field="clientable.name"-->
              <!--            title="{{ 'SALES.SALES_VIEW.CLIENTABLE_NAME' | translate }}">-->
              <!--          </kendo-grid-column>-->

              <!--          <kendo-grid-column-->
              <!--            field="type"-->
              <!--            title="{{ 'SALES.SALES_VIEW.TYPE' | translate }}">-->
              <!--            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
              <!--              <app-type-badge [type]="dataItem.type"></app-type-badge>-->
              <!--            </ng-template>-->
              <!--          </kendo-grid-column>-->

              <kendo-grid-column
                title="{{ 'SALES.SALES_VIEW.ACTIONS' | translate }}"
                [width]="100"
                [class]="'text-center'"
                [includeInChooser]="false"
              >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <app-edit-button (click)="editPayment(dataItem)"></app-edit-button>

                  <app-media-library-button
                    [ngbTooltip]="'SALES.SALES_VIEW.UPLOAD_DOCUMENTS' | translate"
                    container="body"
                    [modelId]="dataItem.id"
                    [modelType]="LaravelModelsEnum.PAYMENT"
                    [collectionName]="'files'"
                    [acceptType]="MediaLibraryAcceptTypes.ALL"
                    [icon]="'fal fa-file'"
                  ></app-media-library-button>

                  <app-delete-button [swal]="{}" (confirm)="deletePayment(dataItem)"></app-delete-button>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </div>
        </kendo-grid>
      </div>

      <div
        *ngIf="
          accessService.hasAccess(
            [
              UserRoleType.RESERVATION_AGENT,
              UserRoleType.RESERVATION_MANAGER,
              UserRoleType.CONTRACTING_AGENT,
              UserRoleType.CONTRACTING_MANAGER,
              UserRoleType.FINANCE_AGENT,
              UserRoleType.FINANCE_MANAGER,
              UserRoleType.ACCOUNTANT
            ],
            ['payments.index']
          )
        "
      >
        <div class="title2 mt-3">
          {{ 'SALES.SALES_VIEW.EXPENSES' | translate }}
        </div>

        <div class="no-data-msg-wrapper" *ngIf="paymentsExpensesResponse?.data.length <= 0">
          {{ 'SALES.SALES_VIEW.NO_EXPENSES_MSG' | translate }}
        </div>
        <kendo-grid
          *ngIf="paymentsExpensesResponse?.data.length > 0"
          [data]="paymentsExpensesData"
          [pageSize]="paymentsExpensesResponse?.meta.per_page"
          [sortable]="false"
          [sort]="paymentsExpensesState?.sort"
          [skip]="paymentsExpensesState?.skip"
          [pageable]="true"
          (dataStateChange)="loadPaymentsExpensesData($event)"
          #expensesGrid
          (excelExport)="onExcelExport($event, 'Expenses', expensesGrid)"
        >
          <kendo-grid-column
            [width]="70"
            class="text-center"
            headerClass="text-center"
            title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ rowIndex + 1 }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="id"
            title="{{ 'SALES.SALES_VIEW.ID' | translate }}"
            [width]="80"
          ></kendo-grid-column>

          <kendo-grid-column
            field="company.shortName"
            title="{{ 'SALES.SALES_VIEW.COMPANY' | translate }}"
            [width]="80"
          >
          </kendo-grid-column>

          <!-- Task #711: Final Lines - edit, save -->
          <!--          <kendo-grid-column-->
          <!--            field="paymentable.sale_final.sale_offer.name"-->
          <!--            title="{{ 'SALES.SALES_VIEW.OFFER_NAME' | translate }}"-->
          <!--          >-->
          <!--            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">-->
          <!--              {{ dataItem?.paymentable?.sale_final?.sale_offer?.name }}-->
          <!--              {{ dataItem?.paymentable?.sale_offer?.name }}-->
          <!--            </ng-template>-->
          <!--          </kendo-grid-column>-->

          <kendo-grid-column field="clientable.name" title="{{ 'SALES.SALES_VIEW.CLIENT' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <ng-container *ngIf="dataItem.clientable_type !== ClientabeType.COMPANY">
                {{ dataItem?.clientable?.name }}
              </ng-container>

              <ng-container *ngIf="dataItem.clientable_type === ClientabeType.COMPANY">
                {{ dataItem?.clientable?.abbreviation }}
              </ng-container>

              <small>({{ dataItem.cleanClientableType }})</small>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="amount" title="{{ 'SALES.SALES_VIEW.AMOUNT' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span *ngIf="dataItem?.type?.id === PaymentsTypes.EXPENSE">-</span
              >{{ dataItem.amount | number : '1.2-2' }}
              <app-currency-box [currency]="dataItem?.currency" class="d-inline-block"></app-currency-box>
              <app-currency-converter
                [value]="dataItem.amount"
                [fromCurrency]="dataItem?.currency"
                [exchangeRate]="dataItem?.exchange_rate"
              ></app-currency-converter>
            </ng-template>

            <ng-template kendoGridFooterTemplate let-column>
              {{ 'SALES.SALES_VIEW.TOTAL_AMOUNT' | translate }}
              {{ paymentsExpensesResponse?.meta_front?.total_amount | number : '1.2-2' }}
              {{ 'DEFAULT_CURRENCY' | translate }}
              <br />

              {{ 'SALES.SALES_VIEW.TOTAL_PAID' | translate }}
              {{ paymentsExpensesResponse?.meta_front?.total_paid | number : '1.2-2' }}
              {{ 'DEFAULT_CURRENCY' | translate }}

              <br />

              {{ 'SALES.SALES_VIEW.BALANCE' | translate }}
              {{
                paymentsExpensesResponse?.meta_front?.total_amount - paymentsExpensesResponse?.meta_front?.total_paid
                  | number : '1.2-2'
              }}
              {{ 'DEFAULT_CURRENCY' | translate }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="date" title="{{ 'SALES.SALES_VIEW.DATE' | translate }}" [width]="80">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem?.date | date : env.pipeDateFormat }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="payment_method.value" title="{{ 'SALES.SALES_VIEW.PAYMENT_METHOD' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <app-type-badge [type]="dataItem.payment_method"></app-type-badge>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="fee_amount" title="{{ 'SALES.SALES_VIEW.FEE_AMOUNT' | translate }}" [width]="90">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem.fee_amount | number : '1.2-2' }}
              <app-currency-box [currency]="dataItem?.fee_currency"></app-currency-box>
              <app-currency-converter
                [value]="dataItem.fee_amount"
                [fromCurrency]="dataItem?.fee_currency"
                [exchangeRate]="dataItem?.fee_exchange_rate"
              ></app-currency-converter>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column field="bank_account.displayName" title="{{ 'SALES.SALES_VIEW.BANK_ACCOUNT' | translate }}">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem?.bank_account?.displayName }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="paymentable.ref_number"
            title="{{ 'SALES.SALES_VIEW.REF_NUMBER_SHORT' | translate }}"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              {{ dataItem?.paymentable?.ref_number }}
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            field="is_paid"
            [width]="50"
            [class]="'text-center'"
            headerClass="text-center"
            title="{{ 'SALES.SALES_VIEW.PAID' | translate }}"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <app-active-indicator [active]="dataItem.is_paid"></app-active-indicator>
            </ng-template>
          </kendo-grid-column>

          <kendo-grid-column
            title="{{ 'SALES.SALES_VIEW.ACTIONS' | translate }}"
            [width]="100"
            [class]="'text-center'"
            [includeInChooser]="false"
          >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <app-edit-button (click)="editPayment(dataItem)"></app-edit-button>

              <app-media-library-button
                [ngbTooltip]="'SALES.SALES_VIEW.UPLOAD_DOCUMENTS' | translate"
                container="body"
                [modelId]="dataItem.id"
                [modelType]="LaravelModelsEnum.PAYMENT"
                [collectionName]="'files'"
                [acceptType]="MediaLibraryAcceptTypes.ALL"
                [icon]="'fal fa-file'"
              ></app-media-library-button>

              <app-delete-button [swal]="{}" (confirm)="deletePayment(dataItem)"></app-delete-button>
            </ng-template>
          </kendo-grid-column>

          <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
            <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
            <kendo-pager-input></kendo-pager-input>
            <kendo-pager-info></kendo-pager-info>
            <kendo-pager-next-buttons></kendo-pager-next-buttons>
            <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
            <app-kendo-grid-refresh-button
              (click)="loadPaymentsExpensesData(paymentsExpensesState)"
            ></app-kendo-grid-refresh-button>
            <button type="button" class="btn kendo-export-btn" [disabled]="expensesGrid.loading" kendoGridExcelCommand>
              {{ 'EXPORT_TO_EXCEL' | translate }}
            </button>
          </ng-template>

          <kendo-grid-excel></kendo-grid-excel>
        </kendo-grid>
      </div>

      <div class="d-flex justify-content-between mt-3 align-items-end">
        <div class="title2 mt-3">
          {{ 'SALES.SALES_VIEW.WEB_OFFERS' | translate }}
        </div>

        <div>
          <button type="button" class="btn btn-sm btn-secondary" (click)="addWebOffer()">
            {{ 'SALES.SALES_VIEW.ADD_WEB_OFFER' | translate }}
          </button>
        </div>
      </div>

      <div class="no-data-msg-wrapper" *ngIf="webOffersResponse?.data.length <= 0">
        {{ 'SALES.SALES_VIEW.NO_WEB_OFFERS_MSG' | translate }}
      </div>
      <kendo-grid
        *ngIf="webOffersResponse?.data.length > 0"
        [data]="webOffersData"
        [pageSize]="webOffersResponse?.meta.per_page"
        [sortable]="false"
        [sort]="webOffersState?.sort"
        [skip]="webOffersState?.skip"
        [pageable]="true"
        (dataStateChange)="loadWebOffersData($event)"
        #webOffersGrid
        (excelExport)="onExcelExport($event, 'Web_offers', webOffersGrid)"
      >
        <kendo-grid-column
          [width]="70"
          class="text-center"
          headerClass="text-center"
          title="{{ 'SALES.SALES_VIEW.NUMBER' | translate }}"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            {{ rowIndex + 1 }}
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="id" title="{{ 'SALES.SALES_VIEW.ID' | translate }}" [width]="120"></kendo-grid-column>

        <kendo-grid-column field="title" title="{{ 'SALES.SALES_VIEW.WEB_OFFERS_TITLE' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div
              class="d-inline-block text-truncate"
              style="width: 300px"
              [ngbTooltip]="dataItem?.title"
              container="body"
            >
              {{ dataItem.title }}
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="description" title="{{ 'SALES.SALES_VIEW.DESCRIPTION' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div
              class="d-inline-block text-truncate"
              style="width: 300px"
              [ngbTooltip]="dataItem?.description"
              container="body"
            >
              {{ dataItem.description }}
            </div>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          field="active"
          [width]="50"
          [class]="'text-center'"
          headerClass="text-center"
          title="{{ 'SALES.SALES_VIEW.ACTIVE' | translate }}"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <app-active-indicator [active]="dataItem.active"></app-active-indicator>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column
          title="{{ 'SALES.SALES_VIEW.ACTIONS' | translate }}"
          [width]="100"
          [class]="'text-center'"
          [includeInChooser]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <a target="_blank" [attr.href]="dataItem.url" class="btn btn-ico">
              <i class="fal fa-external-link-alt"></i>
            </a>
            <app-edit-button (click)="editWebOffer(dataItem)"></app-edit-button>

            <app-delete-button [swal]="{}" (confirm)="deleteWebOffer(dataItem)"></app-delete-button>
          </ng-template>
        </kendo-grid-column>

        <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-input></kendo-pager-input>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-page-sizes [pageSizes]="env.kendoGridPageSizes"></kendo-pager-page-sizes>
          <app-kendo-grid-refresh-button (click)="loadWebOffersData(webOffersState)"></app-kendo-grid-refresh-button>
          <button type="button" class="btn kendo-export-btn" [disabled]="webOffersGrid.loading" kendoGridExcelCommand>
            {{ 'EXPORT_TO_EXCEL' | translate }}
          </button>
        </ng-template>

        <kendo-grid-excel></kendo-grid-excel>
      </kendo-grid>
    </div>
  </div>
</div>
<!--<div (click)="test(data)">test</div>-->
