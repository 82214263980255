import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PaginatedResponse } from '@app/shared/models/paginated-response.model';
import { ApiQuery } from '@app/core/http/api-query';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { Router } from '@angular/router';
import { UsersService } from '@app/settings/users/users.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Logger } from '@app/core';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { AccessService } from '@app/core/access/access.service';
import { UserRoleType } from '@app/settings/users/user-role-type.enum';

const log = new Logger('UserSalesComponent');

@UntilDestroy()
@Component({
  selector: 'app-reports-user-assignments-list',
  templateUrl: './user-assignments.component.html',
  styleUrls: ['./user-assignments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAssignmentsComponent implements OnInit {
  apiQuery: ApiQuery = new ApiQuery();
  requestsResponse: PaginatedResponse;
  data: GridDataResult;
  state: DataStateChangeEvent;
  public loader = false;
  protected filters: CompositeFilterDescriptor = {
    logic: 'and',
    filters: []
  };
  protected total = 0;
  protected totalToNow = 0;

  private saleStatuses = '';
  private saleRequestStatuses = '';

  constructor(
    private usersService: UsersService,
    private router: Router,
    private accessService: AccessService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    const hasAccess = this.accessService.hasOneOfRoles([
      UserRoleType.ADMINISTRATOR,
      UserRoleType.SALES_MANAGER,
      UserRoleType.RESERVATION_MANAGER,
      UserRoleType.LEAD_GENERATION
    ]);

    if (!hasAccess) {
      this.router.navigate(['/']).catch((e) => log.error('Navigate without permissions error', e));
      return;
    }

    this.loadData();
  }

  loadData(state: DataStateChangeEvent = null) {
    this.loader = true;
    this.state = state;

    // const filter: DataStateChangeEvent = {
    //   // filter: {
    //   //   // filters: [{ field: 'date_from', operator: 'gte', value: moment() }],
    //   //   logic: 'and',
    //   // },
    //   group: undefined,
    //   skip: 0,
    //   sort: [
    //     {
    //       field: 'id',
    //       dir: 'asc'
    //     }
    //   ],
    //   take: 1000
    // };
    //
    // filter.filter = this.filters;

    // log.debug('xxx', state);

    this.apiQuery
      .setLimit(999)
      .setDataSetFilters(state)
      .addFilter('active', 1)
      .addFilter('employee', 1)
      .addFilter('sale_status_id', this.saleStatuses)
      .addFilter('sale_request_status_id', this.saleRequestStatuses)
      .addIncludes(
        'sales_as_sales_agent_count',
        'sales_as_reservation_agent_count',
        'sales_as_customer_care_agent_count',
        'assignedSalesToNowCount',
        'roles'
      );
    // .setSort('assigned_sales_to_now_count', ApiQuerySortTypes.ASC);

    this.usersService
      .index(this.apiQuery)
      .pipe(untilDestroyed(this))
      .subscribe((response: PaginatedResponse) => {
        this.requestsResponse = response;
        this.data = response.getGridData();
        this.loader = false;

        this.total = 0;
        this.totalToNow = 0;

        response.data.forEach((user: any) => {
          // log.debug('assigned_sales_to_now_count', user.assigned_sales_to_now_count);
          // log.debug('assigned_sales_count', user.assigned_sales_count);
          user.total =
            Number(user.sales_as_sales_agent_count) +
            Number(user.sales_as_reservation_agent_count) +
            Number(user.sales_as_customer_care_agent_count);
          this.total += user.total;
          this.totalToNow += Number(user.assigned_sales_to_now_count);
        });

        this.cdr.markForCheck();
      });
  }

  // viewAllUpcomingEvents() {
  //   this.router.navigate(['/events'], {
  //     queryParams: {
  //       dataSet: new DataSet()
  //         .addFilter('date_from', moment().format(this.env.apiPipeDateFormat), 'gte')
  //         .getStringifyDataSet(),
  //     },
  //   });
  // }

  clearFilters() {
    this.router.navigate(['/user-assignments']).catch((e) => log.error('Navigate clear filters error', e));

    this.apiQuery = new ApiQuery();
    this.state = null;
    this.saleStatuses = '';
    this.loadData();
  }

  saleRequestStatusChange(filters: CompositeFilterDescriptor): void {
    log.debug('saleRequestStatusChange', filters);

    if (!filters.filters[0]) {
      this.saleRequestStatuses = '';
      this.loadData(this.state);
      return;
    }

    const saleRequestStatuses: string[] = [];

    (filters.filters[0] as CompositeFilterDescriptor).filters.forEach((f: FilterDescriptor) => {
      saleRequestStatuses.push(f.value);
    });

    this.saleRequestStatuses = saleRequestStatuses.join(',');
    this.loadData(this.state);
  }

  saleStatusChange(filters: CompositeFilterDescriptor): void {
    log.debug('saleStatusChanged', filters);

    if (!filters.filters[0]) {
      this.saleStatuses = '';
      this.loadData(this.state);
      return;
    }

    const saleStatuses: string[] = [];

    (filters.filters[0] as CompositeFilterDescriptor).filters.forEach((f: FilterDescriptor) => {
      saleStatuses.push(f.value);
    });

    this.saleStatuses = saleStatuses.join(',');
    this.loadData(this.state);
  }
}
