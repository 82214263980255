import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiQuery } from '@app/core/http/api-query';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WebsitesService } from '@app/settings/websites/websites.service';
import { City } from '@app/settings/cities/cities.model';
import { CitiesService } from '@app/settings/cities/cities.service';
import { Logger } from '@app/core';

const log = new Logger('CitiesSeoFormComponent');

@Component({
  selector: 'app-cities-seo-form',
  templateUrl: './cities-seo-form.component.html',
  styleUrls: ['./cities-seo-form.component.scss'],
})
export class CitiesSeoFormComponent implements OnInit {
  @Input() city: City = null;
  form: FormGroup;
  companyWebsiteLocales: Array<any>;
  currentWebsiteLocaleId: number;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private websitesService: WebsitesService,
    private citiesService: CitiesService
  ) {}

  ngOnInit() {
    this.createForm();
    this.getData();
  }

  save() {
    this.citiesService
      .updateTranslations(this.city, { translations: this.form.get('websiteLocales').value })
      .subscribe((res: any) => {
        this.modal.close(res);
      });
  }

  selectCompanyWebSiteLocale(id: number): void {
    this.currentWebsiteLocaleId = id;
  }

  getWebsiteLocaleName(id: number): string {
    const localeObj = this.companyWebsiteLocales.find((x) => x.id === id);
    return localeObj.website.name + ' ' + localeObj.website.url + ' ' + localeObj.locale.locale;
  }

  private createForm(): void {
    this.form = this.formBuilder.group({
      websiteLocales: new FormArray([]),
    });
  }

  private getData() {
    this.websitesService.getLocales().subscribe((websiteLocales: []) => {
      this.companyWebsiteLocales = websiteLocales;
      const formArray: FormArray = new FormArray([]);
      websiteLocales.forEach((websiteLocale: any) => {
        formArray.push(
          new FormGroup({
            company_website_locale_id: new FormControl(websiteLocale.id),
            name: new FormControl(null),
            description: new FormControl(null),
            slug: new FormControl(null),
            meta_title: new FormControl(null),
            meta_description: new FormControl(null),
            active: new FormControl(false),
            top: new FormControl(false),
          })
        );
      });
      this.form.setControl('websiteLocales', formArray);

      // Взема информацията от API-то и patch-ва контролите един по един
      const query = new ApiQuery().addIncludes('translations');

      this.citiesService.show(this.city.id, query).subscribe((city: City) => {
        city.translations.forEach((translation: any) => {
          this.form.get('websiteLocales')['controls'].forEach((websiteLocaleControl: FormControl) => {
            if (
              translation.company_website_locale_id ===
              Number(websiteLocaleControl.get('company_website_locale_id').value)
            ) {
              websiteLocaleControl.patchValue(translation);
            }
          });
        });
      });
    });
  }
}
