<div
  class="users-view-wrapper relative view-with-static-right-sidebar ps"
  [ngClass]="{ 'sidebar-opened': activitiesService.isOpened }"
  [perfectScrollbar]
  #perfectScroll="ngxPerfectScrollbar"
>
  <ng-container *ngIf="accessService.hasAccess([UserRoleType.FINANCE_MANAGER], [])">
    <app-side-button
      [icon]="'far fa-history'"
      [customClasses]="'btn-activity-floating'"
      (click)="openActivities()"
      *ngIf="!activitiesService.isOpened && userId"
    ></app-side-button>
  </ng-container>

  <div class="top">
    <div class="d-flex">
      <div class="w-50 pb-3 border-right">
        <div class="pl-3 pt-3 d-flex align-items-center gap-3">
          <div>
            <img [src]="user?.avatar || 'assets/user2.png'" class="user-avatar mr-3" />
          </div>

          <div>
            <div class="user-names">
              {{ user?.title?.value }} {{ user?.fake_first_name }} {{ user?.fake_last_name }}
            </div>

            <div class="user-email">
              {{ user?.fake_email }}
            </div>
          </div>
          <div class="flex-grow-1 text-right">
            <button type="button" class="btn btn-sm btn-secondary mr-3" (click)="readAllNotifications()">
              {{ 'USERS.USERS_VIEW.READ_ALL_NOTIFICATIONS' | translate }}
            </button>
          </div>
        </div>

        <div class="mt-3 pt-3 pr-3 border-top d-flex">
          <div class="pl-3 w-60">
            <div class="section-title mt-2 mb-1">
              {{ 'USERS.USERS_VIEW.GENERAL' | translate }}
            </div>

            <div *ngIf="user?.email">
              <div class="sub-title">
                <i class="far fa-envelope"></i>
                {{ 'USERS.USERS_VIEW.EMAIL' | translate }}
              </div>
              <div class="value">
                {{ user?.email }}
              </div>
            </div>

            <div *ngIf="user?.locales?.length > 0">
              <div class="sub-title">
                <i class="fal fa-globe"></i>
                {{ 'USERS.USERS_VIEW.LOCALES' | translate }}
              </div>
              <div class="value">
                <span *ngFor="let locale of user?.locales; let i = index">
                  {{ locale?.name }}<span *ngIf="i !== user?.locales?.length - 1">, </span>
                </span>
              </div>
            </div>

            <div *ngIf="user?.companies?.length > 0">
              <div class="sub-title">
                <i class="far fa-building"></i>
                {{ 'USERS.USERS_VIEW.COMPANIES' | translate }}
              </div>
              <div class="value">
                <span *ngFor="let company of user?.companies; let i = index">
                  {{ company?.shortName }}<span *ngIf="i !== user?.companies?.length - 1">, </span>
                </span>
              </div>
            </div>

            <div *ngIf="user?.roles?.length > 0">
              <div class="sub-title">
                <i class="far fa-user-tag"></i>
                {{ 'USERS.USERS_VIEW.ROLES' | translate }}
              </div>
              <div class="value">
                <span *ngFor="let role of user?.roles; let i = index">
                  {{ role?.name }}<span *ngIf="i !== user?.roles?.length - 1">, </span>
                </span>
              </div>
            </div>

            <div *ngIf="user?.allowed_ip_addresses?.length > 0">
              <div class="sub-title">
                <i class="fal fa-shield-check"></i>
                {{ 'USERS.USERS_VIEW.ALLOWED_IP_ADDRESSES' | translate }}
              </div>
              <div class="value">
                <span *ngFor="let ip of user?.allowed_ip_addresses; let i = index">
                  {{ ip }}<span *ngIf="i !== user?.allowed_ip_addresses?.length - 1">, </span>
                </span>
              </div>
            </div>
          </div>

          <div class="pr-3 w-40">
            <div *ngIf="user?.contacts?.length > 0">
              <div>
                {{ 'USERS.USERS_VIEW.CONTACTS' | translate }}
              </div>
              <app-contacts-box [contacts]="user?.contacts"></app-contacts-box>
            </div>
          </div>
        </div>
      </div>

      <div
        class="w-50 pl-3 pt-2 pr-3 pb-3"
        *ngIf="
          accessService.hasAccess([UserRoleType.FINANCE_AGENT, UserRoleType.FINANCE_MANAGER], []) ||
          userId === auth.accessToken.profile.id
        "
      >
        <div class="section-title mt-2 mb-1">
          {{ 'USERS.USERS_VIEW.EMPLOYEE' | translate }}
        </div>

        <div class="d-flex">
          <div class="w-50">
            <div *ngIf="user?.company">
              <div class="sub-title">
                <i class="far fa-envelope"></i>
                {{ 'USERS.USERS_VIEW.COMPANY' | translate }}
              </div>
              <div class="value">
                {{ user?.company.shortName }}
              </div>
            </div>

            <div *ngIf="user?.gender">
              <div class="sub-title">
                <i class="fal fa-address-card"></i>
                {{ 'USERS.USERS_VIEW.GENDER' | translate }}
              </div>
              <div class="value">
                {{ user?.gender }}
              </div>
            </div>

            <div *ngIf="user?.first_name">
              <div class="sub-title">
                <i class="far fa-location-arrow"></i>
                {{ 'USERS.USERS_VIEW.FIRST_NAME' | translate }}
              </div>
              <div class="value">
                {{ user?.first_name }}
              </div>
            </div>

            <div *ngIf="user?.middle_name">
              <div class="sub-title">
                <i class="far fa-location-arrow"></i>
                {{ 'USERS.USERS_VIEW.MIDDLE_NAME' | translate }}
              </div>
              <div class="value">
                {{ user?.middle_name }}
              </div>
            </div>

            <div *ngIf="user?.last_name">
              <div class="sub-title">
                <i class="far fa-location-arrow"></i>
                {{ 'USERS.USERS_VIEW.LAST_NAME' | translate }}
              </div>
              <div class="value">
                {{ user?.last_name }}
              </div>
            </div>
          </div>

          <div class="w-50">
            <div *ngIf="user?.egn">
              <div class="sub-title">
                <i class="fal fa-address-card"></i>
                {{ 'USERS.USERS_VIEW.EGN' | translate }}
              </div>
              <div class="value">
                {{ user?.egn }}
              </div>
            </div>

            <div *ngIf="user?.birthdate">
              <div class="sub-title">
                <i class="far fa-calendar-day"></i>
                {{ 'USERS.USERS_VIEW.BIRTHDATE' | translate }}
              </div>
              <div class="value">
                {{ user?.birthdate | date : env.pipeDateFormat }}
              </div>
            </div>

            <div *ngIf="user?.address">
              <div class="sub-title">
                <i class="far fa-map-marker-alt"></i>
                {{ 'USERS.USERS_VIEW.ADDRESS' | translate }}
              </div>
              <div class="value">
                {{ user?.address }}
              </div>
            </div>

            <div *ngIf="user?.address2">
              <div class="sub-title">
                <i class="far fa-map-marker-alt"></i>
                {{ 'USERS.USERS_VIEW.ADDRESS2' | translate }}
              </div>
              <div class="value">
                {{ user?.address2 }}
              </div>
            </div>

            <div *ngIf="user?.post_code">
              <div class="sub-title">
                <i class="far fa-location-arrow"></i>
                {{ 'USERS.USERS_VIEW.POST_CODE' | translate }}
              </div>
              <div class="value">
                {{ user?.post_code }}
              </div>
            </div>
          </div>
        </div>

        <app-bank-accounts-box [bankAccounts]="user?.bank_accounts"></app-bank-accounts-box>
      </div>
    </div>
  </div>
</div>
